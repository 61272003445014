export const gameLang = {
    endScreen: {
        title: {
            won: 'Super gemacht!',
            lose: 'Schade!',
        },
        result: {
            start: 'Ich komme zu einer Auswertung',
            end: 'Lebensmittel richtig zugeordnet',
        },
        menuButton: {
            repeatLevel: 'Level wiederholen',
            nextLevel: 'Nächstes Level',
            changeLevel: 'Level wechseln',
        },
    },
    result: {
        zeroFail: 'Du bist ein wahrer Küchenprofi und kennst dich perfekt aus!',
        oneFail: 'Ziemlich gut! Dein Ergebnis lässt sich sehen!',
        twoFail: 'Leider noch nicht ganz. Probiere es noch mal. ',
        threeFail: 'Das kannst du besser! Versuche es noch einmal!',
        failed: 'Knapp daneben.',
    },
};
