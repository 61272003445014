export const alphabet = [
    'A',
    'Ä',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'Ö',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'Ü',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

export const alphabetFilters = [
    {
        text: 'A - H',
        value: 'a',
    },
    {
        text: 'I - R',
        value: 'i',
    },
    {
        text: 'S - Z',
        value: 's',
    },
];
