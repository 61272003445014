import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from '@reach/router';

import {Container, Row, Column} from 'shared-ui/components/Layout/Grid';
import Headline from 'shared-ui/components/Elements/Headline';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';
import Icon from 'shared-ui/components/Elements/Icon';

import HeaderImage from 'components/Layout/HeaderImage';
import RecipeList from 'components/Layout/RecipeList';

import placeHolderHeaderImage from 'assets/images/favorites-header-image.jpeg';
import {lang} from 'shared-ui/lang/global.lang';
import {routes} from 'shared-utils/utils/routes';

import {StyledHeaderWrapper, GoBackReciepButton} from './styles';

const FavoriteList = ({favorites}) => {
    return (
        <>
            <Div>
                <StyledHeaderWrapper>
                    <HeaderImage
                        image={placeHolderHeaderImage}
                        title={lang.favorites}
                    />
                </StyledHeaderWrapper>
            </Div>
            <Container>
                <Div marginBottom="50px" marginTop="50px">
                    {favorites.length > 0 ? (
                        <RecipeList recipes={favorites} />
                    ) : (
                        <Row>
                            <Column
                                cols={{md: 6, lg: 4}}
                                offset={{md: 3, lg: 4}}
                            >
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginTop="10px"
                                    marginBottom="100px"
                                >
                                    <Icon size="130px" name="favoriteEmpty" />
                                    <Headline>
                                        {lang.haveNotFavoriteSave}
                                    </Headline>
                                    <GoBackReciepButton
                                        label={lang.toTheRecipes}
                                        onClick={() =>
                                            navigate(`${routes.categories}`)
                                        }
                                    />
                                </Flex>
                            </Column>
                        </Row>
                    )}
                </Div>
            </Container>
        </>
    );
};

FavoriteList.propTypes = {
    favorites: PropTypes.array.isRequired,
};

export default FavoriteList;
