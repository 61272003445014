import {useQuery} from 'react-query';

import {fetchRecipeOfDay} from 'services/request/recipe-of-days';
import {RECIPE_OF_DAYS} from 'services/config/query-keys';
import {recipeOfTheDayNormalize} from 'services/normalize/recipe-of-the-day';

const getRecipeOfDays = async () => {
    const data = await fetchRecipeOfDay();
    const normalize = recipeOfTheDayNormalize(data);
    return normalize;
};

export function useRecipeOfDays() {
    return useQuery(RECIPE_OF_DAYS, getRecipeOfDays);
}
