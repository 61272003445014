import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import {Container, Row, Column} from 'shared-ui/components/Layout/Grid';
import Button from 'shared-ui/components/Elements/Button';
import Div from 'shared-ui/components/Layout/Div';
import Headline from 'shared-ui/components/Elements/Headline';
import {lang} from 'shared-ui/lang/global.lang';
import {routes} from 'shared-utils/utils/routes';

import Tooltip from 'components/Layout/Tooltip';
import CategoryCard from 'components/Layout/CategoryCard';

import {StyledFlex, SytledHeaderDiv} from './styles';

const CategoryList = ({categories}) => {
    return (
        <>
            <SytledHeaderDiv justifyContent="center">
                <Headline type="h2" marginTop="7px" textAlign="center">
                    {lang.mealsCategories}
                </Headline>
            </SytledHeaderDiv>
            <Container bgWhite>
                <Tooltip theme="categories" tipIndex={3} />
                <Row>
                    {categories.map((category, index) => (
                        <Column key={index} cols={{xxs: 6, md: 6, lg: 4}}>
                            <Div
                                height="100%"
                                paddingTop="16px"
                                paddingBottom="16px"
                            >
                                <CategoryCard
                                    key={index}
                                    id={category.id}
                                    image={category.image}
                                    title={category.title}
                                    recipeNumber={category.recipeNumber}
                                    description={category.description}
                                />
                            </Div>
                        </Column>
                    ))}
                </Row>
                <Row>
                    <Column cols={{lg: 4}} offset={{lg: 4}}>
                        <StyledFlex justifyContent="center" marginBottom="2rem">
                            <Button
                                label={lang.favorites}
                                onClick={() => navigate(`${routes.favorites}`)}
                            />
                        </StyledFlex>
                    </Column>
                </Row>
            </Container>
        </>
    );
};

CategoryList.propTypes = {
    categories: PropTypes.array.isRequired,
};

export default CategoryList;
