import {useQuery} from 'react-query';

import {fetchFavorites} from 'services/request/favorites';
import {recipeListNormalize} from 'services/normalize/recipe-list';
import {FAVORITE_RECIPES} from 'services/config/query-keys';

const getFavoriteRecipeList = async () => {
    const {data, meta} = await fetchFavorites();
    const normalize = await recipeListNormalize(data, meta);
    return normalize;
};

export function useFavoriteRecipeList() {
    return useQuery(FAVORITE_RECIPES, getFavoriteRecipeList, {
        staleTime: Infinity,
    });
}
