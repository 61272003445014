import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';

import BgImage from 'assets/images/vitamin-mineral-bacground.png';

import {circlePointCreator} from 'utils/helpers/circle-point-generator';
import {color} from 'shared-ui/styles/variables';

import {Wrapper, StyledIcon, CircleWrapper} from './styles';
import Item from './Item';

const VitaminMineral = ({icon, vitaminMineralList}) => {
    const wrapperRef = useRef(null);
    const [initialCoordinates, setInitialCoordinates] = useState(null);

    useEffect(() => {
        const handleCirclePointCreator = async () => {
            const coordinates = await circlePointCreator(
                wrapperRef,
                vitaminMineralList.length
            );
            setInitialCoordinates(coordinates);
        };
        if (wrapperRef.current) {
            handleCirclePointCreator();
        }
    }, [wrapperRef]);

    return (
        <Wrapper justifyContent="center" alignItems="center" bgImage={BgImage}>
            <StyledIcon name={icon} color={color.orange} size="48px" />
            <CircleWrapper ref={wrapperRef}>
                {initialCoordinates && (
                    <>
                        {vitaminMineralList.map((item, index) => (
                            <Item
                                key={index}
                                title={item.title}
                                type={item.type}
                                popoverContent={item.content}
                                coordinate={initialCoordinates[index]}
                            />
                        ))}
                    </>
                )}
            </CircleWrapper>
        </Wrapper>
    );
};

VitaminMineral.propTypes = {
    icon: PropTypes.string.isRequired,
    vitaminMineralList: PropTypes.array.isRequired,
};

export default VitaminMineral;
