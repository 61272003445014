import PropTypes from 'prop-types';
import React from 'react';

import Popover from 'shared-ui/components/Elements/Popover';

import PopoverContent from './PopoverContent';
import {color} from 'shared-ui/styles/variables';

import {StyledIcon, Unit, UnitWrapper, ItemWrapper, Title} from './styles';

const IngretidentItem = ({
    amount,
    unit,
    title,
    suffix,
    prefix,
    id,
    kcal,
    fat,
    protein,
    carbs,
    inGlossary,
}) => {
    return (
        <ItemWrapper alignItems="center">
            <UnitWrapper>
                <Unit theme="darkBlue">
                    {amount || ''} {unit}
                </Unit>
            </UnitWrapper>
            <Title theme="grey">
                {prefix || ''} {title} {suffix || ''}
            </Title>
            {inGlossary && (
                <Popover
                    content={
                        <PopoverContent
                            id={id}
                            title={title}
                            unit={`${amount || ''} ${unit || ''}`}
                            kcal={kcal}
                            fat={fat}
                            protein={protein}
                            carbs={carbs}
                        />
                    }
                    positions="top"
                    align="end"
                >
                    <StyledIcon name="info" color={color.orange} size="20px" />
                </Popover>
            )}
        </ItemWrapper>
    );
};

IngretidentItem.propTypes = {
    amount: PropTypes.string.isRequired,
    carbs: PropTypes.string.isRequired,
    fat: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    kcal: PropTypes.number.isRequired,
    prefix: PropTypes.string,
    protein: PropTypes.number.isRequired,
    suffix: PropTypes.string,
    title: PropTypes.string.isRequired,
    unit: PropTypes.number.isRequired,
    inGlossary: PropTypes.bool.isRequired,
};

IngretidentItem.defaultProps = {
    prefix: null,
    suffix: null,
};

export default IngretidentItem;
