import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';

import {orangeShadow} from 'shared-ui/styles/variables';

export const AlphabetWrapper = styled.div`
    margin-bottom: 40px;
`;

export const StyledCard = styled(Card)`
    cursor: pointer;
    height: 100%;

    &:hover {
        box-shadow: ${orangeShadow};
    }
`;
