import styled from 'styled-components';

import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import Headline from 'shared-ui/components/Elements/Headline';

import {queries} from 'shared-ui/styles/variables';

export const StyledHeaderDiv = styled(Flex)`
    padding-top: 64px;
    padding-bottom: 32px;
    @media ${queries.mdMax} {
        padding-top: 20px;
        padding-bottom: 0px;
        padding-left: 10px;
    }
`;

export const StyledHeadline = styled(Headline)`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    margin-top: 7px;
`;

export const SearchDiv = styled(Div)`
    margin-bottom: 64px;
    @media ${queries.mdMax} {
        margin-bottom: 10px;
    }
`;
