import styled from 'styled-components';

import Button from 'shared-ui/components/Elements/Button';

export const StyledHeaderWrapper = styled.div`
    position: relative;
    width: 90%;
`;

export const GoBackReciepButton = styled(Button)`
    margin-top: 30px;
`;
