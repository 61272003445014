import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {Column, Row} from 'shared-ui/components/Layout/Grid';
import Div from 'shared-ui/components/Layout/Div';

import {routes} from 'shared-utils/utils/routes';

import {StyledCard, AlphabetWrapper} from './styles';

const GlossaryList = ({glossaryList, page}) => {
    const pagedList = page === 0 ? glossaryList : glossaryList.slice(0, page);
    return (
        <>
            {pagedList.map((alphabet, index) => (
                <AlphabetWrapper key={index}>
                    <Headline theme="orange" type="h4">
                        {alphabet.value}.
                    </Headline>
                    <Row>
                        {alphabet.glossary.map((glossar, index) => (
                            <Column cols={{md: 4}} key={index}>
                                <Div
                                    height="100%"
                                    paddingTop="16px"
                                    paddingBottom="16px"
                                >
                                    <StyledCard
                                        isHoverBg
                                        justifyContent="center"
                                        onClick={() =>
                                            navigate(
                                                `${routes.glossary}/${glossar.id}`
                                            )
                                        }
                                    >
                                        <Paragraph>{glossar.title}</Paragraph>
                                    </StyledCard>
                                </Div>
                            </Column>
                        ))}
                    </Row>
                </AlphabetWrapper>
            ))}
        </>
    );
};

GlossaryList.propTypes = {
    glossaryList: PropTypes.array.isRequired,
    page: PropTypes.number,
};

GlossaryList.defaultProps = {
    page: 0,
};

export default GlossaryList;
