import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Button from 'shared-ui/components/Elements/Button';

import {useRemoveShopping} from 'services/data-hooks/shoppingList/useRemoveShopping';
import {useAddShopping} from 'services/data-hooks/shoppingList/useAddShopping';
import {useShoppingLisContext} from 'context/ShoppingListContext';
import {useRemoveNutritionPlan} from 'services/data-hooks/nutritionPlan/useRemoveNutritionPlan';

import {routes} from 'shared-utils/utils/routes';

import {
    ImageWrapper,
    IconsWrapper,
    BottomWrapper,
    TitleCard,
    Wrapper,
} from './styles';

export default function RecipeWeeklyPlanner({
    headline,
    image,
    id,
    week,
    dayValue,
}) {
    const {mutate: removeShopping} = useRemoveShopping();
    const {mutate: addShopping} = useAddShopping();
    const {some: isShoppingList} = useShoppingLisContext();
    const {mutate: removeNutritionPlan} = useRemoveNutritionPlan();
    const isShopping = isShoppingList(id);


    const handleRemoveWeekPlan = () => {
        const findDay = week.days.find((x) => x.dayValue === dayValue);
        const findRecipeIndex = findDay.recipes.indexOf(id);
        findDay.recipes.splice(findRecipeIndex, 1, null);
        removeNutritionPlan(week.days, false);
    };

    const handleShoppingIcon = () => {
        if (isShopping) {
            removeShopping(id);
        } else {
            addShopping(id);
        }
    };

    const handleToRecipeDetail = () => navigate(`${routes.recipe}/${id}`);

    return (
        <Wrapper>
            <TitleCard onClick={handleToRecipeDetail}>
                <Paragraph theme="black" size="xsmall" autoLineHeight>
                    {headline}
                </Paragraph>
            </TitleCard>
            <BottomWrapper>
                <ImageWrapper bgImage={image} onClick={handleToRecipeDetail} />
                <IconsWrapper>
                    <Button
                        theme={isShopping ? 'active' : 'white'}
                        icon="bag"
                        size="65px"
                        iconSize="30px"
                        onClick={handleShoppingIcon}
                    />
                    <Button
                        theme="secondary"
                        icon="delete"
                        size="65px"
                        iconSize="30px"
                        onClick={handleRemoveWeekPlan}
                    />
                </IconsWrapper>
            </BottomWrapper>
        </Wrapper>
    );
}

RecipeWeeklyPlanner.propTypes = {
    image: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    week: PropTypes.object.isRequired,
    dayValue: PropTypes.string.isRequired,
};
