import styled, {css} from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';

import {queries} from 'shared-ui/styles/variables';

export const StyledHeaderWrapper = styled.div`
    position: relative;
    width: 100%;
    @media ${queries.lg} {
        width: 90%;
    } ;
`;

export const StyledCard = styled(Card)`
    margin-bottom: 32px;
`;

export const AnchorMenuWrapper = styled(Flex)`
    transition: 500ms;
    position: relative;
    top: 0;
    z-index: 9999;

    ${({isFixed}) =>
        isFixed &&
        css`
            position: fixed;
            top: 80px;
        `}
`;

export const MoreRecipeWrapper = styled.div`
    margin-top: 40px;
    @media ${queries.lg} {
        margin-top: 0;
        margin-left: 60px;
    }
`;

export const StyledDiv = styled(Div)`
    max-width: 150px;
    z-index: 999;
`;
