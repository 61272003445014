import styled from 'styled-components';
import Select from 'react-select';

import {color, shadow} from '../../../styles/variables';

export const StyledSelect = styled(Select)`
    width: 100%;
    .app__select {
        &__control {
            height: 40px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            cursor: pointer;
            border: none;
        }
        &__control:hover {
            border-color: #a1a1a1;
        }

        &__control--is-focused {
            box-shadow: none;
            outline: none;
        }

        &__indicator-separator {
            display: none;
        }

        &__menu {
            color: #3c3d3e;
        }
        &__placeholder,
        &__single-value,
        &__indicator,
        &__input-container {
            color: ${color.white} !important;
        }
        &__menu {
            border: 1px solid transparent !important;
            box-shadow: ${shadow} !important;
            border-radius: 10px;
        }
        &__option {
            background-color: transparent;
            cursor: pointer;
            &:hover {
                background-color: ${color.lightGrey};
            }
            &--is-selected {
                background-color: ${color.lightGrey};
                color: ${color.darkBlue};
            }
        }
    }
`;

export const StyledInput = styled.div`
    color: ${color.white};
`;
