import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {articleNormalize} from 'utils/helpers/type-article-normalize';

const GameContext = createContext();

export const GameProvider = ({children}) => {
    const [lives, setLives] = useState([]);
    const [livesCount, setLivesCount] = useState(3);
    const [initialDroppedProduct, setInitialDroppedProduct] = useState(null);
    const [trueType, setTrueType] = useState(null);
    const [falseType, setFalseType] = useState(null);
    const [alertText, setAlertText] = useState('');
    const [isShowAlertType, setIsShowAlertType] = useState('');

    useEffect(() => {
        let newArray = [];
        for (let i = 0; i < livesCount; i++) {
            newArray.push(i);
        }
        setLives(newArray);
    }, [livesCount]);

    useEffect(() => {
        const timerReset = () =>
            setTimeout(() => {
                setInitialDroppedProduct(null);
                setAlertText('');
                setIsShowAlertType('');
            }, 2000);
        const initialTimerReset = timerReset();
        return () => {
            clearTimeout(initialTimerReset);
        };
    }, [isShowAlertType]);

    useEffect(() => {
        if (trueType || falseType) {
            setIsShowAlertType(trueType || falseType);
        }
    }, [trueType, falseType]);

    const handleRemoveLive = () => {
        setLivesCount((livesCount) => livesCount - 1);
    };

    const handleAlertText = (product, status) => {
        const text = status
            ? `${product.value} gehört ${articleNormalize(product.type)} ${
                  product.typeName
              }!`
            : `${product.value} lieber ${articleNormalize(product.type)} ${
                  product.typeName
              } packen!`;

        setAlertText(text);
    };

    return (
        <GameContext.Provider
            value={{
                lives,
                setLives,
                handleRemoveLive,
                livesCount,
                setLivesCount,
                initialDroppedProduct,
                setInitialDroppedProduct,
                trueType,
                setTrueType,
                falseType,
                setFalseType,
                handleAlertText,
                alertText,
                setAlertText,
                isShowAlertType,
            }}
            displayName="Game"
        >
            {children}
        </GameContext.Provider>
    );
};

GameProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useGameContext = () => {
    return useContext(GameContext);
};
