import React from 'react';
import {navigate} from '@reach/router';

import {Column} from 'shared-ui/components/Layout/Grid';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {navigationLang} from 'lang/homepage-navigation.lang';
import {color} from 'shared-ui/styles/variables';
import Tooltip from 'components/Layout/Tooltip';

import {NavigationCard, CardWrapper} from './styles';

const HomepageNavigation = () => {
    return (
        <CardWrapper>
            <Tooltip theme="homepageNavigation" tipIndex={0} />
            {navigationLang.map((nav, index) => (
                <Column key={index} cols={{sm: 5, lg: 3, xxl: 2}}>
                    <NavigationCard
                        isHoverBg
                        onClick={() => navigate(`${nav.path}`)}
                    >
                        <Icon color={color.red} name={nav.icon} size="48px" />
                        <Paragraph bold theme="darkBlue" size="small">
                            {nav.value}
                        </Paragraph>
                    </NavigationCard>
                </Column>
            ))}
        </CardWrapper>
    );
};

export default HomepageNavigation;
