import {useQuery} from 'react-query';

import {fetchRecipeDetail} from 'services/request/recipe-detail';
import {RECIPE_DETAIL} from 'services/config/query-keys';
import {categoryRecipeDetailNormalize} from 'services/normalize/recipe-detail';

const getRecipeDetail = async (recipeId) => {
    const data = await fetchRecipeDetail(recipeId);
    const normalize = await categoryRecipeDetailNormalize(data);
    return normalize;
};

export function useRecipeDetail(recipeId) {
    return useQuery([RECIPE_DETAIL, recipeId], () => getRecipeDetail(recipeId));
}
