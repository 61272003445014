import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';
import {color} from 'shared-ui/styles/variables';
import Button from 'shared-ui/components/Elements/Button';
import {lang} from 'shared-ui/lang/global.lang';

import {StyledExportCard} from './styles';

const ExportCard = ({onClick}) => {
    return (
        <StyledExportCard width="300px">
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                marginTop="48px"
                marginBottom="32px"
            >
                <Icon name="export" size="70px" color={color.red} bg />
                <Paragraph theme="darkBlue" bold textAlign="center">
                    {lang.exportCardTitle}
                </Paragraph>

                <Div marginTop="32px">
                    <Button
                        theme="secondary"
                        icon="pdfDownload"
                        size="70px"
                        iconSize="35px"
                        onClick={onClick}
                    />
                </Div>
                <Paragraph theme="darkBlue" bold>
                    {lang.exportCardInfoIcon}
                </Paragraph>
            </Flex>
        </StyledExportCard>
    );
};

ExportCard.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ExportCard;
