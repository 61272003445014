export const articleNormalize = (type) => {
    if (type === 'regal') {
        return `in das`;
    }
    if (type === 'fridge') {
        return `in den`;
    }
    if (type === 'bowl') {
        return `in die`;
    }
};
