import styled from 'styled-components';
import {motion} from 'framer-motion';

import {color} from 'shared-ui/styles/variables';

export const NavWrapper = styled(motion.div)`
    background: ${color.button.primary};
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 14px 0px 0px 14px;
    cursor: pointer;
    flex-direction: column;
    padding: 0.3125rem 0;
`;

export const IconMotionWrapper = styled(motion.span)`
    display: flex;
    opacity: 1;
`;
export const IconClosedMotionWrapper = styled(motion.span)`
    display: none;
    opacity: 0;
`;

export const CardHead = styled(motion.div)`
    display: none;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
`;

export const CardBottom = styled(motion.div)`
    display: none;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
    margin: 5px 0;
    align-items: center;
`;

export const SelectWrapper = styled(motion.span)`
    min-width: 160px;
`;

export const Line = styled(motion.div)`
    margin: 5px 0;
    border-bottom: 2px solid ${color.white};
    width: 0;
    opacity: 0.3;
`;
