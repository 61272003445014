import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';
import Image from 'shared-ui/components/Elements/Image';
import {routes} from 'shared-utils/utils/routes';

import {useGameContext} from 'context/GameContext';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';

import {StyledCard, FinishIcon} from './styles';

const GameLevelListItem = ({category, successfulyCategories}) => {
    const {setLivesCount} = useGameContext();

    const isSuccessful = successfulyCategories.some(
        (x) => x.level === category.level
    );

    const handleOnClick = () => {
        navigate(`${routes.gameLevel}/${category.path}`);
        setLivesCount(3);
        dataLayerPush({
            event: 'biib_internalLinkClick',
            eventAsset: 'game_choose_level',
            eventAssetBis: category.title,
        });
    };

    return (
        <StyledCard width="100%" isHoverBg onClick={handleOnClick}>
            <Flex flexDirection="row" alignItems="center">
                <Div marginRight="20px">
                    <Image src={category.icon} width="35px" />
                </Div>
                <Paragraph theme="black" bold>
                    {category.title}
                </Paragraph>
                {isSuccessful && <FinishIcon name="check" />}
            </Flex>
        </StyledCard>
    );
};

GameLevelListItem.propTypes = {
    category: PropTypes.object.isRequired,
    successfulyCategories: PropTypes.array.isRequired,
};

export default GameLevelListItem;
