import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'shared-ui/components/Elements/Headline';
import {Column, Container, Row} from 'shared-ui/components/Layout/Grid';
import Div from 'shared-ui/components/Layout/Div';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import BackButton from 'shared-ui/components/Layout/BackButton';
import GameLevelListItem from 'components/Layout/GameLevelListItem';

import {levels} from 'lang/game-levels.lang';
import {lang} from 'shared-ui/lang/global.lang';
import {footerTopPadding, queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {
    Wrapper,
    BackgroundImage,
    StyledWrapperDiv,
    StyledHeadline,
    ImageDiv,
    CardsWrapper,
    StyledParagraph,
} from './styles';

const GameSelectLevel = ({successfulyCategories}) => {
    const isMdMax = useMediaQuery(queries.mdMax);
    const isSm = useMediaQuery(queries.sm);

    return (
        <Div paddingBottom={footerTopPadding}>
            {!isMdMax && (
                <Div
                    justifyContent="center"
                    paddingTop="25px"
                    paddingBottom="25px"
                >
                    <Headline type="h2" textAlign="center">
                        {lang.storageQuiz}
                    </Headline>
                </Div>
            )}

            <Container bgWhite>
                <Wrapper>
                    <Div marginBottom="50px">
                        <ImageDiv>
                            <BackgroundImage>
                                {isMdMax && (
                                    <>
                                        <Div
                                            paddingTop="10px"
                                            paddingLeft="10px"
                                        >
                                            <BackButton />
                                        </Div>

                                        <StyledWrapperDiv>
                                            <Div
                                                marginTop="10px"
                                                marginLeft="2.5rem"
                                            >
                                                <StyledHeadline
                                                    type="h3"
                                                    theme="white"
                                                >
                                                    {lang.welcome}
                                                </StyledHeadline>
                                                <Paragraph
                                                    size={
                                                        isSm
                                                            ? 'small'
                                                            : 'xsmall'
                                                    }
                                                    theme="white"
                                                >
                                                    {lang.chooseLevel}
                                                </Paragraph>
                                            </Div>
                                        </StyledWrapperDiv>
                                    </>
                                )}

                                <Container>
                                    <Row>
                                        <CardsWrapper>
                                            <Column
                                                cols={{lg: 10}}
                                                offset={{lg: 1}}
                                            >
                                                <Row>
                                                    {!isMdMax && (
                                                        <Column
                                                            offset={{
                                                                md: 4,
                                                                lg: 5,
                                                            }}
                                                        >
                                                            <StyledParagraph
                                                                textAlign="center"
                                                                theme="white"
                                                                size="medium"
                                                                autoLineHeight
                                                                verticalPadding
                                                            >
                                                                {
                                                                    lang.chooseLevel
                                                                }
                                                            </StyledParagraph>
                                                        </Column>
                                                    )}

                                                    {levels.map(
                                                        (category, key) => (
                                                            <Column
                                                                key={key}
                                                                cols={{
                                                                    sm: 8,
                                                                    md: 4,
                                                                    lg: 4,
                                                                }}
                                                                offset={{
                                                                    sm: 2,
                                                                    md: 1,
                                                                    lg: 0,
                                                                }}
                                                            >
                                                                <GameLevelListItem
                                                                    category={
                                                                        category
                                                                    }
                                                                    successfulyCategories={
                                                                        successfulyCategories
                                                                    }
                                                                />
                                                            </Column>
                                                        )
                                                    )}
                                                </Row>
                                            </Column>
                                        </CardsWrapper>
                                    </Row>
                                </Container>
                            </BackgroundImage>
                        </ImageDiv>
                    </Div>
                </Wrapper>
            </Container>
        </Div>
    );
};

GameSelectLevel.propTypes = {
    successfulyCategories: PropTypes.array.isRequired,
};

export default GameSelectLevel;
