import React from 'react';
import PropTypes from 'prop-types';

import {
    StyledHeaderImage,
    PreviewImage,
    Title,
    ContentWrapper,
    BadgeWrapper,
} from './styles';

import Badge from 'shared-ui/components/Elements/Badge';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Divider from 'shared-ui/components/Elements/Divider';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {queries} from 'shared-ui/styles/variables';

const HeaderImage = ({title, preTitle, image, tags, theme}) => {
    const isLg = useMediaQuery(queries.lg);
    return (
        <StyledHeaderImage theme={theme}>
            <PreviewImage src={image} />
            {tags && (
                <BadgeWrapper>
                    {tags.map((value, index) => {
                        return <Badge text={value.name} key={index} />;
                    })}
                </BadgeWrapper>
            )}

            <ContentWrapper>
                <Paragraph theme="white">{preTitle}</Paragraph>
                <Divider marginBottom="0" />
                <Title theme="white" type={isLg ? 'h2' : 'h1'}>
                    {title}
                </Title>
            </ContentWrapper>
        </StyledHeaderImage>
    );
};

HeaderImage.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    preTitle: PropTypes.string,
    tags: PropTypes.array,
    theme: PropTypes.string,
};

HeaderImage.defaultProps = {
    tags: [],
    image: '',
    title: '',
    preTitle: '',
    theme: 'big',
};

export default HeaderImage;
