import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Button from 'shared-ui/components/Elements/Button';
import Input from 'shared-ui/components/Elements/Input';
import {Container, Row, Column} from 'shared-ui/components/Layout/Grid';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';

import GlossaryList from 'components/Layout/GlossaryList';
import BackButton from 'shared-ui/components/Layout/BackButton';
// import AlphabetFilter from 'components/Layout/AlphabetFilter';

import {lang} from 'shared-ui/lang/global.lang';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {queries} from 'shared-ui/styles/variables';

import {StyledHeaderDiv, SearchDiv, StyledHeadline} from './styles';

const GlossaryListTemplate = ({glossaryList}) => {
    const isMdMax = useMediaQuery(queries.mdMax);
    const [searchString, setSearchString] = useState('');
    const [page, setPage] = useState(2);

    const filtered = () => {
        const response = glossaryList?.map((item) => ({
            value: item.value,
            glossary: item.glossary.filter((x) =>
                x.title.includes(
                    searchString
                        .toLowerCase()
                        .replace(/(^|\s)\S/g, (L) => L.toUpperCase())
                )
            ),
        }));
        return response;
    };

    const memorizedRecipes = useMemo(
        () => filtered(),
        [glossaryList, searchString]
    );

    const filteredRecipes = memorizedRecipes.filter(
        (x) => x.glossary.length > 0
    );

    const showMoreButton = page >= filteredRecipes.length;

    return (
        <>
            <StyledHeaderDiv justifyContent="space-around">
                {isMdMax && <BackButton />}
                <StyledHeadline type="h2" textAlign="center">
                    {lang.glossary}
                </StyledHeadline>
            </StyledHeaderDiv>
            <Container bgWhite>
                <Row>
                    <Column cols={{lg: 6}} offset={{lg: 3}}>
                        <Flex justifyContent="center" marginBottom="16px">
                            <Paragraph size="xsmall" bold theme="darkBlue">
                                {lang.glossaryTemplate.paragraph}
                            </Paragraph>
                        </Flex>
                        <SearchDiv>
                            <Input
                                placeholder={lang.search}
                                iconName="search"
                                onChange={setSearchString}
                            />
                        </SearchDiv>
                    </Column>
                    <Column cols={{lg: 10}} offset={{lg: 1}}>
                        <Div marginTop="16px">
                            <GlossaryList
                                glossaryList={filteredRecipes}
                                page={page}
                            />
                        </Div>
                    </Column>
                    {!showMoreButton && (
                        <Column cols={{lg: 4}} offset={{lg: 4}}>
                            <Flex justifyContent="center" marginBottom="4rem">
                                <Button
                                    label={lang.showMore}
                                    onClick={() => {
                                        if (glossaryList.length > page) {
                                            setPage(page + 2);
                                        }
                                    }}
                                />
                            </Flex>
                        </Column>
                    )}
                </Row>
            </Container>
        </>
    );
};

GlossaryListTemplate.propTypes = {
    glossaryList: PropTypes.array.isRequired,
};

export default GlossaryListTemplate;
