import PropTypes from 'prop-types';
import React, {createContext, useContext, useState, useEffect} from 'react';
import {getWeekNUmber} from 'utils/helpers/get-week-number';

const NutritionPlanContext = createContext();

export const NutritionPlanProvider = ({children}) => {
    const now = new Date();
    const year = now.getFullYear();
    const currentWeek = getWeekNUmber(new Date(now));
    const [selectedWeek, setSelectedWeek] = useState(currentWeek);
    const [currentYear, setCurrentYear] = useState(`${year}-${selectedWeek}`);

    const handleCurrentYear = () => {
        setCurrentYear(`${year}-${currentWeek}`);
        setSelectedWeek(currentWeek);
    };

    useEffect(() => {
        setCurrentYear(`${year}-${selectedWeek}`);
    }, [selectedWeek]);

    const stringfy = selectedWeek.toString().padStart(2, '0');
    const stringfyCurrentYear = `${year}-${stringfy}`;

    return (
        <NutritionPlanContext.Provider
            value={{
                currentYear,
                selectedWeek,
                setSelectedWeek,
                handleCurrentYear,
                year,
                currentWeek,
                stringfyCurrentYear,
            }}
            displayName="NutritionPlan"
        >
            {children}
        </NutritionPlanContext.Provider>
    );
};

NutritionPlanProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useNutritionPlanContext = () => {
    return useContext(NutritionPlanContext);
};
