import {useNutritionPlanContext} from 'context/NutritionPlanContext';
import {useMemo} from 'react';

export const useCreateNextWeeks = (weekNumber) => {
    const {currentWeek} = useNutritionPlanContext();
    const weeks = [...Array(weekNumber).keys()].map((value, index) => ({
        label: `KW ${currentWeek + value}`,
        value: currentWeek + index,
    }));
    const memoWeeksItems = useMemo(() => weeks, []);
    return {memoWeeksItems};
};
