import styled from 'styled-components';

import {color} from 'shared-ui/styles/variables';

import Image from 'shared-ui/components/Elements/Image';
import Div from 'shared-ui/components/Layout/Div';

export const IconSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    padding-bottom: 8px;
    border-top: 2px solid ${color.grey};
    width: 100%;
`;

export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const ImageWrapper = styled.div`
    max-height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`;

export const StyledImage = styled(Image)`
    transition: 500ms;
`;

export const StyledDiv = styled(Div)`
    &:hover {
        ${StyledImage} {
            transform: scale(1.1);
        }
    }
`;
