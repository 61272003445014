export const navVariants = (isDesktop) => ({
    open: {
        width: isDesktop ? 350 : 280,
        height: isDesktop ? 155 : 126,
        transition: {
            staggerChildren: 0.01,
            when: 'beforeChildren',
            delayChildren: 0.01,
        },
    },
    closed: {
        width: isDesktop ? 105 : 75,
        height: isDesktop ? 105 : 75,
        transition: {
            staggerChildren: 0.01,
            delayChildren: 0,
            when: 'afterChildren',
            staggerDirection: -1,
        },
    },
});

export const weeklyIconVariants = {
    open: {
        display: 'none',
        opacity: 0,
    },
    closed: {
        display: 'flex',
        opacity: 1,
    },
};

export const itemVariants = {
    open: {
        display: 'flex',
        opacity: 1,
    },
    closed: {
        display: 'none',
        opacity: 0,
    },
};
export const displayVariants = {
    open: {
        display: 'flex',
    },
    closed: {
        display: 'none',
    },
};
export const lineVariants = {
    open: {
        display: 'flex',
        width: 300,
    },
    closed: {
        display: 'none',
    },
};
