import PropTypes from 'prop-types';
import React from 'react';

import {queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {ButtonsWrapper, StyledButton, BackgroundFade} from './styles';

const RecipeButton = ({
    isFixed,
    isFavorite,
    isShopping,
    handleShoppingIcon,
    handleFavoriteIcon,
    handleCalendarOnclick,
}) => {
    const isLgMax = useMediaQuery(queries.lgMax);
    return (
        <ButtonsWrapper isFixed={isFixed}>
            <BackgroundFade />
            <StyledButton
                theme="gradientBorder"
                icon="calendarPlus"
                iconSize={!isLgMax ? '50px' : '40px'}
                onClick={handleCalendarOnclick}
            />
            <StyledButton
                theme={isShopping ? 'active' : 'gradientBorder'}
                icon="bag"
                iconSize={!isLgMax ? '32px' : '25px'}
                onClick={handleShoppingIcon}
            />
            <StyledButton
                theme={isFavorite ? 'active' : 'gradientBorder'}
                icon="heart"
                iconSize={!isLgMax ? '32px' : '25px'}
                onClick={handleFavoriteIcon}
            />
        </ButtonsWrapper>
    );
};

RecipeButton.propTypes = {
    isFixed: PropTypes.bool,
    isFavorite: PropTypes.bool,
    isShopping: PropTypes.bool,
    handleFavoriteIcon: PropTypes.func,
    handleShoppingIcon: PropTypes.func,
    handleCalendarOnclick: PropTypes.func,
};

RecipeButton.defaultProps = {
    isFixed: false,
    isFavorite: false,
    isShopping: false,
    handleFavoriteIcon: () => {},
    handleShoppingIcon: () => {},
    handleCalendarOnclick: () => {},
};

export default RecipeButton;
