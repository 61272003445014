import React from 'react';
import PropTypes from 'prop-types';

import {
    StyledFilterBox,
    Input,
    Box,
    Label,
    StyledIcon,
    DisabledBackground,
} from './styles';

const FilterBox = ({name, label, icon, id, onChange, checked, value}) => {
    return (
        <StyledFilterBox htmlFor={id} size="150px">
            <Box size="150px" checked={checked}>
                <DisabledBackground checked={checked} />
                <StyledIcon icon={icon} checked={checked} />
                <Label
                    size="xxxsmall"
                    textAlign="center"
                    theme="darkBlue"
                    checked={checked}
                    bold={true}
                >
                    {label}
                </Label>
            </Box>
            <Input
                id={id}
                type="checkbox"
                name={name}
                onChange={() => onChange(value)}
                checked={checked}
                value={value}
            />
        </StyledFilterBox>
    );
};

FilterBox.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

export default FilterBox;
