import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'shared-ui/components/Layout/Flex';
import Image from 'shared-ui/components/Elements/Image';

import {StyledCard, CardParagraph} from './styles';

const RecipeStorage = ({storages}) => {
    return (
        <>
            {storages.map((storage, index) => (
                <StyledCard key={index} theme="secondary">
                    <Flex alignItems="center">
                        <Image
                            src={storage.icon}
                            width="56px"
                            height="56px"
                            alt={storage.title}
                        />
                        <CardParagraph theme="darkBlue" bold size="xxsmall" />
                        <CardParagraph theme="darkBlue" size="xsmall">
                            {storage.title}
                        </CardParagraph>
                    </Flex>
                </StyledCard>
            ))}
        </>
    );
};

RecipeStorage.propTypes = {
    storages: PropTypes.array.isRequired,
};

export default RecipeStorage;
