import styled from 'styled-components';

import Card from '../Card';
import Paragraph from '../Paragraph';

import {color} from '../../../styles/variables';

export const StyledFilterBox = styled.label`
    position: relative;
    display: block;
    height: 1.75rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-right: 6rem;
    margin: 0.5rem;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;

    &:checked ~ span::after {
        display: block;
    }
`;

export const Box = styled(Card)`
    box-shadow: 15px 15px 50px 0 rgb(0 0 0 / 15%);
    align-items: center;
    background: ${color.button.primary};
    overflow: hidden;
`;

export const DisabledBackground = styled.div`
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 13px;
    opacity: ${({checked}) => (checked ? '0' : '1')};
    transition: all 500ms;
`;

export const Label = styled(Paragraph)`
    color: ${({checked}) => (checked ? color.white : color.darkBlue)};
    line-height: 16px;
    z-index: 2;
`;

export const StyledIcon = styled.span`
    -webkit-mask: url(${({icon}) => icon}) no-repeat center;
    mask: url(${({icon}) => icon}) no-repeat center;
    background: ${({checked}) => (checked ? color.white : color.red)};
    height: 50px;
    width: 50px;
    margin-top: 16px;
`;
