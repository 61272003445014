import styled from 'styled-components';

import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';
import Flex from 'shared-ui/components/Layout/Flex';
import {Container} from 'shared-ui/components/Layout/Grid';

import {queries, shadow} from 'shared-ui/styles/variables';

export const StyledContainer = styled(Container)`
    margin-top: 32px;
    @media ${queries.mdMax} {
        margin-top: 0px;
    }
`;

export const StyledFlex = styled(Flex)`
    padding-top: 50px;
    @media ${queries.lgMax} {
        padding: 20px 15px 0px;
    }
`;

export const StyledHeadline = styled(Headline)`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    margin-top: 7px;
    @media ${queries.lgMax} {
        font-size: 30px;
    }
    @media ${queries.xsMax} {
        font-size: 24px;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-right: 3rem;
`;
export const StyledTextParagraph = styled(Paragraph)`
    margin-top: 1rem;
    margin-bottom: 3rem;
    padding-right: 3rem;
`;

export const StyledCard = styled(Card)`
    margin-bottom: 3rem;
    @media ${queries.smMax} {
        max-width: 350px;
        margin: 1px auto;
    }
`;

export const StyledIcon = styled(Icon)`
    width: 45px;
    height: 45px;
    padding: 10px 10px;
    margin-left: 1rem;
    box-shadow: ${shadow};
    @media ${queries.xxsMax} {
        width: 38px;
        height: 38px;
    }
`;

export const AnchorMenuWrapper = styled(Flex)`
    position: fixed;
    margin-left: 40px;
`;
