import styled from 'styled-components';

import Flex from '../..//Layout/Flex';
import {color} from '../../../styles/variables';

export const Wrapper = styled(Flex)`
    background-color: ${color.lightGrey};
    border-radius: 0.9375rem;
    padding: 1rem;
`;

export const StyledInput = styled.input`
    border: none;
    background-color: transparent;
    width: 100%;
    ::placeholder {
        color: ${color.grey};
        font-size: 1.2em;
        opacity: 0.5;
    }
    :focus,
    :focus-visible {
        outline: none;
    }
`;
