import {SelectWrapper, StyledPlannerCard} from './styles';
import {useRecipeWeeklyPlanner} from './useRecipeWeeklyPlanner';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'shared-ui/components/Elements/Button';
import CheckBoxGroup from 'shared-ui/components/Elements/CheckBoxGroup';
import ImageSvg from 'shared-ui/components/Elements/ImageSvg';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Select from 'shared-ui/components/Elements/Select';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {lang} from 'shared-ui/lang/global.lang';

const RecipeWeeklyPlanCheckBoxes = ({
    selectedCheckBoxValues,
    disabledCheckBoxValues,
    handleAddWeekPlan,
    handleSelectedDay,
    handleWeekChange,
    memoFiveWeeksItems,
    setSelectedCheckBoxValues,
    selectedWeek,
}) => {
    return (
        <StyledPlannerCard width="400px">
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                marginTop="48px"
                marginBottom="32px"
            >
                <ImageSvg
                    src="assets/images/weeklyPlan/icon-weekly-plan-checkboxes.svg"
                    size="70px"
                />
                <Paragraph theme="darkBlue" bold>
                    {lang.chooseDayOfTheWeek}
                </Paragraph>
                <SelectWrapper>
                    <Select
                        options={memoFiveWeeksItems}
                        value={memoFiveWeeksItems.find(
                            (x) => x.value === selectedWeek
                        )}
                        onChange={(e) => handleWeekChange(e)}
                    />
                </SelectWrapper>
                <CheckBoxGroup
                    items={lang.weekDays}
                    disableItems={disabledCheckBoxValues}
                    selectedValues={selectedCheckBoxValues}
                    setSelectedValues={setSelectedCheckBoxValues}
                    onChange={(e) => {
                        handleSelectedDay(e.target.defaultValue);
                    }}
                />
            </Flex>
            <Flex
                alignItems="center"
                justifyContent="center"
                marginBottom="20px"
            >
                <Div marginRight="10px">
                    <ImageSvg
                        src="assets/images/weeklyPlan/icon-warning.svg"
                        size="24px"
                    />
                </Div>

                <Paragraph size="xsmall">{lang.dayIsFilled}</Paragraph>
            </Flex>
            <Button onClick={handleAddWeekPlan} label={lang.confirm} />
        </StyledPlannerCard>
    );
};

RecipeWeeklyPlanCheckBoxes.propTypes = {
    selectedCheckBoxValues: PropTypes.array.isRequired,
    disabledCheckBoxValues: PropTypes.array.isRequired,
    handleAddWeekPlan: PropTypes.func.isRequired,
    handleSelectedDay: PropTypes.func.isRequired,
    handleWeekChange: PropTypes.func.isRequired,
    memoFiveWeeksItems: PropTypes.array.isRequired,
    setSelectedCheckBoxValues: PropTypes.func.isRequired,
    selectedWeek: PropTypes.array.isRequired,
};

export const ConnectedRecipeWeeklyPlanCheckBoxes = ({
    selectedRecipe,
    clickPosition,
}) => {
    const {
        selectedCheckBoxValues,
        disabledCheckBoxValues,
        handleAddWeekPlan,
        handleSelectedDay,
        handleWeekChange,
        memoFiveWeeksItems,
        setSelectedCheckBoxValues,
        selectedWeek,
    } = useRecipeWeeklyPlanner(selectedRecipe, clickPosition);

    return (
        <RecipeWeeklyPlanCheckBoxes
            selectedCheckBoxValues={selectedCheckBoxValues}
            disabledCheckBoxValues={disabledCheckBoxValues}
            handleAddWeekPlan={handleAddWeekPlan}
            handleSelectedDay={handleSelectedDay}
            handleWeekChange={handleWeekChange}
            memoFiveWeeksItems={memoFiveWeeksItems}
            setSelectedCheckBoxValues={setSelectedCheckBoxValues}
            selectedWeek={selectedWeek}
        />
    );
};

ConnectedRecipeWeeklyPlanCheckBoxes.propTypes = {
    selectedRecipe: PropTypes.number.isRequired,
    clickPosition: PropTypes.string.isRequired,
};

export default RecipeWeeklyPlanCheckBoxes;
