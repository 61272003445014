import {useLocation} from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import {ReactSVG} from 'react-svg';

const ImageSvg = ({src, onClick, size}) => {
    const location = useLocation();
    return (
        <ReactSVG
            beforeInjection={(svg) => {
                svg.setAttribute('style', `width: ${size}`);
            }}
            className="app-svg-wrapper"
            evalScripts="always"
            httpRequestWithCredentials={true}
            onClick={onClick}
            renumerateIRIElements={false}
            src={`${location.origin}/${src}`}
        />
    );
};

ImageSvg.propTypes = {
    src: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    size: PropTypes.string,
};

ImageSvg.defaultProps = {
    onClick: null,
    size: null,
};

export default ImageSvg;
