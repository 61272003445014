import React from 'react';
import PropTypes from 'prop-types';

import List from 'shared-ui/components/Elements/List';
import Headline from 'shared-ui/components/Elements/Headline';
import Infobox from 'shared-ui/components/Elements/Infobox';
import Time from 'shared-ui/components/Elements/Time';

import {lang} from 'shared-ui/lang/global.lang';

import {StyledRecipeSteps} from './styles';

const RecipeSteps = ({steps, infoText, workingTime, cookingTime, width}) => {
    return (
        <StyledRecipeSteps width={width}>
            <Headline type="h4">{lang.preparation}</Headline>
            <Time workingTime={workingTime} cookingTime={cookingTime} />
            {infoText && <Infobox text={infoText}></Infobox>}
            <List items={steps} />
        </StyledRecipeSteps>
    );
};

RecipeSteps.propTypes = {
    steps: PropTypes.array.isRequired,
    infoText: PropTypes.string,
    workingTime: PropTypes.number.isRequired,
    cookingTime: PropTypes.number.isRequired,
    width: PropTypes.string,
};

RecipeSteps.defaultProps = {
    width: '',
    infoText: '',
};

export default RecipeSteps;
