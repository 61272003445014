import {useNutritionPlanContext} from 'context/NutritionPlanContext';
import {useEffect, useState} from 'react';
import {useAddNutritionPlan} from 'services/data-hooks/nutritionPlan/useAddNutritionPlan';
import {useNutritionPlan} from 'services/data-hooks/nutritionPlan/useNutritionPlan';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';
import {setRecipeToWeekPlan} from 'utils/helpers/week-plan-generator';
import {useCreateNextWeeks} from 'utils/hooks/useCreateNextWeeks';

const selectedValues = (weeklyPlan, recipeId) => {
    return weeklyPlan?.days.reduce((acc, current) => {
        const find = current.recipes.some((x) => x === recipeId);
        return find ? [...acc, current.dayValue] : acc;
    }, []);
};

const disabledValues = (weeklyPlan, recipeId) => {
    return weeklyPlan?.days.reduce((acc, current) => {
        const findFirstElement =
            current.recipes[0] !== null && current.recipes[0] !== recipeId;
        const findSecondElement =
            current.recipes[1] !== null && current.recipes[1] !== recipeId;
        return findFirstElement && findSecondElement
            ? [...acc, current.dayValue]
            : acc;
    }, []);
};

export const useRecipeWeeklyPlanner = (selectedRecipe, clickPosition) => {
    const {data} = useNutritionPlan();
    const {mutate} = useAddNutritionPlan();
    const {setSelectedWeek, selectedWeek} = useNutritionPlanContext();
    const {memoWeeksItems: memoFiveWeeksItems} = useCreateNextWeeks(5);
    const [selectedCheckBoxValues, setSelectedCheckBoxValues] = useState([]);
    const [disabledCheckBoxValues, setDisabledCheckBoxValues] = useState([]);
    const [initialWeeklyPlanDays, setInitialWeeklyPlanDays] = useState(null);

    useEffect(() => {
        if (data) {
            const selected = selectedValues(data, selectedRecipe.id);
            const disabled = disabledValues(data, selectedRecipe.id);
            setSelectedCheckBoxValues(selected);
            setDisabledCheckBoxValues(disabled);
            setInitialWeeklyPlanDays(data.days);
        }
    }, [data]);

    const handleSelectedDay = (value) => {
        const newWeeklyPlan = setRecipeToWeekPlan(
            initialWeeklyPlanDays,
            selectedRecipe,
            value
        );
        setInitialWeeklyPlanDays(newWeeklyPlan);
    };

    const handleAddWeekPlan = () => {
        mutate(initialWeeklyPlanDays, {
            onSuccess: () => {
                dataLayerPush({
                    event: 'biib_internalLinkClick',
                    eventAsset: 'add_to_calendar',
                    eventAssetBis: clickPosition,
                });
            },
        });
    };

    const handleWeekChange = (e) => {
        const findWeek = memoFiveWeeksItems.find((x) => x.value === e.value);
        setSelectedWeek(findWeek.value);
    };

    return {
        selectedCheckBoxValues,
        disabledCheckBoxValues,
        memoFiveWeeksItems,
        selectedWeek,
        setSelectedCheckBoxValues,
        handleAddWeekPlan,
        handleWeekChange,
        handleSelectedDay,
    };
};
