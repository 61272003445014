import {recipeNormalize} from './recipe-list';

export const normalizeIngredients = (ingredients) =>
    ingredients.map((item) => ({
        id: item.id,
        title: item.name,
        unit: item.unit,
        kcal: parseInt(item.calories),
        fat: parseInt(item.fat),
        protein: parseInt(item.protein),
        carbs: parseInt(item.carbs),
        // if amount = integer, parse it (zero shows no number)
        amount: item.amount % 1 === 0 ? parseInt(item.amount) : item.amount,
        prefix: item.prefix,
        suffix: item.suffix,
        inGlossary: item.in_glossary,
    }));

export const categoryRecipeDetailNormalize = async ({data}) => {
    const normalizedMap = {
        id: data.id,
        headerImage: {
            title: data.title,
            preTitle: 'Übersicht',
            tags: data.tags,
            image: data.banner,
        },
        recipeInfo: {
            level: data.difficulty_level,
            cookingTime: data.preparation_time + data.cooking_time,
            calories: data.calories,
            servings: data.servings,
            description: data.description,
        },
        nutrients: {
            kcal: parseInt(data.per_portion_calories),
            protein: parseInt(data.per_portion_protein),
            fat: parseInt(data.per_portion_fat),
            carbon: parseInt(data.per_portion_carbs),
        },
        ingredients: normalizeIngredients(data.ingredients),
        expedients: data.tools.map((item) => ({
            title: item.name,
            unit: item.amount,
        })),
        recipeSteps: {
            infoText: data.preparation_note,
            workingTime: data.preparation_time,
            cookingTime: data.cooking_time,
            restingTime: data.resting_time,
            steps: data.steps,
        },
        steps: data.steps,
        tips: data.tips,
        tags: data.tags,
        recipeSortInfo: recipeNormalize(data),
    };
    return normalizedMap;
};