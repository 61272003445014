import {makeRequest} from 'services/config/nutrition-api';

export async function duplicateNutritionPlan(weekPlan) {
    const response = await makeRequest(
        `/me/nutrition-plan/many`,
        'post',
        weekPlan
    );
    return response;
}
