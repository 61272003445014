import styled from 'styled-components';

import Div from 'shared-ui/components/Layout/Div';
import {Row} from 'shared-ui/components/Layout/Grid';

import {queries} from 'shared-ui/styles/variables';

export const StyledHeaderWrapper = styled.div`
    position: relative;
    width: 100%;
    @media ${queries.lg} {
        width: 90%;
    } ;
`;

export const StyledRow = styled(Row)`
    padding: 0px;
`;

export const StyledDiv = styled(Div)`
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media ${queries.mdMax} {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`;
