export const setRecipeToWeekPlan = (days, recipe, value) => {
    const findDay = days.find((x) => x.dayValue === value);
    const findRecipe = findDay?.recipes.find((x) => x === recipe.id);
    if (findRecipe) {
        const recipeIndex = findDay.recipes.indexOf(recipe.id);
        findDay.recipes[recipeIndex] = null;
        findDay.recipesDetails[recipeIndex] = null;
    } else {
        if (!findDay.recipes[0]) {
            findDay.recipes[0] = recipe.id;
            findDay.recipesDetails[0] = recipe;
        } else {
            findDay.recipes[1] = recipe.id;
            findDay.recipesDetails[1] = recipe;
        }
    }
    return days;
};

export const selectedValues = (weeklyPlan, recipeId) => {
    return weeklyPlan.days.reduce((acc, current) => {
        const find = current.recipes.some((x) => x === recipeId);
        return find ? [...acc, current.dayValue] : acc;
    }, []);
};

export const disabledValues = (weeklyPlan, recipeId) => {
    return weeklyPlan.days.reduce((acc, current) => {
        const findFirstElement =
            current.recipes[0] !== null && current.recipes[0] !== recipeId;
        const findSecondElement =
            current.recipes[1] !== null && current.recipes[1] !== recipeId;
        return findFirstElement && findSecondElement
            ? [...acc, current.dayValue]
            : acc;
    }, []);
};
