import React from 'react';
import PropTypes from 'prop-types';

import CheckBox from '../CheckBox';
import Icon from '../Icon';
import Flex from '../../Layout/Flex';
import Div from '../../Layout/Div';

const CheckBoxGroup = ({
    items,
    setSelectedValues,
    selectedValues,
    onChange,
    disableItems,
}) => {
    const handleOnChange = (value) => {
        if (selectedValues.indexOf(value) > -1) {
            setSelectedValues(
                selectedValues.filter(
                    (selectedValue) => selectedValue !== value
                )
            );
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };

    return (
        <Div className="ap-checkbox">
            {items.map(({value, label}, index) => {
                const isDisabled = disableItems?.some((x) => x === value);
                return (
                    <Flex key={index} alignItems="center">
                        <CheckBox
                            id={index}
                            label={label}
                            disabled={isDisabled}
                            checked={selectedValues?.indexOf(value) > -1}
                            value={value}
                            onChange={(e) => {
                                handleOnChange(value);
                                onChange(e);
                            }}
                        />
                        {isDisabled && (
                            <Div marginTop="8px">
                                <Icon name="warning" size="20px" />
                            </Div>
                        )}
                    </Flex>
                );
            })}
        </Div>
    );
};

CheckBoxGroup.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    setSelectedValues: PropTypes.func,
    selectedValues: PropTypes.array,
    disableItems: PropTypes.array,
};

CheckBoxGroup.defaultProps = {
    selectedValues: null,
    setSelectedValues: () => {},
    onChange: () => {},
    disableItems: [],
};

export default CheckBoxGroup;
