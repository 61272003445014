import React from 'react';
import PropTypes from 'prop-types';
import {Droppable} from 'react-beautiful-dnd';

import Image from 'shared-ui/components/Elements/Image';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';

import AlertCheck from 'assets/images/icon-alert-check.svg';
import AlertTimes from 'assets/images/icon-alert-times.svg';

import {TargetCard, AlertCard} from './styles';
import {useGameContext} from 'context/GameContext';

const Target = ({src, title, type, droppableId}) => {
    const {trueType, falseType, alertText, isShowAlertType} = useGameContext();

    const isShowAlert = isShowAlertType === type && (trueType || falseType);

    return (
        <Droppable droppableId={droppableId} type="product">
            {(provided) => (
                <div
                    className="target-card"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    <TargetCard flexDirection="column" alignItems="center">
                        <Flex alignItems="center" flex={1}>
                            <Headline type="h4">{title}</Headline>
                        </Flex>
                        <Flex alignItems="center" flex={2}>
                            <Image width="100%" src={src} alt="" />
                        </Flex>
                    </TargetCard>
                    {isShowAlert && (
                        <AlertCard trueType={trueType}>
                            <Flex alignItems="center">
                                <Div marginRight="20px">
                                    <Image
                                        width="30px"
                                        src={trueType ? AlertCheck : AlertTimes}
                                        alt=""
                                    />
                                </Div>
                                <Paragraph size="xxsmall">
                                    {alertText}
                                </Paragraph>
                            </Flex>
                        </AlertCard>
                    )}
                </div>
            )}
        </Droppable>
    );
};

Target.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    droppableId: PropTypes.string,
};

Target.defaultProps = {
    droppableId: null,
};

export default Target;
