import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {queries} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    @media ${queries.md} {
        width: 16.875rem;
    }
    width: 12.5rem;
    margin: 0.625rem 0;
`;

export const CardParagraph = styled(Paragraph)`
    margin-left: 1rem;
`;
