import {useQuery} from 'react-query';

import {fetchIngredients} from 'services/request/ingredients-list';
import {INGREDIENTS} from 'services/config/query-keys';
import {ingredientsListNormalize} from 'services/normalize/ingredients-list';

const getIngredients = async () => {
    const data = await fetchIngredients();
    const normalize = await ingredientsListNormalize(data);
    return normalize;
};

export function useIngredients() {
    return useQuery(INGREDIENTS, getIngredients);
}
