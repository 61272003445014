import styled, {css} from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Flex from 'shared-ui/components/Layout/Flex';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Button from 'shared-ui/components/Elements/Button';

import {queries, shadow} from 'shared-ui/styles/variables';

const ImageStyles = css`
    width: 100%;
    height: 200px;
    border-radius: 0.625rem;
`;

export const StyledImage = styled.div`
    ${ImageStyles};

    width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: 500ms;

    ${({backgroundImage}) =>
        backgroundImage &&
        css`
            background-image: url(${backgroundImage});
            background-size: cover;
            background-position: center;
        `}
`;

export const ImageWrapper = styled.div`
    ${ImageStyles};

    overflow: hidden;

    @media ${queries.mdMax} {
        max-width: 100px;
        height: 115px;
        margin: 0.9rem 0.325rem;
    }
`;

export const CatgoriesWrapper = styled.div`
    @media ${queries.sm} {
        width: 40%;
    }
    @media ${queries.md} {
        position: absolute;
        top: 3.125rem;
        left: 2.5rem;
    }
`;

export const Window = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 24rem;
    max-height: 50rem;
    max-width: 90vw;
    border-radius: 15px;
    overflow: hidden;
    padding: 0;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 50%;
    @media ${queries.md} {
        justify-content: space-around;
    }
    button {
        @media ${queries.mdMax} {
            margin-right: 0.625rem;
        }
    }
`;

export const Wrapper = styled(Flex)`
    flex-direction: row;
    height: 100%;
    @media ${queries.md} {
        flex-direction: column;
    }
`;
export const ContentWrapper = styled(Flex)`
    width: 60%;
    height: 50%;
    justify-content: space-between;
    @media ${queries.mdMax} {
        margin-top: 3px;
        margin-left: 10px;
        width: 100%;
        height: 120px;
        justify-content: space-between;
    }
    @media ${queries.md} {
        width: 100%;
    }
`;

export const StyledCard = styled(Card)`
    width: 100%;

    &:hover {
        ${StyledImage} {
            transform: scale(1.1);
        }
    }

    @media ${queries.md} {
        height: 385px;
    }
    @media ${queries.mdMax} {
        margin: 10px 0;
        padding: 1.6px 8px;
    }
`;

export const StyledButton = styled(Button)`
    margin: 5px;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    box-shadow: ${shadow};

    @media ${queries.md} {
        width: 75px;
        height: 75px;
    }
    @media ${queries.lg} {
        width: 65px;
        height: 65px;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    padding: 0px;
    padding-bottom: 11px;

    @media ${queries.md} {
        margin-top: 10px;
    }
    @media ${queries.mdMax} {
        margin-top: 10px;
    }
`;

export const TitleWrapper = styled.div`
    cursor: pointer;
    height: 100px;
`;
