import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Icon from 'shared-ui/components/Elements/Icon';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {color} from 'shared-ui/styles/variables';
import {lang} from 'shared-ui/lang/global.lang';
import {routes} from 'shared-utils/utils/routes';

import {
    IconSection,
    IconWrapper,
    ImageWrapper,
    StyledImage,
    StyledDiv,
} from './styles';

export default function RecipeCard({
    headline,
    minutes,
    difficulty,
    kcal,
    image,
    inSidebar,
    id,
}) {
    return (
        <StyledDiv onClick={() => navigate(`${routes.recipe}/${id}`)}>
            <ImageWrapper>
                <StyledImage width="100%" src={image} alt="recipe" responsive />
            </ImageWrapper>
            <Headline type={inSidebar ? 'h5' : 'h4'} marginTop="16px">
                {headline}
            </Headline>
            <IconSection>
                <IconWrapper>
                    <Icon name="clock" color={color.red} size="24px" />
                    <Paragraph theme="grey">{`${minutes} min`}</Paragraph>
                </IconWrapper>
                <IconWrapper>
                    <Icon
                        name={lang.difficultyLevel[difficulty - 1]}
                        color={color.red}
                        size="24px"
                    />
                    <Paragraph theme="grey">
                        {lang.difficultyLevel[difficulty - 1] || ``}
                    </Paragraph>
                </IconWrapper>
                <IconWrapper>
                    <Icon name="kcal" color={color.red} size="24px" />
                    <Paragraph theme="grey">{`${kcal} kcal`}</Paragraph>
                </IconWrapper>
            </IconSection>
        </StyledDiv>
    );
}

RecipeCard.propTypes = {
    image: PropTypes.string,
    headline: PropTypes.string,
    minutes: PropTypes.number,
    difficulty: PropTypes.number,
    kcal: PropTypes.number,
    inSidebar: PropTypes.bool,
    id: PropTypes.number,
};

RecipeCard.defaultProps = {
    image: '',
    headline: '',
    minutes: 2,
    difficulty: 5,
    kcal: 5,
    inSidebar: false,
    id: 5,
};
