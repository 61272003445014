import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import BackButton from 'shared-ui/components/Layout/BackButton';
import AnimatedTab from 'shared-ui/components/Elements/AnimatedTab';

const AppLayout = ({children, location}) => {
    useEffect(() => window.scrollTo(0, 0), [location.pathname]);
    return (
        <div className="psp-app-body">
            {location.pathname !== '/nutritionmanager' && <BackButton />}
            {children}
            <AnimatedTab />
        </div>
    );
};

AppLayout.propTypes = {
    children: PropTypes.any.isRequired,
    location: PropTypes.object,
};

AppLayout.defaultProps = {
    location: {},
};
export default AppLayout;
