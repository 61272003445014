import {normalizeIngredients} from './recipe-detail';

export const recipeNormalize = (recipe) => {
    if (!recipe) return null;
    return {
        id: recipe.id,
        image: recipe.thumbnail,
        title: recipe.title,
        minutes: recipe.total_time,
        difficulty: recipe.difficulty_level,
        kcal: recipe.calories,
        isFavorite: recipe.is_favorite,
        ingredients: recipe.ingredients
            ? normalizeIngredients(recipe.ingredients)
            : [],
    };
};

export const recipeListNormalize = async (data, meta) => {
    const normalizedMap = data.map((item) => recipeNormalize(item));
    const normalizeMeta = {
        currentPage: meta.current_page,
        from: meta.from,
        last_page: meta.lastPage,
        per_page: meta.perPage,
        to: meta.to,
        total: meta.total,
    };
    return {data: normalizedMap, meta: normalizeMeta};
};
