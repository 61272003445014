import TagManager from 'react-gtm-module';

export const dataLayerPush = (object) => {
    if (typeof document !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(object);
    }
};

export const tagManagerArgs = {
    gtmId: 'GTM-5NQNMZ8',
};

export {TagManager};
