import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Tips from 'shared-ui/components/Elements/Tips';
import ProgressBar from 'shared-ui/components/Elements/ProgressBar';
import Headline from 'shared-ui/components/Elements/Headline';
import Flex from 'shared-ui/components/Layout/Flex';

import {useMediaQuery, useOnClickOutside} from 'shared-utils';
import {queries} from 'shared-ui/styles/variables';

import girl from 'nutrition-manager/src/assets/images/tooltips/img-girl-2x.png';
import {useToolTip} from 'context/ToolTipContext';
import {tooltips} from 'lang/tooltips.lang';

import {
    StyledTooltip,
    StyledImage,
    StyledParagraph,
    Instruction,
    Close,
} from './styles';

const Tooltip = ({
    tipIndex,
    arrowPos,
    top,
    left,
    right,
    bottom,
    position,
    theme,
}) => {
    const ref = useRef();
    const isLg = useMediaQuery(queries.lg);
    const {handleCloseOnClick, screenToolTips, setScreenToolTipIndex} =
        useToolTip();

    useOnClickOutside(ref, () => handleCloseOnClick(tipIndex));

    useEffect(() => {
        setScreenToolTipIndex(tipIndex);
    }, []);

    const isActive = screenToolTips[0] === tipIndex;

    if (!isActive) {
        return null;
    }
    return (
        <>
            <StyledTooltip
                ref={ref}
                data-tooltipid={tipIndex}
                isOpen={isActive}
                theme={theme}
                arrowPos={arrowPos && arrowPos}
                top={top && top}
                left={left && left}
                right={right && right}
                bottom={bottom && bottom}
                position={position && position}
            >
                <Tips
                    margin="4rem 0 0 0"
                    iconPosition="left"
                    iconName="tipp"
                    width={isLg ? '100%' : '95%'}
                >
                    <Close
                        name="close"
                        color="white"
                        onClick={() => handleCloseOnClick(tipIndex)}
                    />
                    <StyledImage
                        src={girl}
                        height={isLg ? '15rem' : '10rem'}
                        alt="Tutorial"
                    />

                    <ProgressBar
                        steps={tooltips.length + 1}
                        activeStep={tipIndex + 1}
                        theme="secondary"
                        margin="1.5rem 0 0 0"
                        width="90%"
                    />
                    <Headline theme="white" type="h4" marginTop="5rem">
                        {tooltips[tipIndex].headline}
                    </Headline>
                    <StyledParagraph theme="white">
                        {tooltips[tipIndex].text}
                    </StyledParagraph>

                    <Flex justifyContent="center" marginTop="1rem">
                        <Instruction
                            src={tooltips[tipIndex].image}
                            alt="Anweisung"
                        />
                    </Flex>
                </Tips>
            </StyledTooltip>
        </>
    );
};

Tooltip.propTypes = {
    tipIndex: PropTypes.array,
    arrowPos: PropTypes.array,
    left: PropTypes.array,
    right: PropTypes.array,
    top: PropTypes.array,
    bottom: PropTypes.array,
    position: PropTypes.array,
    theme: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
    tipIndex: 0,
    arrowPos: 'left',
    left: null,
    top: null,
    right: null,
    bottom: null,
    position: null,
};

export default Tooltip;
