import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';

import {queries} from 'shared-ui/styles/variables';

export const StyledNutrients = styled.div``;

export const NutrientsWrapper = styled(Flex)`
    flex-flow: row wrap;
    @media ${queries.lg} {
        flex-flow: row;
    }
`;

export const Box = styled(Card)`
    margin-top: 16px;
    margin-right: 16px;
    justify-content: center;
    padding: 8px !important;
    width: unset;
    min-width: 96px;

    &:last-child {
        min-width: 128px;
    }
`;

export const Text = styled(Paragraph)`
    padding: 0;
    margin-left: 6px;
`;
