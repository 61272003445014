import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Row, Column} from 'shared-ui/components/Layout/Grid';
import Div from 'shared-ui/components/Layout/Div';
import Lightbox from 'shared-ui/components/Elements/Lightbox';

import RecipeCategory from 'components/Layout/RecipeCategory';
import {ConnectedRecipeWeeklyPlanCheckBoxes} from 'components/Layout/RecipeWeeklyPlanCheckBoxes';

const RecipeList = ({recipes}) => {
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleCalendarOnclick = (recipe) => {
        setSelectedRecipe(recipe);
        setIsOpen(true);
    };
    return (
        <>
            <Row>
                {recipes?.map((recipe, index) => (
                    <Column
                        key={index}
                        cols={{
                            xxs: 12,
                            sm: 10,
                            md: 6,
                            lg: 4,
                            xl: 3,
                        }}
                        offset={{
                            xxs: 0,
                            sm: 1,
                            md: 0,
                            lg: 0,
                            xl: 0,
                        }}
                    >
                        <Div marginBottom="16px">
                            <RecipeCategory
                                recipe={recipe}
                                handleCalendarOnclick={handleCalendarOnclick}
                            />
                        </Div>
                    </Column>
                ))}
            </Row>
            <Lightbox isOpen={isOpen} isOrangeBg onClose={() => setIsOpen()}>
                <ConnectedRecipeWeeklyPlanCheckBoxes
                    isOpen={isOpen}
                    selectedRecipe={selectedRecipe}
                    clickPosition="category"
                />
            </Lightbox>
        </>
    );
};

RecipeList.propTypes = {
    recipes: PropTypes.array.isRequired,
};

export default RecipeList;
