import React from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {color, queries} from 'shared-ui/styles/variables';
import {lang} from 'shared-ui/lang/global.lang';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {StyledRecipeInfo, InfoWrapper, Info, InfoIcon} from './styles';

const RecipeInfo = ({
    level,
    cookingTime,
    calories,
    servings,
    description,
    width,
}) => {
    const isSm = useMediaQuery(queries.sm);
    return (
        <StyledRecipeInfo width={width}>
            <InfoWrapper>
                <Info alignItems="center">
                    <InfoIcon
                        name={lang.difficultyLevel[level - 1]}
                        color={color.red}
                    />
                    <Paragraph
                        size={isSm ? 'xxsmallplus' : 'xxxsmallplus'}
                        theme="darkBlue50"
                    >
                        {lang.difficultyLevel[level - 1]}
                    </Paragraph>
                </Info>
                <Info alignItems="center">
                    <InfoIcon name="clock" color={color.red} />
                    <Paragraph
                        size={isSm ? 'xxsmallplus' : 'xxxsmallplus'}
                        theme="darkBlue50"
                    >
                        {cookingTime} {lang.minute}
                    </Paragraph>
                </Info>
                <Info alignItems="center">
                    <InfoIcon name="kcal" color={color.red} />
                    <Paragraph
                        size={isSm ? 'xxsmallplus' : 'xxxsmallplus'}
                        theme="darkBlue50"
                    >
                        {calories} {lang.kcal}
                    </Paragraph>
                </Info>
                <Info alignItems="center">
                    <InfoIcon name="user" color={color.red} />
                    <Paragraph
                        size={isSm ? 'xxsmallplus' : 'xxxsmallplus'}
                        theme="darkBlue50"
                    >
                        {servings}
                    </Paragraph>
                </Info>
            </InfoWrapper>
            <Paragraph>{description}</Paragraph>
        </StyledRecipeInfo>
    );
};

RecipeInfo.propTypes = {
    description: PropTypes.string.isRequired,
    cookingTime: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    servings: PropTypes.number.isRequired,
    width: PropTypes.string,
};

RecipeInfo.defaultProps = {
    width: '100%',
};

export default RecipeInfo;
