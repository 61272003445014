import {useMutation, useQueryClient} from 'react-query';
import {notify} from 'react-notify-toast';

import {duplicateNutritionPlan} from 'services/request/duplicate-nutrition-plan';
import {duplicateNutritionPlanNormalize} from 'services/normalize/duplicate-nutrition-plan';
import {useNutritionPlanContext} from 'context/NutritionPlanContext';
import {NUTRITION_PLAN} from 'services/config/query-keys';
import {lang} from 'shared-ui/lang/global.lang';

export const getDuplicateNutritionPlan = async (values, currentYear) => {
    const normalize = await duplicateNutritionPlanNormalize({
        values,
        currentYear,
    });
    const response = await duplicateNutritionPlan(normalize);
    return response;
};

export const useDuplicateNutritionPlan = () => {
    const cache = new useQueryClient();
    const {year} = useNutritionPlanContext();
    const mutate = useMutation(
        (values) => {
            getDuplicateNutritionPlan(values, year);
        },
        {
            onSuccess: () => {
                notify.show(`${lang.recipeHasSaved}`, 'success');
                cache.refetchQueries(NUTRITION_PLAN);
            },
        }
    );
    return mutate;
};
