import {useQuery} from 'react-query';

import {makeRequest} from 'services/config/nutrition-api';
import {NUTRITION_PLAN} from 'services/config/query-keys';
import {nutritionPlanNormalize} from 'services/normalize/nutrition-plan';

import {useNutritionPlanContext} from 'context/NutritionPlanContext';

const getNutritionPlan = async (currentYear) => {
    const response = await makeRequest(
        `/me/nutrition-plan?filter[week]=${currentYear}`,
        'get'
    );
    const normalize = await nutritionPlanNormalize(response);
    return normalize;
};

export function useNutritionPlan() {
    const {stringfyCurrentYear} = useNutritionPlanContext();
    return useQuery(
        [NUTRITION_PLAN, stringfyCurrentYear],
        () => getNutritionPlan(stringfyCurrentYear),
        {
            enabled: !!stringfyCurrentYear,
            staleTime: Infinity,
        }
    );
}
