import {useQuery} from 'react-query';

import {fetchRecipesOfIngredient} from 'services/request/recipes-of-ingredient';
import {RECIPE_OF_INGREDIENTS} from 'services/config/query-keys';
import {recipeListNormalize} from 'services/normalize/recipe-list';

const getRecipeOfIngredient = async (ingredientsId) => {
    const {data, meta} = await fetchRecipesOfIngredient(ingredientsId);
    const normalize = await recipeListNormalize(data, meta);
    return normalize.data.slice(0, 4);
};

export function useRecipeOfIngredient(ingredientsId) {
    return useQuery([RECIPE_OF_INGREDIENTS, ingredientsId], () =>
        getRecipeOfIngredient(ingredientsId)
    );
}
