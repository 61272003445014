import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';

import {cardRadius, shadow} from 'shared-ui/styles/variables';

export const ImageWrapper = styled.div`
    width: 70%;
    height: 145px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: ${cardRadius};
    box-shadow: ${shadow};
    background-image: ${({bgImage}) => bgImage && `url(${bgImage})`};
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
`;

export const IconsWrapper = styled.div`
    width: 30%;
    height: 100%;
    flex-direction: column;
    padding-left: 10px;
    display: inline-flex;
    justify-content: space-between;
`;

export const BottomWrapper = styled.div`
    flex-direction: row;
    display: inline-flex;
    margin: 0.3125rem 0;
    height: 145px;
`;

export const TitleCard = styled(Card)`
    margin-bottom: 0.625rem;
`;

export const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 100%;
`;
