import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {queries} from 'shared-ui/styles/variables';

export const CategoryImageWrapper = styled.div`
    overflow: hidden;
    height: 4.375rem;
    border-radius: 0.6rem;

    @media ${queries.md} {
        height: 10.3125rem;
    }
    @media ${queries.mdMax} {
        margin: 0.3125rem 0.4375rem 0px;
    }
`;

export const CategoryImage = styled.div`
    width: 100%;
    height: 100%;
    transition: 500ms;
    background-image: url(${({image}) => image && image});
    background-size: cover;
    background-position: center;
`;

export const StyledCard = styled(Card)`
    border: 1px solid transparent;
    height: 100%;
    justify-content: flex-start;

    @media ${queries.mdMax} {
        padding: 0px 0px;
    }

    &:hover {
        ${CategoryImage} {
            transform: scale(1.1);
        }
    }
`;

export const StyledHeadline = styled(Headline)`
    margin: 5px 0 !important;
`;

export const ParagraphWrapper = styled.div`
    margin-top: 0.625rem;
    margin-bottom: 0.1875rem;
`;

export const StyledParagraph = styled(Paragraph)`
    padding: 0px 0px;
`;
