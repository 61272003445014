import styled, {css} from 'styled-components';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Image from 'shared-ui/components/Elements/Image';
import Icon from 'shared-ui/components/Elements/Icon';
import {queries, transition} from 'shared-ui/styles/variables';

import arrowUp from 'nutrition-manager/src/assets/images/tooltips/img-pfeil.png';
// import arrowDown from 'nutrition-manager/src/assets/images/tooltips/img-pfeil-down.png';

const tooltipTheme = {
    homepageNavigation: css`
        margin-top: 50px;
        margin-left: 0px;
        position: absolute;
        @media ${queries.lgMax} {
            margin-top: 250px;
            margin-left: 0px;
        }
    `,
    navigation: css`
        bottom: 170px;
        right: 200px;
        position: fixed;
        @media ${queries.lgMax} {
            bottom: 250px;
            right: 0px;
            max-width: 95%;
        }
    `,
    weeklyPlanner: css`
        margin-top: 120px;
        margin-left: 220px;
        position: absolute;
        @media ${queries.smMax} {
            margin-top: 120px;
            margin-left: 0px;
            max-width: 100%;
        }
        @media ${queries.mdMax} {
            margin-top: 200px;
            margin-left: 0px;
            max-width: 100%;
        }
    `,
    categories: css`
        margin-left: 340px;
        margin-top: 120px;
        position: absolute;
        @media ${queries.lgMax} {
            margin-left: 180px;
            margin-top: 220px;
            max-width: 95%;
        }
        @media ${queries.mdMax} {
            margin-left: 0px;
            margin-top: 220px;
            max-width: 95%;
        }
    `,
    categoryDetail: css`
        margin-left: 280px;
        margin-top: 20px;
        position: absolute;
        @media ${queries.mdMax} {
            position: fixed;
            bottom: 250px;
            left: 20px;
            margin-left: 0px;
            margin-top: 0px;
            max-width: 95%;
        }
    `,
    recipeDetail: css`
        margin-left: 250px;
        margin-top: 200px;
        position: absolute;
        width: 100vw;
        @media ${queries.lgMax} {
            position: fixed;
            bottom: 250px;
            left: 20px;
            margin-left: 0px;
            margin-top: 0px;
            width: 95vw;
            max-width: 95%;
        }
    `,
};
const tooltipArrowTheme = {
    homepageNavigation: css`
        top: 128px;
        left: -128px;
        position: absolute;
        @media ${queries.lgMax} {
            top: -100px;
            left: 140px;
            transform: rotate(220deg);
        }
    `,
    navigation: css`
        bottom: 0px;
        right: -130px;
        transform: rotate(180deg);
        @media ${queries.lgMax} {
            bottom: -150px;
            right: 110px;
            transform: rotate(180deg) matrix(0, 1, 1, 0, 0, 0);
        }
    `,
    weeklyPlanner: css`
        top: 95px;
        left: -150px;
        @media ${queries.smMax} {
            top: -75px;
            left: 150px;
            transform: matrix(0, 1, 1, 0, 0, 0);
        }
        @media ${queries.mdMax} {
            top: -75px;
            left: 150px;
            transform: matrix(0, 1, 1, 0, 0, 0);
        }
    `,
    categories: css`
        top: 95px;
        left: -150px;
        @media ${queries.lgMax} {
            top: -95px;
            left: 180px;
            transform: rotate(0deg) matrix(0, 1, 1, 0, 0, 0);
        }
        @media ${queries.mdMax} {
            top: -95px;
            left: 140px;
            transform: rotate(0deg) matrix(0, 1, 1, 0, 0, 0);
        }
    `,
    categoryDetail: css`
        top: 95px;
        left: -150px;
        @media ${queries.mdMax} {
            bottom: -140px;
            left: 0px;
            top: inherit;
            transform: rotate(210deg) matrix(0, 1, 1, 0, 0, 0);
        }
    `,
    recipeDetail: css`
        top: 335px;
        left: -150px;
        @media ${queries.lgMax} {
            bottom: -140px;
            left: 0px;
            top: inherit;
            transform: rotate(210deg) matrix(0, 1, 1, 0, 0, 0);
        }
    `,
};

export const StyledTooltip = styled.div`
    max-width: 800px;
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    transition: ${transition};
    ${({theme}) => tooltipTheme[theme]};
    z-index: 1000;

    &::before {
        content: '';
        background: url(${arrowUp}) no-repeat;
        background-size: contain;
        position: absolute;
        width: 7rem;
        height: 7rem;
        ${({theme}) => tooltipArrowTheme[theme]};
    }
`;

export const StyledImage = styled(Image)`
    position: absolute;
    right: -2.4rem;
    top: 5rem;
    @media ${queries.smMax} {
        right: -1.6rem;
        top: 6rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    max-width: 90%;
`;

export const Instruction = styled(Image)`
    max-height: 20rem;
    max-width: 80%;
`;

export const Close = styled(Icon)`
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    pointer-events: auto;
`;
export const Overlay = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: red;
`;
