import PropTypes from 'prop-types';
import React from 'react';

import {PageWrapepr} from './styles';

const PageLayout = ({children, bgWhite}) => {
    return <PageWrapepr bgWhite={bgWhite}>{children}</PageWrapepr>;
};

PageLayout.propTypes = {
    children: PropTypes.any.isRequired,
    bgWhite: PropTypes.bool,
};

PageLayout.defaultProps = {
    bgWhite: false,
};

export default PageLayout;
