import {lang} from 'shared-ui/lang/global.lang';

import {recipeNormalize} from './recipe-list';

export const nutritionPlanNormalize = async ({data}) => {
    const normalizedMap = {
        recipeCount: data.recipes_count,
        days: data.plan.map((item, index) => ({
            dayValue: lang.weekDays[index].value,
            recipesDetails: [
                recipeNormalize(item[0]),
                recipeNormalize(item[1]),
            ],
            recipes: [item[0]?.id || null, item[1]?.id || null],
        })),
    };
    return normalizedMap;
};
