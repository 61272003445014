import {useMutation} from 'react-query';

import {addNutritionPlanNormalize} from 'services/normalize/add-nutrition-plan';
import {useNutritionPlanContext} from 'context/NutritionPlanContext';
import {NUTRITION_PLAN} from 'services/config/query-keys';
import {makeRequest} from 'services/config/nutrition-api';
import {useSetQueryData} from 'utils/hooks/useSetQueryData';

export const getRemoveNutritionPlan = async (weekPlan, year, selectedWeek) => {
    const normalize = await addNutritionPlanNormalize(
        weekPlan,
        year,
        selectedWeek
    );
    await makeRequest(`/me/nutrition-plan`, 'POST', normalize);
};

export const useRemoveNutritionPlan = () => {
    const {setData, queryClient} = useSetQueryData();
    const {year, selectedWeek, stringfyCurrentYear} = useNutritionPlanContext();
    const mutate = useMutation(
        (weekPlan) => {
            getRemoveNutritionPlan(weekPlan, year, selectedWeek);
        },
        {
            onMutate: async (values) => {
                const dataLength = values
                    .map((item) => item.recipes)
                    .flat()
                    .filter((item) => item !== null).length;

                const weekData = {
                    days: values,
                    recipeCount: dataLength,
                };
                const previousValue = queryClient.getQueryData([
                    NUTRITION_PLAN,
                    stringfyCurrentYear,
                ]);
                setData([NUTRITION_PLAN, stringfyCurrentYear], weekData);
                return previousValue;
            },
            onError: (err, variables, previousValue) => {
                queryClient.setQueryData(
                    [NUTRITION_PLAN, stringfyCurrentYear],
                    previousValue
                );
            },
        }
    );
    return mutate;
};
