import styled from 'styled-components';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';

import {color} from 'shared-ui/styles/variables';

export const Title = styled(Paragraph)`
    margin: 0 16px;
    flex: 1;
    border-bottom: 1px solid ${color.lightGrey};
    padding: 16px 0;
`;

export const UnitWrapper = styled(Flex)`
    background-color: ${color.lightGrey};
    width: 140px;
    text-align: right;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
`;

export const Unit = styled(Paragraph)`
    padding: 16px 8px;
    margin: 0px 8px;
    width: 100%;
    text-align: right;
    border-bottom: 1px solid ${color.grey};
`;

export const ItemWrapper = styled(Flex)`
    width: 100%;
    &:last-child {
        p {
            border-bottom: none;
        }
        ${UnitWrapper} {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
    &:first-child {
        ${UnitWrapper} {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
    }
`;
