import React from 'react';
import PropTypes from 'prop-types';

import {lang} from 'shared-ui/lang/global.lang';

import {StyledNutrients, NutrientsWrapper, Box, Text} from './styles';

const Nutrients = ({kcal, protein, fat, carbon, theme}) => {
    return (
        <StyledNutrients>
            <NutrientsWrapper>
                <Box theme={theme} size="80px">
                    <Text theme="darkBlue35" bold>
                        {lang.kcal}
                    </Text>
                    <Text theme="darkBlue">{kcal}</Text>
                </Box>
                <Box theme={theme} size="80px">
                    <Text theme="darkBlue35" bold>
                        {lang.protein}
                    </Text>
                    <Text theme="darkBlue">{protein} g</Text>
                </Box>
                <Box theme={theme} size="80px">
                    <Text theme="darkBlue35" bold>
                        {lang.fat}
                    </Text>
                    <Text theme="darkBlue">{fat} g</Text>
                </Box>
                <Box theme={theme} size="80px">
                    <Text theme="darkBlue35" bold>
                        {lang.carbohydrates}
                    </Text>
                    <Text theme="darkBlue">{carbon} g</Text>
                </Box>
            </NutrientsWrapper>
        </StyledNutrients>
    );
};

Nutrients.propTypes = {
    kcal: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    carbon: PropTypes.number.isRequired,
    theme: PropTypes.oneOf(['primary', 'secondary']),
};

Nutrients.defaultProps = {
    theme: 'primary',
};

export default Nutrients;
