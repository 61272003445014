import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {MenuItem, StyledParagprah} from './styles';

const AnchorLeftMenu = ({menuItems, offset}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleActiveIndex = (e) => {
        const findIndex = menuItems.findIndex((x) => x.pathId === e);
        setActiveIndex(findIndex);
    };

    return menuItems.map((item, index) => (
        <MenuItem
            key={index}
            onSetActive={(e) => handleActiveIndex(e)}
            activeClass="active"
            hashSpy={true}
            spy={true}
            smooth={true}
            offset={offset}
            to={item.pathId}
        >
            <StyledParagprah theme="darkBlue" isActive={activeIndex === index}>
                {item.title}
            </StyledParagprah>
        </MenuItem>
    ));
};

AnchorLeftMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    offset: PropTypes.number,
};

AnchorLeftMenu.defaultProps = {
    isActiveAnchorIndex: 0,
    offset: 0,
};

export default AnchorLeftMenu;
