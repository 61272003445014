import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Row, Container, Column} from 'shared-ui/components/Layout/Grid';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Image from 'shared-ui/components/Elements/Image';
import Div from 'shared-ui/components/Layout/Div';
import Headline from 'shared-ui/components/Elements/Headline';
import Flex from 'shared-ui/components/Layout/Flex';
import {queries, footerTopPadding} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {lang} from 'shared-ui/lang/global.lang';

import GameLevelMotionArea from 'components/Layout/GameLevelMotionArea';
import Avocado from 'assets/images/placeholder-avocado.png';
import {levels} from 'lang/game-levels.lang';

import {useGameContext} from 'context/GameContext';

import {
    BackgroundImage,
    CardsWrapper,
    LiveContainer,
    ImagesDiv,
} from './styles';

const GameLevel = ({levelPath}) => {
    const isMd = useMediaQuery(queries.md);
    const isMdMax = useMediaQuery(queries.mdMax);
    const {lives} = useGameContext();

    const currentLevelTitle = useMemo(() =>
        levels.find((x) => x.path === levelPath)
    );

    return (
        <Div paddingBottom={footerTopPadding}>
            {!isMdMax && (
                <Div
                    justifyContent="center"
                    paddingTop="25px"
                    paddingBottom="25px"
                >
                    <Headline type="h2" textAlign="center">
                        {lang.storageQuiz}
                    </Headline>
                </Div>
            )}
            <Container bgWhite noGutter>
                <BackgroundImage>
                    {!isMd && (
                        <Div paddingTop="25px" paddingBottom="10px">
                            <Headline
                                type="h3"
                                theme="white"
                                textAlign="center"
                            >
                                Level: {currentLevelTitle.title}
                            </Headline>
                        </Div>
                    )}
                    <CardsWrapper>
                        <Row>
                            {isMd && (
                                <Column
                                    cols={{sm: 12, md: 4, lg: 4}}
                                    offset={{sm: 0, md: 4, lg: 4}}
                                >
                                    <Flex
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Paragraph
                                            type="h2"
                                            textAlign="center"
                                            size="medium"
                                            theme="white"
                                        >
                                            Level: {currentLevelTitle.title}
                                        </Paragraph>
                                    </Flex>
                                </Column>
                            )}
                            <Column cols={{sm: 12, md: 4, lg: 4}}>
                                <LiveContainer
                                    theme="primary"
                                    flexDirection="row"
                                    justifyContent="space-evenly"
                                >
                                    <Paragraph bold size="small">
                                        Leben:
                                    </Paragraph>

                                    {lives.length > 0 &&
                                        lives.map((live) => (
                                            <ImagesDiv key={live}>
                                                <Image
                                                    width="100%"
                                                    height="100%"
                                                    src={Avocado}
                                                    alt={Avocado}
                                                />
                                            </ImagesDiv>
                                        ))}
                                </LiveContainer>
                            </Column>
                        </Row>
                        <GameLevelMotionArea levelPath={levelPath} />
                    </CardsWrapper>
                </BackgroundImage>
            </Container>
        </Div>
    );
};

GameLevel.propTypes = {
    levelPath: PropTypes.string.isRequired,
};

export default GameLevel;
