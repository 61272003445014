import styled from 'styled-components';

import Button from 'shared-ui/components/Elements/Button';

import {queries, shadow, color} from 'shared-ui/styles/variables';

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media ${queries.lgMax} {
        position: fixed;
        opacity: ${(props) => (props.isFixed ? 1 : 0)};
        bottom: 2.5vh;
        justify-content: center;
        transition: all 500ms;
        width: 100%;
        left: 49%;
        transform: translateX(-50%);
        z-index: 1;
    }
`;

export const BackgroundFade = styled.div`
    position: absolute;
    bottom: -2.5vh;
    background: ${color.gradientWhite};
    width: 100vw;
    height: 100%;
    transform: rotate(180deg);
    z-index: -1;

    @media ${queries.lg} {
        display: none;
    }
`;

export const StyledButton = styled(Button)`
    margin: 10px 5px;
    width: 8.125rem;
    height: 4.375rem;
    box-shadow: ${shadow};

    @media ${queries.lgMax} {
        margin: 2px 6px;
        width: 95px;
        height: 50px;
        box-shadow: ${shadow};
    }
`;
