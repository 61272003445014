import styled from 'styled-components';

import Flex from 'shared-ui/components/Layout/Flex';

import {queries} from 'shared-ui/styles/variables';

export const SytledHeaderDiv = styled(Flex)`
    padding-top: 50px;
    padding-bottom: 120px;
    @media ${queries.mdMax} {
        padding-top: 20px;
        padding-bottom: 0px;
        padding-left: 10px;
    }
`;

export const StyledFlex = styled(Flex)`
    margin-top: 4rem;

    @media ${queries.mdMax} {
        margin-top: 1rem;
    }
`;
