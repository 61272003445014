import {useQuery} from 'react-query';

import {fetchShoppingList} from 'services/request/shopping-list';
import {recipeListNormalize} from 'services/normalize/recipe-list';
import {SHOPPING_RECIPE_LIST} from 'services/config/query-keys';

const getShoppingRecipeList = async () => {
    const {data, meta} = await fetchShoppingList();
    const normalize = await recipeListNormalize(data, meta);
    return normalize;
};

export function useShoppingRecipeList() {
    return useQuery(SHOPPING_RECIPE_LIST, getShoppingRecipeList);
}
