import {routes} from 'shared-utils/utils/routes';

import {
    Icon01Variant,
    Icon02Variant,
    Icon03Variant,
    Icon04Variant,
} from './animations';

export const tabs = [
    {
        key: 1,
        icon: 'list',
        variants: Icon01Variant,
        path: routes.shoppingList,
    },
    {
        key: 2,
        icon: 'recipeSelection',
        variants: Icon02Variant,
        path: routes.categories,
    },
    {
        key: 3,
        icon: 'calendar',
        variants: Icon03Variant,
        path: routes.weeklyPlanner,
    },
    {
        key: 4,
        icon: 'home',
        variants: Icon04Variant,
        path: routes.home,
    },
];
