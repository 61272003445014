import {useMutation, useQueryClient} from 'react-query';

import {useFavoriteContext} from 'context/FavContext';
import {removeFavorite} from 'services/request/remove-favorite';
import {FAVORITE_RECIPES} from 'services/config/query-keys';

export const getRemoveFavorite = async (recipeId) => {
    const response = removeFavorite(recipeId);
    return response || false;
};

export const useRemoveFavorite = () => {
    const cache = new useQueryClient();
    const {remove} = useFavoriteContext();
    const mutate = useMutation((recipeId) => getRemoveFavorite(recipeId), {
        onSuccess: (_, recipeId) => {
            remove(recipeId);
            cache.refetchQueries(FAVORITE_RECIPES);
        },
    });
    return mutate;
};
