import React from 'react';
import PropTypes from 'prop-types';

import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';

import {lang} from 'shared-ui/lang/global.lang';

import {ListWrapper, ListItem} from './styles';

const OwnedIngredients = ({items}) => {
    return (
        <Card>
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Headline textAlign="center">{lang.ownedIngredients}</Headline>
                <ListWrapper theme="secondary">
                    {items.map((item, index) => {
                        return (
                            <ListItem key={index}>
                                <Paragraph theme="darkBlue">
                                    {item.title}
                                </Paragraph>
                            </ListItem>
                        );
                    })}
                </ListWrapper>
            </Flex>
        </Card>
    );
};

OwnedIngredients.propTypes = {
    items: PropTypes.array.isRequired,
};

OwnedIngredients.defaultProps = {};

export default OwnedIngredients;
