import styled from 'styled-components';

import {queries} from 'shared-ui/styles/variables';
import Button from 'shared-ui/components/Elements/Button';

export const StyledHeaderWrapper = styled.div`
    position: relative;
    width: 100%;
    @media ${queries.lg} {
        width: 90%;
    } ;
`;

export const GoBackReciepButton = styled(Button)`
    margin-top: 30px;
`;
