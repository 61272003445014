import instruction1 from 'nutrition-manager/src/assets/images/tooltips/tipp-anweisung-1-2x.png';
import instruction2 from 'nutrition-manager/src/assets/images/tooltips/tipp-anweisung-2-2x.png';
import instruction3 from 'nutrition-manager/src/assets/images/tooltips/tipp-anweisung-3-2x.png';
import instruction4 from 'nutrition-manager/src/assets/images/tooltips/tipp-anweisung-4-2x.png';
import instruction5 from 'nutrition-manager/src/assets/images/tooltips/tipp-anweisung-5-2x.png';
import instruction6 from 'nutrition-manager/src/assets/images/tooltips/tipp-anweisung-6-2x.png';

export const tooltips = [
    {
        headline: 'Main Navigation',
        text: 'Herzlich Willkommen beim ErnährungsManager! Auf dieser Seite findest du alle Funktionen, die dir der ErnährungsManager bietet, zum Beispiel den Wochenplaner, das Glossar oder die Rezeptauswahl. Wenn du direkt in die Erstellung deines Ernährungsplans starten möchtest, klicke auf die Kachel "Wochenplan". Wenn du dir erst einmal die Rezepte anschauen möchtest, klicke auf die Kachel "Rezeptauswahl". ',
        image: instruction2,
    },
    {
        headline: 'Navigation',
        text: 'Damit du dich jederzeit bestens zurechtfindest, haben wir dir ein Navigationsmenü eingebaut! Damit kannst du schnell zwischen den Funktionen wechseln, probier‘s doch gleich mal aus! ',
        image: instruction6,
    },
    {
        headline: 'Wochenplan',
        text: 'Hier findest du alle deine gespeicherten Rezepte! Sieht noch etwas leer aus? Klicke auf die freie Stelle im Wochenplan, um in die Rezeptauswahl zu gelangen!',
        image: instruction1,
    },
    {
        headline: 'Rezepte',
        text: 'Hier ist für jede:n was dabei! Worauf hast du am meisten Lust? Kleiner Tipp: Bei den Snacks wird man immer fündig!',
        image: instruction3,
    },
    {
        headline: 'Rezepte Filter',
        text: 'Du magst lieber vegan essen oder hast bestimme Vorlieben? Kein Problem, diese kannst du hier einfach auswählen und die Auswahl an deine Wünsche anpassen!',
        image: instruction4,
    },
    {
        headline: 'Rezepte Aktionen',
        text: 'Du möchtest das Rezept dem Wochenplan, der Einkaufsliste oder deinen Favoriten hinzufügen? Schau mal hier, mit nur einem Klick kannst du genau das tun!',
        image: instruction5,
    },
];
