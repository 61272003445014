import React from 'react';

import FavoriteList from 'components/Templates/FavoriteList';

import {useFavoriteRecipeList} from 'services/data-hooks/favorites/useFavoriteRecipeList';

const FavoritesPage = () => {
    const {data: favorites, isLoading} = useFavoriteRecipeList();

    if (isLoading) {
        return null;
    }

    return <FavoriteList favorites={favorites.data} />;
};

export default FavoritesPage;
