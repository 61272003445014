import {useMutation} from 'react-query';
import {notify} from 'react-notify-toast';

import {useShoppingLisContext} from 'context/ShoppingListContext';
import {addShoppingList} from 'services/request/add-shopping-list';
import {lang} from 'shared-ui/lang/global.lang';

export const getAddShopping = async (recipeId) => {
    const response = addShoppingList(recipeId);
    return response || false;
};

export const useAddShopping = () => {
    const {push} = useShoppingLisContext();
    const mutate = useMutation((recipeId) => getAddShopping(recipeId), {
        onSuccess: (_, recipeId) => {
            notify.show(`${lang.dishHasSuccessfullyAdded}`, 'success');
            push(recipeId);
        },
    });
    return mutate;
};
