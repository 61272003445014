import PropTypes from 'prop-types';
import React from 'react';
import {navigate} from '@reach/router';

import Flex from 'shared-ui/components/Layout/Flex';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {routes} from 'shared-utils/utils/routes';

import {
    StyledHeadline,
    StyledFlex,
    Title,
    Value,
    StyledItemFlex,
    StyledIconsFlex,
} from './PopoverContent.styles';
import {lang} from 'shared-ui/lang/global.lang';

const PopoverContent = ({id, title, unit, kcal, fat, protein, carbs}) => {
    return (
        <Flex flexDirection="column">
            <StyledFlex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Flex flexDirection="column">
                    <StyledHeadline type="h4">{title}</StyledHeadline>
                    <Paragraph
                        bold
                        autoLineHeight
                        size="xsmall"
                        theme="darkBlue"
                    >
                        {unit}
                    </Paragraph>
                </Flex>
                <StyledIconsFlex
                    onClick={() => navigate(`${routes.glossary}/${id}`)}
                    alignItems="center"
                    justifyContent="space-around"
                >
                    <Icon name="glossar" size="32px" color="white" />
                    <Icon name="rightLineArrow" size="32px" color="white" />
                </StyledIconsFlex>
            </StyledFlex>
            <Flex>
                <StyledItemFlex flexDirection="column">
                    <Title>{lang.kcal}</Title>
                    <Value>{kcal}</Value>
                </StyledItemFlex>
                <StyledItemFlex flexDirection="column">
                    <Title>{lang.fat}</Title>
                    <Value>{fat}</Value>
                </StyledItemFlex>
                <StyledItemFlex flexDirection="column">
                    <Title>{lang.protein}</Title>
                    <Value>{protein}</Value>
                </StyledItemFlex>
                <StyledItemFlex flexDirection="column">
                    <Title>{lang.carbohydrates}</Title>
                    <Value>{carbs}</Value>
                </StyledItemFlex>
            </Flex>
        </Flex>
    );
};

PopoverContent.propTypes = {
    title: PropTypes.string,
    unit: PropTypes.string,
    kcal: PropTypes.number,
    fat: PropTypes.string,
    protein: PropTypes.string,
    carbs: PropTypes.string,
    id: PropTypes.number.isRequired,
};

PopoverContent.defaultProps = {
    unit: '',
    title: '',
    kcal: '',
    fat: '',
    protein: '',
    carbs: '',
};

export default PopoverContent;
