export const containerVariant = {
    hidden: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
    show: {
        opacity: 1,
        display: 'flex',
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1,
        },
    },
    leave: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
};

export const mainIconVariant = {
    hidden: {opacity: 1, rotate: 160},
    show: {opacity: 1, rotate: 0},
};

export const Icon01Variant = {
    hidden: {opacity: 0, y: -45, x: 180},
    show: {opacity: 1, y: 25, x: 200},
};

export const Icon02Variant = {
    hidden: {opacity: 0, y: -45, x: 110},
    show: {opacity: 1, y: -16, x: 62},
};

export const Icon03Variant = {
    hidden: {opacity: 0, y: -30, x: 65},
    show: {opacity: 1, y: -90, x: 0},
};

export const Icon04Variant = {
    hidden: {opacity: 0, y: -50, x: 0},
    show: {opacity: 1, y: -125, x: 0},
};

export const btnUp = {
    hidden: {opacity: 0, y: 40, x: 0},
    show: {
        transition: 1,
        opacity: 1,
        y: 0,
        x: 0,
    },
};
