export const recipeOfTheDayNormalize = async ({data}) => {
    const normalizedMap = {
        id: data.id,
        image: data.thumbnail,
        headline: data.title,
        minutes: data.total_time,
        difficulty: data.difficulty_level,
        kcal: data.calories,
    };
    return normalizedMap;
};
