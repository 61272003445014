import {useParams} from '@reach/router';
import PageLayout from 'components/Layout/PageLayout';
import RecipeDetail from 'components/Templates/RecipeDetail';
import React from 'react';
import {useRecipeDetail} from 'services/data-hooks/recipes/useRecipeDetail';
import {useRecipeOfTags} from 'services/data-hooks/recipes/useRecipeOfTags';

const RecipeDetailPage = () => {
    const params = useParams();
    const {data, isLoading} = useRecipeDetail(params?.recipeId);

    const tags = data?.tags;
    const {data: moreRecipes} = useRecipeOfTags(tags);

    if (isLoading) {
        return null;
    }

    return (
        <PageLayout bgWhite>
            <RecipeDetail
                headerImage={data.headerImage}
                recipeInfo={data.recipeInfo}
                nutrients={data.nutrients}
                ingredients={data.ingredients}
                expedients={data.expedients}
                tips={data.tips}
                recipeSteps={data.recipeSteps}
                moreRecipes={moreRecipes}
                recipeSortInfo={data.recipeSortInfo}
            />
        </PageLayout>
    );
};

export default RecipeDetailPage;
