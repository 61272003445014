import {useQuery} from 'react-query';

import {fetchRecipesOfCategory} from 'services/request/recipes-of-category';
import {RECIPE_OF_CATEGORY} from 'services/config/query-keys';
import {recipeListNormalize} from 'services/normalize/recipe-list';

const getRecipeOfCategory = async (categoryId, tagIds, page) => {
    const {data, meta} = await fetchRecipesOfCategory(categoryId, tagIds, page);
    const normalize = await recipeListNormalize(data, meta);
    return normalize;
};

export function useRecipeOfCategory(categoryId, tagIds, page) {
    return useQuery([RECIPE_OF_CATEGORY, categoryId, tagIds, page], () =>
        getRecipeOfCategory(categoryId, tagIds, page)
    );
}
