import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';

import {fetchFavorites} from 'services/request/favorites';
import {FAVORITES} from 'services/config/query-keys';

const FavContext = createContext();

export const FavProvider = ({children}) => {
    const [favorites, setFavorites] = useState(null);

    useQuery(FAVORITES, async () => {
        const {data} = await fetchFavorites();
        const normalizedMap = data.map((item) => item.id);
        setFavorites(normalizedMap || []);
    });

    const push = (recipeId) => {
        const newFavorites = [...favorites, recipeId];
        setFavorites(newFavorites);
    };
    const some = (recipeId) => favorites?.some((n) => n === recipeId);

    const remove = (recipeId) => {
        const filtered = favorites?.filter((x) => x !== recipeId);
        setFavorites(filtered);
    };

    const clear = () => {
        setFavorites([]);
    };

    return (
        <FavContext.Provider
            value={{
                push,
                remove,
                some,
                clear,
                favorites,
                setFavorites,
            }}
            displayName="Favorite"
        >
            {children}
        </FavContext.Provider>
    );
};

FavProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useFavoriteContext = () => {
    return useContext(FavContext);
};
