import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';

import {fetchShoppingList} from 'services/request/shopping-list';
import {SHOPPING_LIST} from 'services/config/query-keys';

const ShoppingListContext = createContext();

export const ShoppingListProvider = ({children}) => {
    const [shoppingList, setShoppingList] = useState(null);

    useQuery(SHOPPING_LIST, async () => {
        const {data} = await fetchShoppingList();
        const normalizedMap = data.map((item) => item.id);
        setShoppingList(normalizedMap || []);
    });

    const push = (recipeId) => {
        const newFavorites = [...shoppingList, recipeId];
        setShoppingList(newFavorites);
    };
    const some = (recipeId) => shoppingList?.some((n) => n === recipeId);

    const remove = (recipeId) => {
        const filtered = shoppingList?.filter((x) => x !== recipeId);
        setShoppingList(filtered);
    };

    const clear = () => {
        setShoppingList([]);
    };

    return (
        <ShoppingListContext.Provider
            value={{push, remove, some, clear, shoppingList, setShoppingList}}
            displayName="ShoppingList"
        >
            {children}
        </ShoppingListContext.Provider>
    );
};

ShoppingListProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useShoppingLisContext = () => {
    return useContext(ShoppingListContext);
};
