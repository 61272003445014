import styled from 'styled-components';

import Div from 'shared-ui/components/Layout/Div';
import Tips from 'shared-ui/components/Elements/Tips';

import {queries} from 'shared-ui/styles/variables';
import {Container} from 'shared-ui/components/Layout/Grid';

export const StyledHeaderWrapper = styled.div`
    position: relative;
    width: 100%;

    @media ${queries.lg} {
        width: 90%;
        margin-bottom: 8rem;
    } ;
`;

export const StyledContainer = styled(Container)`
    padding: 2rem 1rem;
`;

export const StyledTips = styled(Tips)`
    height: 100%;
`;

export const StyledDiv = styled(Div)`
    padding-bottom: 120px;
    @media ${queries.lgMax} {
        padding-top: 16px;
    }
`;

export const Wrapper = styled(Div)`
    padding-bottom: 30px;
    @media ${queries.lgMax} {
        padding-top: 0px;
    }
`;
export const StyledFlex = styled(Div)`
    padding-top: 50px;
    @media ${queries.lg} {
        padding: 50px 0;
    }
`;
export const CardWrapper = styled(Div)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;