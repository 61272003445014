import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import {Container, Row, Column} from 'shared-ui/components/Layout/Grid';
import Flex from 'shared-ui/components/Layout/Flex';
import Div from 'shared-ui/components/Layout/Div';
import Button from 'shared-ui/components/Elements/Button';
import Icon from 'shared-ui/components/Elements/Icon';
import Headline from 'shared-ui/components/Elements/Headline';
import Lightbox from 'shared-ui/components/Elements/Lightbox';

import HeaderImage from 'components/Layout/HeaderImage';
import RecipeCollapseCard from 'components/Layout/RecipeCollapseCard';
import IngretidenList from 'components/Layout/IngretidentList';
import ExportCard from 'components/Layout/ExportCard';
import OwnedIngredients from 'components/Layout/OwnedIngredients';

import placeHolderHeaderImage from 'assets/images/shopping-list-header-image.jpeg';

import {lang} from 'shared-ui/lang/global.lang';
import {routes} from 'shared-utils/utils/routes';

import {useRemoveShopping} from 'services/data-hooks/shoppingList/useRemoveShopping';
import {useExportShoppingList} from 'services/data-hooks/shoppingList/useExportShoppingList';

import {StyledHeaderWrapper, GoBackReciepButton} from './styles';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';

const ShoppingList = ({recipes, ingredients, isIngredientsLoading}) => {
    const [isOpen, setIsOpen] = useState(false);

    const {mutate: deleteShoppingList} = useRemoveShopping();
    const {mutate: exportShoppingList} = useExportShoppingList();

    const handleDelete = (recipeId) => {
        deleteShoppingList(recipeId);
    };

    const handleExportShoppingList = (params) => {
        exportShoppingList(null, {
            onSuccess: () => {
                dataLayerPush({
                    event: 'biib_downloads',
                    eventAsset: 'export_shopping_list',
                    eventAssetBis: '/',
                });
            },
        });
    };

    return (
        <>
            <Div>
                <StyledHeaderWrapper>
                    <HeaderImage
                        image={placeHolderHeaderImage}
                        title={lang.yourShoppingList}
                        theme="small"
                    />
                </StyledHeaderWrapper>
            </Div>
            <Container>
                <Div marginTop="50px" paddingBottom="50px">
                    {recipes.length > 0 ? (
                        <Row>
                            <Column
                                cols={{md: 8, lg: 6}}
                                offset={{md: 2, lg: 3}}
                            >
                                {!isIngredientsLoading && (
                                    <OwnedIngredients items={ingredients} />
                                )}
                            </Column>
                            <Column
                                cols={{md: 8, lg: 6}}
                                offset={{md: 2, lg: 3}}
                            >
                                <Flex
                                    justifyContent="center"
                                    marginBottom="3rem"
                                    marginTop="3rem"
                                >
                                    <Button
                                        onClick={() => setIsOpen(true)}
                                        label={lang.generateShoppingList}
                                    />
                                </Flex>
                            </Column>
                            {recipes.map((item, index) => (
                                <Column
                                    key={index}
                                    cols={{lg: 8}}
                                    offset={{lg: 2}}
                                >
                                    <RecipeCollapseCard
                                        handleDelete={() =>
                                            handleDelete(item.id)
                                        }
                                        id={item.id}
                                        image={item.image}
                                        title={item.title}
                                        kcal={item.kcal}
                                        ingredientsNumber={
                                            item.ingredients.length
                                        }
                                    >
                                        <Div marginTop="1.5rem">
                                            <Row>
                                                <Column
                                                    cols={{lg: 8}}
                                                    offset={{lg: 2}}
                                                >
                                                    <IngretidenList
                                                        ingredients={
                                                            item.ingredients
                                                        }
                                                    />
                                                </Column>
                                            </Row>
                                        </Div>
                                    </RecipeCollapseCard>
                                </Column>
                            ))}
                        </Row>
                    ) : (
                        <Row>
                            <Column
                                cols={{md: 6, lg: 4}}
                                offset={{md: 3, lg: 4}}
                            >
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginTop="10px"
                                    marginBottom="100px"
                                >
                                    <Icon size="130px" name="favoriteEmpty" />
                                    <Headline>
                                        {lang.yourShoppingListEmpty}
                                    </Headline>
                                    <GoBackReciepButton
                                        label={lang.toTheRecipes}
                                        onClick={() =>
                                            navigate(`${routes.categories}`)
                                        }
                                    />
                                </Flex>
                            </Column>
                        </Row>
                    )}
                </Div>
            </Container>
            <Lightbox
                isOpen={isOpen}
                isOrangeBg
                onClose={() => setIsOpen(false)}
            >
                <ExportCard onClick={handleExportShoppingList} />
            </Lightbox>
        </>
    );
};

ShoppingList.propTypes = {
    recipes: PropTypes.array.isRequired,
    ingredients: PropTypes.array,
    isIngredientsLoading: PropTypes.bool,
};

ShoppingList.defaultProps = {
    isIngredientsLoading: true,
    ingredients: [],
};

export default ShoppingList;
