import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {Item, ItemTitle, TimeIcon, FlexWrapper, ItemWrapper} from './styles';
import Paragraph from '../Paragraph';
import {color, queries} from '../../../styles/variables';
import Div from '../../Layout/Div';

import {lang} from '../../../lang/global.lang';

const Time = ({workingTime, cookingTime}) => {
    const isSmMax = useMediaQuery(queries.smMax);
    const totalTime = workingTime + cookingTime;

    return (
        <ItemWrapper alignItems="center" justifyContent="center">
            <Item alignItems="center" justifyContent="center">
                <Div>
                    <ItemTitle theme="grey">{lang.workingTime}</ItemTitle>
                    <FlexWrapper alignItems="center">
                        <TimeIcon
                            name="clock"
                            color={color.red}
                            size="1.3rem"
                        />
                        <Paragraph
                            size={isSmMax ? 'xxsmall' : 'small'}
                            theme="black"
                        >
                            {workingTime} {lang.minute}
                        </Paragraph>
                    </FlexWrapper>
                </Div>
            </Item>
            <Item alignItems="center" justifyContent="center">
                <Div>
                    <ItemTitle theme="grey">{lang.cookingTime}</ItemTitle>
                    <FlexWrapper alignItems="center">
                        <TimeIcon
                            name="clock"
                            color={color.red}
                            size="1.3rem"
                        />
                        <Paragraph
                            size={isSmMax ? 'xxsmall' : 'small'}
                            theme="black"
                        >
                            {cookingTime} {lang.minute}
                        </Paragraph>
                    </FlexWrapper>
                </Div>
            </Item>
            <Item alignItems="center" justifyContent="center">
                <Div>
                    <ItemTitle theme="grey">{lang.totalTime}</ItemTitle>
                    <FlexWrapper alignItems="center">
                        <TimeIcon
                            name="clock"
                            color={color.red}
                            size="1.3rem"
                        />
                        <Paragraph
                            size={isSmMax ? 'xxsmall' : 'small'}
                            theme="black"
                        >
                            {totalTime} {lang.minute}
                        </Paragraph>
                    </FlexWrapper>
                </Div>
            </Item>
        </ItemWrapper>
    );
};

Time.propTypes = {
    workingTime: PropTypes.number.isRequired,
    cookingTime: PropTypes.number.isRequired,
};

export default Time;
