export const circlePointCreator = async (ref, arrayLength) => {
    let coordinates = [];
    const radius = ref.current.scrollHeight / 2;

    for (let i = 1; i <= arrayLength; i++) {
        const mathCos = Math.cos((2 * Math.PI * i) / arrayLength);
        const mathSin = Math.sin((2 * Math.PI * i) / arrayLength);

        const x = radius + radius * mathCos;
        const y = radius + radius * mathSin;

        coordinates.push({x, y});
    }
    return coordinates;
};
