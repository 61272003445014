import PropTypes from 'prop-types';
import React from 'react';

import {StyledSelect} from './styles';

export default function Select({options, onChange, defaultValue, value}) {
    return (
        <StyledSelect
            options={options}
            classNamePrefix="app__select"
            onChange={onChange}
            defaultValue={defaultValue}
            closeMenuOnSelect={false}
            value={value}
        />
    );
}

Select.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
};

Select.defaultProps = {
    onChange: () => {},
    defaultValue: null,
    value: null,
};
