import React from 'react';
import PropTypes from 'prop-types';

import {
    VIEWBOX_CENTER_X,
    VIEWBOX_CENTER_Y,
    VIEWBOX_HEIGHT_HALF,
} from './constants';

import {CircularProgressbarPath, CircularProgressbarTrail} from './styles';

const options = {
    path: CircularProgressbarPath,
    trail: CircularProgressbarTrail,
};

const minValue = 0;
const strokeWidth = 15;
const circleRatio = 1;

const Path = ({value, maxValue, pathOption}) => {
    const ProgressPath = options[pathOption];

    function getPathRadius() {
        return VIEWBOX_HEIGHT_HALF - strokeWidth / 2 - 10;
    }

    function getPathRatio() {
        const boundedValue = Math.min(Math.max(value, minValue), maxValue);
        return (boundedValue - minValue) / (maxValue - minValue);
    }

    const pathRadius = getPathRadius();
    const pathRatio = getPathRatio();
    const dashRatio =
        pathOption === 'path' ? pathRatio * circleRatio : circleRatio;

    const getPathDescription = ({pathRadius}) => {
        return `
            M ${VIEWBOX_CENTER_X},${VIEWBOX_CENTER_Y}
            m 0,-${pathRadius}
            a ${pathRadius},${pathRadius} 0 1 1 0,${2 * pathRadius}
            a ${pathRadius},${pathRadius} 0 1 1 0,-${2 * pathRadius}
          `;
    };

    const getDashStyle = ({pathRadius, dashRatio}) => {
        const diameter = Math.PI * 2 * pathRadius;
        const gapLength = (1 - dashRatio) * diameter;
        return {
            strokeDasharray: `${diameter}px ${diameter}px`,
            strokeDashoffset: `${gapLength}px`,
        };
    };

    return (
        <ProgressPath
            style={Object.assign(getDashStyle({pathRadius, dashRatio}))}
            d={getPathDescription({
                pathRadius,
            })}
            strokeWidth={strokeWidth}
            fillOpacity={0}
        />
    );
};

Path.propTypes = {
    value: PropTypes.number.isRequired,
    maxValue: PropTypes.number,
    pathOption: PropTypes.string.isRequired,
};

Path.defaultProps = {
    maxValue: 10,
};

export default Path;
