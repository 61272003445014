import {useMutation, useQueryClient} from 'react-query';

import {useShoppingLisContext} from 'context/ShoppingListContext';
import {removeShoppingList} from 'services/request/remove-shopping-list';
import {SHOPPING_RECIPE_LIST} from 'services/config/query-keys';

export const getRemoveShopping = async (recipeId) => {
    const response = removeShoppingList(recipeId);
    return response || false;
};

export const useRemoveShopping = (id) => {
    const cache = new useQueryClient();
    const {remove} = useShoppingLisContext();
    const mutate = useMutation((recipeId) => getRemoveShopping(recipeId), {
        onSuccess: (_, recipeId) => {
            remove(recipeId);
            cache.refetchQueries(SHOPPING_RECIPE_LIST);
        },
    });
    return mutate;
};
