export const CATEGORIES = 'CATEGORIES';
export const CATEGORY = 'CATEGORY';
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';
export const RECIPE_OF_DAYS = 'RECIPE_OF_DAYS';
export const RECIPE_OF_CATEGORY = 'RECIPE_OF_CATEGORY';
export const RECIPE_OF_INGREDIENTS = 'RECIPE_OF_INGREDIENTS';
export const RECIPE_DETAIL = 'RECIPE_DETAIL';
export const TAGS = 'TAGS';
export const INGREDIENTS = 'INGREDIENTS';
export const INGREDIENTS_BASIC = 'INGREDIENTS_BASIC';
export const FAVORITES = 'FAVORITES';
export const FAVORITE_RECIPES = 'FAVORITE_RECIPES';
export const SHOPPING_LIST = 'SHOPPING_LIST';
export const SHOPPING_RECIPE_LIST = 'SHOPPING_RECIPE_LIST';
export const NUTRITION_PLAN = 'NUTRITION_PLAN';
export const GAME_LEVELS = 'GAME_LEVELS';
export const USER = 'USER';
export const USER_LOGIN = 'USER_LOGIN';
