import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';

import {queries} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    min-height: 86px;
    @media ${queries.mdMax} {
        margin-bottom: 15px;
    }
`;

export const FinishIcon = styled(Icon)`
    margin-left: auto;
`;
