import PropTypes from 'prop-types';
import React from 'react';

import Popover from 'shared-ui/components/Elements/Popover';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {StyledCard} from './styles';

const Item = ({title, type, coordinate, popoverContent}) => {
    const typeLowerCase = type.toLowerCase();
    return (
        <StyledCard width="110px" left={coordinate.x} top={coordinate.y}>
            <Popover
                positions="bottom"
                align="center"
                content={popoverContent}
                width="300px"
            >
                {typeLowerCase === 'vitamin' && (
                    <Paragraph
                        size="xxxsmall"
                        autoLineHeight
                        textAlign="center"
                        noPadding
                    >
                        {type.replace(/^./, type[0].toUpperCase())}
                    </Paragraph>
                )}
                <Paragraph
                    size="xxxsmall"
                    autoLineHeight
                    textAlign="center"
                    noPadding
                >
                    {title}
                </Paragraph>
            </Popover>
        </StyledCard>
    );
};

Item.propTypes = {
    coordinate: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    popoverContent: PropTypes.any.isRequired,
};

export default Item;
