import styled from 'styled-components';

import Div from 'shared-ui/components/Layout/Div';
import Headline from 'shared-ui/components/Elements/Headline';
import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import backgroundImageMobile from 'assets/images/placeholder-game-mobile-background-image.jpg';
import backgroundImage from 'assets/images/placeholder-game-desktop-background-image.png';
import {color, queries} from 'shared-ui/styles/variables';

export const StyledDiv = styled(Div)`
    padding-bottom: 120px;
`;

export const BackgroundImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${backgroundImage});
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    @media ${queries.mdMax} {
        background-image: url(${backgroundImageMobile});
        background-size: cover;
    }
`;

export const Wrapper = styled(Div)`
    padding-bottom: 30px;
    @media ${queries.lgMax} {
        padding-top: 0px;
    }
`;

export const StyledWrapperDiv = styled(Div)`
    margin-top: 50px;
    width: 100%;
`;

export const StyledHeadline = styled(Headline)`
    margin-bottom: 0px;
    @media ${queries.sm} {
        margin-top: 50px;
    }
`;

export const StyledMobileCard = styled(Card)`
    background-color: ${color.white};
    margin-top: 150px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding-top: 50px;
    width: 100%;
    @media ${queries.sm} {
        margin-top: 250px;
    }
`;

export const StyledCard = styled(Card)`
    margin-bottom: 50px;
    @media ${queries.mdMax} {
        margin-bottom: 15px;
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 12px;
    margin-left: 5px;
`;

export const FinishIcon = styled(Icon)`
    margin-left: auto;
`;

export const ImageDiv = styled.div`
    width: 100%;
    height: 550px;
`;

export const CardsWrapper = styled.div`
    margin-top: 50px;
    padding-top: 120px;
    width: 100%;
    padding-bottom: 50px;
    @media ${queries.lgMax} {
        margin-top: 10px;
    }
    @media ${queries.mdMax} {
        background-color: ${color.white};
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: 40px;
        padding-top: 40px;
    }
`;
export const ContainerDiv = styled(Div)`
    margin: auto;
    padding-left: 1rem;
    max-width: 1200px;
    @media ${queries.lgMax} {
        padding-left: 0px;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    margin-bottom: 30px;
    display: inline-block;
    text-align: center;
    align-items: center;
`;
