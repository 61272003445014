import React from 'react';
import {navigate} from '@reach/router';

import {routes} from 'shared-utils/utils/routes';
import Icon from 'shared-ui/components/Elements/Icon';
import {color} from 'shared-ui/styles/variables';

import {StyledPlusCard} from './styles';

const PlusCard = () => {
    return (
        <StyledPlusCard
            onClick={() => navigate(routes.categories)}
            justifyContent="center"
            alignItems="center"
        >
            <Icon name="plus" size="16px" color={color.lightGrey} />
        </StyledPlusCard>
    );
};

export default PlusCard;
