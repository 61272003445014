import styled from 'styled-components';

import Icon from '../Icon';
import Paragraph from '../Paragraph';

import Flex from '../../Layout/Flex';

import {queries, color} from '../../../styles/variables';

export const Item = styled(Flex)`
    height: 40px;
    @media ${queries.smMax} {
        height: 30px;
    }
`;

export const ItemWrapper = styled(Flex)`
    width: 100%;
    background-color: ${color.bgGrey};
    border-radius: 10px;
    padding: 30px 0;
    ${Item}:nth-child(2) {
        border-right: 1px solid ${color.darkBlue35};
        border-left: 1px solid ${color.darkBlue35};
        padding: 0 20px;
        margin: 0 20px;
    }
    @media ${queries.smMax} {
        padding: 20px 0;
        ${Item}:nth-child(2) {
            border-right: 1px solid ${color.grey};
            border-left: 1px solid ${color.grey};
            padding: 0 10px;
            margin: 0 10px;
        }
    }
`;

export const ItemTitle = styled(Paragraph)`
    padding: 0;
    font-size: 20px;
    @media ${queries.smMax} {
        font-size: 13px;
    }
`;

export const TimeIcon = styled(Icon)`
    margin-right: 16px;
    @media ${queries.mdMax} {
        margin-right: 10px;
    }
`;

export const FlexWrapper = styled(Flex)`
    height: 35px;
    @media ${queries.smMax} {
        height: 25px;
    }
`;
