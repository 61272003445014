import {useQuery} from 'react-query';

import {fetchTags} from 'services/request/tags';
import {TAGS} from 'services/config/query-keys';
import {tagsNormalize} from 'services/normalize/tags';

const getTags = async () => {
    const data = await fetchTags();
    const normalize = tagsNormalize(data);
    return normalize;
};

export function useTags() {
    return useQuery(TAGS, getTags);
}
