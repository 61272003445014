import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'shared-ui/components/Layout/Flex';

import IngretidentItem from './IngretidentItem';

const IngretidenList = ({ingredients}) => {
    return (
        <Flex flexDirection="column">
            {ingredients.map((item) => (
                <IngretidentItem
                    key={item.id}
                    amount={item.amount}
                    carbs={item.carbs}
                    fat={item.fat}
                    id={item.id}
                    kcal={item.kcal}
                    prefix={item.prefix}
                    protein={item.protein}
                    suffix={item.suffix}
                    title={item.title}
                    unit={item.unit}
                    inGlossary={item.inGlossary}
                />
            ))}
        </Flex>
    );
};

IngretidenList.propTypes = {
    ingredients: PropTypes.array.isRequired,
};

export default IngretidenList;
