import {
    StyledCard,
    AnchorMenuWrapper,
    MoreRecipeWrapper,
    StyledDiv,
    StyledHeaderWrapper,
} from './styles';
import {useParams} from '@reach/router';
import AnchorLeftMenu from 'components/Layout/AnchorLeftMenu';
import Expedient from 'components/Layout/Expedient';
import HeaderImage from 'components/Layout/HeaderImage';
import IngretidentList from 'components/Layout/IngretidentList';
import Nutrients from 'components/Layout/Nutrients';
import RecipeCard from 'components/Layout/Recipe';
import RecipeButton from 'components/Layout/RecipeButton';
import RecipeInfo from 'components/Layout/RecipeInfo';
import RecipeSteps from 'components/Layout/RecipeSteps';
import {ConnectedRecipeWeeklyPlanCheckBoxes} from 'components/Layout/RecipeWeeklyPlanCheckBoxes';
import Tooltip from 'components/Layout/Tooltip';
import {useFavoriteContext} from 'context/FavContext';
import {useNutritionPlanContext} from 'context/NutritionPlanContext';
import {useShoppingLisContext} from 'context/ShoppingListContext';
import PropTypes from 'prop-types';
import React, {useState, useMemo, useEffect} from 'react';
import {InView} from 'react-intersection-observer';
import {useAddFavorite} from 'services/data-hooks/favorites/useAddFavorite';
import {useRemoveFavorite} from 'services/data-hooks/favorites/useRemoveFavorite';
import {useAddShopping} from 'services/data-hooks/shoppingList/useAddShopping';
import {useRemoveShopping} from 'services/data-hooks/shoppingList/useRemoveShopping';
import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Lightbox from 'shared-ui/components/Elements/Lightbox';
import Tips from 'shared-ui/components/Elements/Tips';
import Div from 'shared-ui/components/Layout/Div';
import {Row, Container, Column} from 'shared-ui/components/Layout/Grid';
import {lang} from 'shared-ui/lang/global.lang';
import {queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';

const RecipeDetail = ({
    headerImage,
    recipeInfo,
    moreRecipes,
    nutrients,
    ingredients,
    expedients,
    tips,
    recipeSteps,
    recipeSortInfo,
}) => {
    const isLg = useMediaQuery(queries.lg);
    const isLgMax = useMediaQuery(queries.lgMax);
    const [isIntroOut, setIsIntroOut] = useState(false);
    // const [isOutro, setIsOutro] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const params = useParams();

    const parseRecipeId = parseInt(params.recipeId);

    const {some: isFavoriteList} = useFavoriteContext();
    const {mutate: addFavorite} = useAddFavorite();
    const {mutate: removeFavorite} = useRemoveFavorite();

    const {some: isShoppingList} = useShoppingLisContext();
    const {mutate: addShopping} = useAddShopping();
    const {mutate: removeShopping} = useRemoveShopping();

    const {handleCurrentYear} = useNutritionPlanContext();

    const isFavorite = isFavoriteList(parseRecipeId);
    const isShopping = isShoppingList(parseRecipeId);

    const filteredMoreRecipes = useMemo(
        () => moreRecipes?.filter((value) => value.id !== parseRecipeId),
        [moreRecipes]
    );
    useEffect(() => {
        handleCurrentYear();
    }, []);

    const handleCalendarOnclick = () => {
        setIsOpen(true);
    };

    const handleFavoriteIcon = () => {
        if (isFavorite) {
            removeFavorite(parseRecipeId);
        } else {
            addFavorite(parseRecipeId, {
                onSuccess: () => {
                    dataLayerPush({
                        event: 'biib_internalLinkClick',
                        eventAsset: 'add_to_fav',
                        eventAssetBis: 'recipe',
                    });
                },
            });
        }
    };
    const handleShoppingIcon = () => {
        if (isShopping) {
            removeShopping(parseRecipeId);
        } else {
            addShopping(parseRecipeId, {
                onSuccess: () => {
                    dataLayerPush({
                        event: 'biib_internalLinkClick',
                        eventAsset: 'add_to_shopping_list',
                        eventAssetBis: 'recipe',
                    });
                },
            });
        }
    };

    return (
        <>
            <Div>
                <StyledHeaderWrapper>
                    <InView
                        as="div"
                        id="intro"
                        rootMargin="100px"
                        onChange={(inView) => setIsIntroOut(!inView)}
                    >
                        <HeaderImage
                            title={headerImage.title}
                            preTitle={headerImage.preTitle}
                            image={headerImage.image}
                            tags={headerImage.tags}
                        />
                    </InView>
                </StyledHeaderWrapper>
            </Div>

            <Div marginTop="50px" marginBottom="5rem" paddingBottom="5rem">
                <Container>
                    <Row>
                        <Column cols={{lg: 2}}>
                            {isLg && (
                                <>
                                    <AnchorMenuWrapper
                                        flexDirection="column"
                                        isFixed={isIntroOut}
                                    >
                                        <Tooltip
                                            theme="recipeDetail"
                                            tipIndex={5}
                                        />
                                        <AnchorLeftMenu
                                            menuItems={
                                                lang.recipeDetailPageMenuItems
                                            }
                                            offset={-100}
                                        />
                                        <StyledDiv marginTop="50px">
                                            <RecipeButton
                                                isFavorite={isFavorite}
                                                isShopping={isShopping}
                                                handleFavoriteIcon={
                                                    handleFavoriteIcon
                                                }
                                                handleShoppingIcon={
                                                    handleShoppingIcon
                                                }
                                                handleCalendarOnclick={
                                                    handleCalendarOnclick
                                                }
                                            />
                                        </StyledDiv>
                                    </AnchorMenuWrapper>
                                </>
                            )}
                        </Column>
                        <Column cols={{lg: 6}}>
                            <Div marginBottom="32px">
                                <RecipeInfo
                                    level={recipeInfo.level}
                                    cookingTime={recipeInfo.cookingTime}
                                    calories={recipeInfo.calories}
                                    servings={recipeInfo.servings}
                                    description={recipeInfo.description}
                                />
                            </Div>
                            <Div id="nahrwerte" marginTop="32px">
                                <Headline type="h5">
                                    {lang.nutrientsTitle}
                                </Headline>
                                <Nutrients
                                    theme="secondary"
                                    kcal={nutrients.kcal}
                                    protein={nutrients.protein}
                                    fat={nutrients.fat}
                                    carbon={nutrients.carbon}
                                />
                            </Div>
                            <Div id="zutaten" marginTop="32px">
                                <Card theme="secondary">
                                    <Headline type="h5">
                                        {lang.ingredients}
                                    </Headline>
                                    <IngretidentList
                                        ingredients={ingredients}
                                    />
                                </Card>
                            </Div>
                            <Div marginTop="32px">
                                <Expedient expedients={expedients} />
                            </Div>
                            <Div marginTop="80px">
                                <Tips headline={lang.tipsTricks} text={tips} />
                            </Div>
                            <Div id="zubereitung" marginTop="80px">
                                <Card>
                                    <RecipeSteps
                                        steps={recipeSteps.steps}
                                        infoText={recipeSteps.infoText}
                                        workingTime={recipeSteps.workingTime}
                                        cookingTime={recipeSteps.cookingTime}
                                    />
                                </Card>
                            </Div>
                            {isLgMax && (
                                <>
                                    <Tooltip
                                        theme="recipeDetail"
                                        tipIndex={5}
                                    />
                                    <RecipeButton
                                        isFixed={true}
                                        // isFixed={isIntroOut && !isOutro}
                                        isFavorite={isFavorite}
                                        isShopping={isShopping}
                                        handleFavoriteIcon={handleFavoriteIcon}
                                        handleShoppingIcon={handleShoppingIcon}
                                        handleCalendarOnclick={
                                            handleCalendarOnclick
                                        }
                                    />
                                </>
                            )}
                        </Column>
                        <Column cols={{lg: 4}}>
                            <MoreRecipeWrapper>
                                <Div marginLeft="10px">
                                    {filteredMoreRecipes?.length > 0 && (
                                        <Headline>{lang.moreRecipes}</Headline>
                                    )}
                                </Div>
                                <Row>
                                    {filteredMoreRecipes?.map((item, index) => (
                                        <Column
                                            key={index}
                                            cols={{sm: 6, md: 6, lg: 12}}
                                        >
                                            <StyledCard isHoverBg>
                                                <RecipeCard
                                                    id={item.id}
                                                    image={item.image}
                                                    headline={item.title}
                                                    minutes={item.minutes}
                                                    difficulty={item.difficulty}
                                                    kcal={item.kcal}
                                                />
                                            </StyledCard>
                                        </Column>
                                    ))}
                                </Row>
                            </MoreRecipeWrapper>
                            {/* <InView
                                as="div"
                                id="outro"
                                rootMargin="100px"
                                onChange={(inView) => setIsOutro(inView)}
                            ></InView> */}
                        </Column>
                    </Row>
                </Container>
            </Div>
            <Lightbox isOpen={isOpen} isOrangeBg onClose={() => setIsOpen()}>
                <ConnectedRecipeWeeklyPlanCheckBoxes
                    isOpen={isOpen}
                    selectedRecipe={recipeSortInfo}
                    clickPosition="recipe"
                />
            </Lightbox>
        </>
    );
};

RecipeDetail.propTypes = {
    headerImage: PropTypes.shape({
        title: PropTypes.string,
        preTitle: PropTypes.string,
        tags: PropTypes.array,
        image: PropTypes.any,
    }).isRequired,
    recipeInfo: PropTypes.object.isRequired,
    moreRecipes: PropTypes.array,
    ingredients: PropTypes.array.isRequired,
    expedients: PropTypes.array.isRequired,
    nutrients: PropTypes.object.isRequired,
    tips: PropTypes.string.isRequired,
    recipeSteps: PropTypes.object.isRequired,
    recipeSortInfo: PropTypes.object.isRequired,
};

RecipeDetail.defaultProps = {
    moreRecipes: [],
    weeklyPlan: {},
};

export default RecipeDetail;
