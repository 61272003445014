import styled from 'styled-components';

import {color, font} from '../../../styles/variables';

export const StyledList = styled.div``;

export const ListElement = styled.div`
    display: flex;
    padding: 1rem 1rem 0 0;
`;

export const Indicator = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 15px;
    background-color: ${color.lightRed};
    font-size: ${font.size.medium};
    font-family: ${font.type.mulishBold};
    font-weight: bold;
    color: ${color.red};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IndicatorWrapper = styled.div`
    padding: 0.5rem 2rem 0.5rem 0;
`;

export const TextWrapper = styled.div`
    padding-bottom: 1rem;
    border-bottom: ${(props) =>
        props.border ? `2px solid ${color.lightGrey}` : 0};
`;
