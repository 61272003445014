import styled from 'styled-components';

import Headline from 'shared-ui/components/Elements/Headline';
import Flex from 'shared-ui/components/Layout/Flex';

import {color, queries, font} from 'shared-ui/styles/variables';

export const StyledFlex = styled(Flex)`
    border-bottom: 1px solid ${color.grey};
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

export const StyledHeadline = styled(Headline)`
    margin-bottom: 0;
    font-weight: bolder;
    line-height: 20px;
`;

export const Title = styled.span`
    color: ${color.grey};
`;
export const Value = styled.span`
    color: ${color.darkBlue};
    font-size: 16px;
    margin-top: 3px;
`;

export const StyledItemFlex = styled(Flex)`
    margin: 0 15px;
    font-family: ${font.type.mulishRegular};

    @media ${queries.xsMax} {
        margin: 0 6px;
    }
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
`;
export const StyledButtonFlex = styled(Flex)`
    margin: 0 15px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
`;
export const StyledIconsFlex = styled(Flex)`
    background: ${color.button.primary};
    border-radius: 14px;
    width: 100px;
    cursor: pointer;
    padding: 15px;
`;
