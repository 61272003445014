import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Div from 'shared-ui/components/Layout/Div';

import backgroundImage from 'assets/images/placeholder-navigation-field.jpg';

import {color, queries, orangeShadow} from 'shared-ui/styles/variables';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const ImageDiv = styled.div`
    position: relative;
    width: 100%;
    height: 450px;
    margin: 0 auto;

    @media ${queries.mdMax} {
        height: 300px;
    }

    @media ${queries.smMax} {
        height: 200px;
    }

    @media ${queries.xsMax} {
        height: 160px;
    }
`;

export const CardWrapper = styled(Div)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const BackgroundImage = styled.div`
    height: 100%;
    position: absolute;
    width: 100%;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    &:before {
        width: 100%;
        height: 30%;
        position: relative;
        background: ${color.gradientButtonWhite};
        position: absolute;
        width: 100%;
        bottom: 0;
        content: '';
    }
`;

export const CardsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const NavigationCard = styled(Card)`
    height: 9rem;
    margin: 20px 10px;
    position: relative;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${color.white};
        box-shadow: ${orangeShadow};
    }

    @media ${queries.smMax} {
        margin: 12px 6px;
    }
`;
