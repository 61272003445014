import React from 'react';

import CategoryList from 'components/Templates/CategoryList';

import {useCategory} from 'services/data-hooks/categories/useCategoryList';

const Categories = () => {
    const {data: categories, isLoading} = useCategory();

    if (isLoading) {
        return null;
    }

    return <CategoryList categories={categories} />;
};

export default Categories;
