import React from 'react';

import Homepage from 'components/Templates/Homepage';

import {useRecipeOfDays} from 'services/data-hooks/recipes/useRecipeOfDays';
import {lang} from 'shared-ui/lang/global.lang';

export default function Home() {
    const {data: recipeOfDays, isLoading} = useRecipeOfDays();
    if (isLoading) {
        return null;
    }
    return <Homepage recipeOfDay={recipeOfDays} tipsText={lang.tipsText} />;
}
