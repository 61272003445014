import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Icon from 'shared-ui/components/Elements/Icon';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Select from 'shared-ui/components/Elements/Select';

import {color, queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {lang} from 'shared-ui/lang/global.lang';
import {weeksCreator} from 'utils/helpers/week-creator';
import {useNutritionPlanContext} from 'context/NutritionPlanContext';

import {
    NavWrapper,
    IconMotionWrapper,
    IconClosedMotionWrapper,
    CardHead,
    Line,
    CardBottom,
    SelectWrapper,
} from './styles';
import {
    navVariants,
    weeklyIconVariants,
    itemVariants,
    displayVariants,
    lineVariants,
} from './variants';

export default function PlannerNav({handleDuplicateOnClick}) {
    const isDesktop = useMediaQuery(queries.lg);
    const options = weeksCreator();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const {selectedWeek, setSelectedWeek} = useNutritionPlanContext();

    const handleOptionChange = (e) => {
        const findWeek = options.find((x) => x.value === e.value);
        setSelectedWeek(findWeek.value);
    };

    const handlePrev = () => {
        selectedWeek > 1 && setSelectedWeek(selectedWeek - 1);
    };
    const handleNext = () => {
        selectedWeek < options.length && setSelectedWeek(selectedWeek + 1);
    };

    return (
        <NavWrapper
            variants={{
                ...navVariants(isDesktop),
            }}
            animate={isNavOpen ? 'open' : 'closed'}
            onClick={() => !isNavOpen && setIsNavOpen(true)}
        >
            <IconMotionWrapper variants={weeklyIconVariants}>
                <Icon name="calendarSearch" color={color.white} size="40px" />
            </IconMotionWrapper>
            <CardHead variants={displayVariants}>
                <IconClosedMotionWrapper
                    variants={itemVariants}
                    onClick={handleDuplicateOnClick}
                >
                    <Icon name="duplicate" color={color.white} size="24px" />
                </IconClosedMotionWrapper>
                <IconClosedMotionWrapper
                    variants={itemVariants}
                    onClick={handleDuplicateOnClick}
                >
                    <Paragraph theme="white">{lang.plannerNav.title}</Paragraph>
                </IconClosedMotionWrapper>
                <IconClosedMotionWrapper
                    variants={itemVariants}
                    onClick={() => setIsNavOpen(false)}
                >
                    <Icon name="close" color={color.white} size="40px" />
                </IconClosedMotionWrapper>
            </CardHead>
            <Line variants={lineVariants} />
            <CardBottom variants={displayVariants}>
                <IconClosedMotionWrapper variants={itemVariants}>
                    <Icon
                        name="leftArrow"
                        color={color.white}
                        size="24px"
                        onClick={handlePrev}
                    />
                </IconClosedMotionWrapper>
                <SelectWrapper variants={itemVariants}>
                    <Select
                        options={options}
                        value={options[selectedWeek - 1]}
                        onChange={(e) => handleOptionChange(e)}
                    />
                </SelectWrapper>
                <IconClosedMotionWrapper variants={itemVariants}>
                    <Icon
                        name="rightArrow"
                        color={color.white}
                        size="24px"
                        onClick={handleNext}
                    />
                </IconClosedMotionWrapper>
            </CardBottom>
        </NavWrapper>
    );
}

PlannerNav.propTypes = {
    handleDuplicateOnClick: PropTypes.func,
};

PlannerNav.defaultProps = {
    handleDuplicateOnClick: () => {},
};
