import styled, {css} from 'styled-components';
import {ArrowContainer} from 'react-tiny-popover';

import {color, shadow, queries, cardRadius} from '../../../styles/variables';

export const StyledContent = styled.div`
    background-color: ${color.white};
    color: ${color.darkBlue};
    border-radius: ${cardRadius};
    display: inline-flex;
    box-shadow: ${shadow};
    box-sizing: border-box;
    padding: 16px;
    min-width: 100px;
    ${({width}) =>
        width &&
        css`
            width: ${width};
        `};

    position: relative;
`;

export const StyledArrowContainer = styled(ArrowContainer)`
    padding: 15px 0 !important;
    @media ${queries.sm} {
        ${({isOutLeft}) =>
            isOutLeft &&
            css`
                transform: translateX(30%);
                .popover-arrow {
                    left: 50px !important;
                    right: inherit !important;
                }
            `};
        ${({isOutRight}) =>
            isOutRight &&
            css`
                transform: translateX(-30%);
                .popover-arrow {
                    right: 50px !important;
                    left: inherit !important;
                }
            `};
    }
`;
