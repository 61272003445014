import {useMutation} from 'react-query';

import {makeRequest} from 'services/config/nutrition-api';
import {useFavoriteContext} from 'context/FavContext';

export const mutateAddFavorite = async (recipeId) => {
    const data = {
        ids: [recipeId],
    };
    await makeRequest(`/me/favorites`, 'post', data);
};

export const useAddFavorite = () => {
    const {push} = useFavoriteContext();
    const mutate = useMutation((recipeId) => mutateAddFavorite(recipeId), {
        onSuccess: (_, recipeId) => {
            push(recipeId);
        },
    });
    return mutate;
};
