import IconSalmon from 'assets/images/game/icon-game-salmon.png';
import IconEgg from 'assets/images/game/icon-game-egg.png';
import IconLeinol from 'assets/images/game/icon-game-leinol.png';
import IconWatercress from 'assets/images/game/icon-game-watercress.png';
import IconCashew from 'assets/images/game/icon-game-cashew.png';
import IconHoney from 'assets/images/game/icon-game-honey.png';
import IconOat from 'assets/images/game/icon-game-oat.png';
import IconApple from 'assets/images/game/icon-game-apple.png';
import IconOrange from 'assets/images/game/icon-game-orange.png';
import IconCranberry from 'assets/images/game/icon-game-cranberry.png';
import IconOliveOil from 'assets/images/game/icon-game-olive-oil.png';
import IconGinger from 'assets/images/game/icon-game-ginger.png';
import IconKurkuma from 'assets/images/game/icon-game-kurkuma.png';
import IconAlmond from 'assets/images/game/icon-game-almond.png';
import IconCocoaPowder from 'assets/images/game/icon-game-cocoa_powder.png';
import IconGarlic from 'assets/images/game/icon-game-garlic.png';
import IconQuinoa from 'assets/images/game/icon-game-quinoa.png';
import IconSweetPotato from 'assets/images/game/icon-game-sweet-potato.png';
import IconZucchini from 'assets/images/game/icon-game-zucchini.png';
import IconAvocado from 'assets/images/game/icon-game-avocado.png';
import IconTrout from 'assets/images/game/icon-game-trout.png';
import IconPetersilie from 'assets/images/game/icon-game-petersilie.png';
import IconWalnut from 'assets/images/game/icon-game-walnut.png';
import IconOnion from 'assets/images/game/icon-game-onion.png';
import IconWirsing from 'assets/images/game/icon-game-wirsing.png';
import IconTuna from 'assets/images/game/icon-game-tuna.png';
import IconThyme from 'assets/images/game/icon-game-thyme.png';
import IconRosmarin from 'assets/images/game/icon-game-rosmarin.png';
import IconHazelnut from 'assets/images/game/icon-game-hazelnut.png';
import IconLinsen from 'assets/images/game/icon-game-linsen.png';
import IconCauliflower from 'assets/images/game/icon-game-cauliflower.png';
import IconSellerie from 'assets/images/game/icon-game-sellerie.png';
import IconCabbage from 'assets/images/game/icon-game-cabbage.png';
import IconChicoree from 'assets/images/game/icon-game-chicoree.png';
import IconHokkaidoPumpkin from 'assets/images/game/icon-game-hokkaido_pumpkin.png';
import IconBeetroot from 'assets/images/game/icon-game-beetroot.png';
import IconStangensellerie from 'assets/images/game/icon-game-stangensellerie.png';
import IconAsparagus from 'assets/images/game/icon-game-asparagus.png';
import IconSpinach from 'assets/images/game/icon-game-spinach.png';
import IconFenchel from 'assets/images/game/icon-game-fenchel.png';
import IconSalmonFilet from 'assets/images/game/icon-game-salmon_filet.png';
import IconBreast from 'assets/images/game/icon-game-chicken-breast.png';
import IconRapsol from 'assets/images/game/icon-game-rapsol.png';
import IconOregano from 'assets/images/game/icon-game-oregano.png';
import IconMustard from 'assets/images/game/icon-game-mustard.png';
import IconCarrot from 'assets/images/game/icon-game-carrot.png';
import IconTomate from 'assets/images/game/icon-game-tomate.png';
import IconBroccoli from 'assets/images/game/icon-game-broccoli.png';
import IconStrawberry from 'assets/images/game/icon-game-strawberry.png';
import IconLemon from 'assets/images/game/icon-game-lemon.png';

import IconFridge from 'assets/images/game/icon-game-fridge.png';
import IconBowl from 'assets/images/game/icon-game-bowl.png';
import IconRegal from 'assets/images/game/icon-game-regal.png';

import IconSommerfest from 'assets/images/game/category/ico-sommerfest.png';
import IconAroundTheWorld from 'assets/images/game/category/ico-aus-aller-welt.png';
import IconKaminAbend from 'assets/images/game/category/ico-kaminabend.png';
import IconFestmahl from 'assets/images/game/category/ico-festmahl.png';
import IconBreakfast from 'assets/images/game/category/ico-breakfast.png';

export const levels = [
    {
        icon: IconSommerfest,
        desc: 'Pack die Sonnenstühle aus und plane dein nächstes Sommerfest! Doch damit nichts verdirbt, solltest du die Lebensmittel richtig lagern!',
        path: 'summer-festival',
        title: 'Sommerfest',
        level: 1,
        products: [
            {
                value: 'Seelachsfilet',
                image: IconSalmonFilet,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lege es an die kühlste Stelle im Kühlschrank.',
            },
            {
                value: 'Fettarmes Hähnchenfleisch',
                image: IconBreast,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Du kannst es maximal 2 Tage im Kühlschrank lagern. ',
            },
            {
                value: 'Rapsöl',
                image: IconRapsol,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Rapsöl muss kühl und dunkel gelagert werden.',
            },
            {
                value: 'Oregano (frisch)',
                image: IconOregano,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lege ihn feucht eingewickelt in einen Gefrierbeutel. ',
            },
            {
                value: 'Senf',
                image: IconMustard,
                type: 'regal',
                typeName: 'Regal',
                info: 'Verschlossenen Senf kühl, dunkel und luftdicht lagern.',
            },
            {
                value: 'Karotten',
                image: IconCarrot,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Karotten kühl und feucht eingewickelt lagern.',
            },
            {
                value: 'Tomaten',
                image: IconTomate,
                type: 'regal',
                typeName: 'Regal',
                info: 'Tomaten sollten bei Zimmertemperatur gelagert werden.',
            },
            {
                value: 'Brokkoli',
                image: IconBroccoli,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Brokkoli in Frischhaltefolie eingepackt kühl lagern. ',
            },
            {
                value: 'Erdbeeren',
                image: IconStrawberry,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lagere Erdbeeren ungewaschen, kühl und abgedeckt. ',
            },
            {
                value: 'Zitronen',
                image: IconLemon,
                type: 'bowl',
                typeName: 'Schale',
                info: 'Zitronen kühl, lichtgeschützt und luftig lagern.',
            },
        ],
    },
    {
        icon: IconAroundTheWorld,
        desc: 'Hier kannst du zeigen, wie gut du dich mit der Lagerung von Lebensmitteln aus fernen Ländern auskennst',
        path: 'around-the-world',
        title: 'Aus aller Welt',
        level: 2,
        products: [
            {
                value: 'Olivenöl',
                image: IconOliveOil,
                type: 'regal',
                typeName: 'Regal',
                info: 'Olivenöl bei Raumtemperatur dunkel lagern.',
            },
            {
                value: 'Ingwer (frisch)',
                image: IconGinger,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Olivenöl bei Raumtemperatur dunkel lagern.',
            },
            {
                value: 'Kurkuma (frisch)',
                image: IconKurkuma,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Kurkuma am besten im Gemüsefach lagern.',
            },
            {
                value: 'Mandeln',
                image: IconAlmond,
                type: 'regal',
                typeName: 'Regal',
                info: 'Mandeln kühl, dunkel und trocken lagern. ',
            },
            {
                value: 'Dunkles Kakaopulver',
                image: IconCocoaPowder,
                type: 'regal',
                typeName: 'Regal',
                info: 'Kakaopulver dunkel, trocken und luftdicht lagern.',
            },
            {
                value: 'Knoblauch',
                image: IconGarlic,
                type: 'regal',
                typeName: 'Regal',
                info: 'Knoblauch kühl, dunkel, luftig und trocken lagern.',
            },
            {
                value: 'Quinoa',
                image: IconQuinoa,
                type: 'regal',
                typeName: 'Regal',
                info: 'Quinoa dunkel, kühl und trocken lagern.',
            },
            {
                value: 'Süßkartoffel',
                image: IconSweetPotato,
                type: 'regal',
                typeName: 'Regal',
                info: 'Sie mag es kühl, trocken und lichtgeschützt.',
            },
            {
                value: 'Zucchini',
                image: IconZucchini,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lagere Zucchini kühl und dunkel.',
            },
            {
                value: 'Avocado',
                image: IconAvocado,
                type: 'bowl',
                typeName: 'Schale',
                info: 'Die unreife Avocado bei Zimmertemperatur lagern.',
            },
        ],
    },
    {
        icon: IconKaminAbend,
        desc: 'Diese Lebensmittel passen perfekt zu einem gemütlichen Winterabend vor dem Kamin.',
        path: 'fireplace-evening',
        title: 'Kaminabend',
        level: 3,
        products: [
            {
                value: 'Thunfischsteak',
                image: IconTuna,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Stelle den frischen Fisch an die kühlste Stelle. ',
            },
            {
                value: 'Thymian (frisch)',
                image: IconThyme,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Den frischen Thymian vorher feucht einwickeln. ',
            },
            {
                value: 'Rosmarin (frisch)',
                image: IconRosmarin,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Schlage den Rosmarin vorher in ein feuchtes Tuch ein. ',
            },
            {
                value: 'Haselnüsse',
                image: IconHazelnut,
                type: 'regal',
                typeName: 'Regal',
                info: 'Lagere Haselnüsse mit Schale kühl und trocken.',
            },
            {
                value: 'Linsen',
                image: IconLinsen,
                type: 'regal',
                typeName: 'Regal',
                info: 'Linsen trocken und verschlossen aufbewahren.',
            },
            {
                value: 'Blumenkohl',
                image: IconCauliflower,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Blumenkohl in Folie gewickelt im Gemüsefach lagern. ',
            },
            {
                value: 'Sellerie',
                image: IconSellerie,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Sellerie feucht eingewickelt im Kühlschrank lagern. ',
            },
            {
                value: 'Grünkohl',
                image: IconCabbage,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Grünkohl feucht eingewickelt im Gemüsefach aufbewahren.',
            },
            {
                value: 'Chicorée',
                image: IconChicoree,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Den Chicorée am besten dunkel und feucht lagern. ',
            },
            {
                value: 'Hokkaido',
                image: IconHokkaidoPumpkin,
                type: 'regal',
                typeName: 'Regal',
                info: 'Der Hokkaido mag es am liebsten kühl und dunkel.',
            },
        ],
    },
    {
        icon: IconFestmahl,
        desc: 'Diese Köstlichkeiten sollten bei einem schicken Dinner nicht fehlen. Und wo werden sie gelagert?',
        path: 'feast',
        title: 'Festmahl',
        level: 4,
        products: [
            {
                value: 'Forelle',
                image: IconTrout,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Die frische Forelle an der kühlsten Stelle lagern. ',
            },
            {
                value: 'Petersilie',
                image: IconPetersilie,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Petersilie feucht eingewickelt kühl lagern.',
            },
            {
                value: 'Walnüsse',
                image: IconWalnut,
                type: 'regal',
                typeName: 'Regal',
                info: 'Walnüsse mit Schale kühl und trocken lagern.',
            },
            {
                value: 'Zwiebel',
                image: IconOnion,
                type: 'regal',
                typeName: 'Regal',
                info: 'Lagere Zwiebeln am besten dunkel und trocken.',
            },
            {
                value: 'Wirsing',
                image: IconWirsing,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Wirsing im Kühlschrank oder Keller lagern. ',
            },
            {
                value: 'Rote Bete (frisch)',
                image: IconBeetroot,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Rote Bete im Kühlschrank feucht eingewickelt lagern. ',
            },
            {
                value: 'Stangensellerie',
                image: IconStangensellerie,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lagere den Stangesellerie luftdicht im Gemüsefach. ',
            },
            {
                value: 'Spargel',
                image: IconAsparagus,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Ungeschälten Spargel feucht eingewickelt kühl lagern. ',
            },
            {
                value: 'Spinat',
                image: IconSpinach,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Richtig, Spinat im Gemüsefach für max. 2 Tage lagern.',
            },
            {
                value: 'Fenchel',
                image: IconFenchel,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Fenchel sollte man kühl und feucht lagern. ',
            },
        ],
    },
    {
        icon: IconBreakfast,
        desc: 'Du möchtest ein leckeres Frühstück auf den Tisch zaubern! Doch wo sind die Lebensmittel idealerweise zu lagern?',
        path: 'breakfast',
        title: 'Frühstück',
        level: 5,
        info: '',
        products: [
            {
                value: 'Lachs',
                image: IconSalmon,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lagere frischen Lachs an der kühlsten Stelle.',
            },
            {
                value: 'Ei',
                image: IconEgg,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Eier sind gekühlt i.d.R. ca. 28 Tage haltbar.',
            },
            {
                value: 'Leinöl',
                image: IconLeinol,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Leinöl für maximal 6 Wochen kühl lagern.',
            },
            {
                value: 'Brunnenkresse',
                image: IconWatercress,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Brunnenkresse für max. 1 Woche kühl lagern.',
            },
            {
                value: 'Cashewkerne',
                image: IconCashew,
                type: 'regal',
                typeName: 'Regal',
                info: 'Cashewkerne trocken und luftdicht lagern.',
            },
            {
                value: 'Honig',
                image: IconHoney,
                type: 'regal',
                typeName: 'Regal',
                info: 'Honig sollte kühl und dunkel gelagert werden.',
            },
            {
                value: 'Haferflocken',
                image: IconOat,
                type: 'regal',
                typeName: 'Regal',
                info: 'Haferflocken mögen es trocken und luftdicht. ',
            },
            {
                value: 'Apfel',
                image: IconApple,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Äpfel kurzfristig im Kühlschrank lagern.',
            },
            {
                value: 'Orangen',
                image: IconOrange,
                type: 'bowl',
                typeName: 'Schale',
                info: 'Lagere Orangen kühl, trocken und gut belüftet.',
            },
            {
                value: 'Cranberrys (frisch)',
                image: IconCranberry,
                type: 'fridge',
                typeName: 'Kühlschrank',
                info: 'Lagere Orangen kühl, trocken und gut belüftet.',
            },
        ],
    },
];

export const targets = [
    {
        title: 'Kühlschrank',
        type: 'fridge',
        image: IconFridge,
    },
    {
        title: 'Schale',
        type: 'bowl',
        image: IconBowl,
    },
    {
        title: 'Regal',
        type: 'regal',
        image: IconRegal,
    },
];
