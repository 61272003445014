import {routes} from 'shared-utils/utils/routes';

export const navigationLang = [
    {
        value: 'Wochenplan',
        icon: 'calendar',
        path: routes.weeklyPlanner,
    },
    {
        value: 'Rezeptauswahl',
        icon: 'recipeSelection',
        path: routes.categories,
    },
    {
        value: 'Glossar',
        icon: 'glossar',
        path: routes.glossary,
    },
    {
        value: 'Lagerungsquiz',
        icon: 'joypad',
        path: routes.gameStart,
    },
    {
        value: 'Einkaufsliste',
        icon: 'list',
        path: routes.shoppingList,
    },
];
