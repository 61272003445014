export const categoryListNormalize = async ({data}) => {
    const normalizedMap = data.map((item) => ({
        id: item.id,
        image: item.thumbnail,
        description: item.description,
        title: item.name,
        recipeNumber: item.recipes_count,
    }));
    return normalizedMap;
};
