import React from 'react';
import PropTypes from 'prop-types';

import {
    StyledList,
    ListElement,
    Indicator,
    IndicatorWrapper,
    TextWrapper,
} from './styles';

import Paragraph from '../Paragraph';

const List = ({items, className}) => {
    return (
        <StyledList className={className}>
            {items.map((value, index) => {
                return (
                    <ListElement key={index}>
                        <IndicatorWrapper>
                            <Indicator>{index + 1}</Indicator>
                        </IndicatorWrapper>

                        <TextWrapper border={index !== items.length - 1}>
                            <Paragraph>{value.description}</Paragraph>
                        </TextWrapper>
                    </ListElement>
                );
            })}
        </StyledList>
    );
};

List.propTypes = {
    items: PropTypes.array.isRequired,
    className: PropTypes.string,
};

List.defaultProps = {
    className: 'ap-list',
};

export default List;
