import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';

import {color} from '../../../styles/variables';

import {StyledInput, Wrapper} from './styles';

const Input = ({type, className, placeholder, iconName, onChange}) => {
    return (
        <Wrapper justifyContent="space-between" alignItems="center">
            <StyledInput
                type={type}
                className={className}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
            />
            {iconName && (
                <Icon name={iconName} color={color.grey} size="16px" />
            )}
        </Wrapper>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    iconName: PropTypes.string,
    onChange: PropTypes.func,
};

Input.defaultProps = {
    className: 'app-input',
    type: '',
    placeholder: '',
    iconName: '',
    onChange: () => {},
};

export default Input;
