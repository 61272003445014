import styled from 'styled-components';

export const CircularProgressbar = styled.svg`
    width: 100%;
    vertical-align: middle;
`;
export const CircularProgressbarPath = styled.path`
    stroke: url(#gradient);
    transition: stroke-dashoffset 0.5s ease 0s;
`;
export const CircularProgressbarTrail = styled.path`
    stroke: #d6d6d6;
    stroke-linecap: round;
`;

export const CircularProgressbarBackground = styled.circle`
    fill: white;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
`;
export const CircularProgressbarChildren = styled.circle`
    fill: #d6d6d6;
`;
