import React from 'react';
import {useParams} from '@reach/router';

import GameLevel from 'components/Templates/GameLevel';

const GameLevelPage = () => {
    const params = useParams();
    const {level} = params;

    return <GameLevel levelPath={level} />;
};

export default GameLevelPage;
