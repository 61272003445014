import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import {color, queries} from 'shared-ui/styles/variables';

export const SelectWrapper = styled.div`
    background: ${color.button.primary};
    border-radius: 10px;
    width: 60%;
    margin: 10px 0;
    padding: 10px 30px;
    @media ${queries.smMax} {
        width: 80%;
    }
`;

export const StyledPlannerCard = styled(Card)`
    overflow: auto;
    width: 75vw;
    @media ${queries.sm} {
        width: ${({width}) => width};
    }
    @media ${queries.smMax} {
        height: 550px;
    }
`;
