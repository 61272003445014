export const getWeekNUmber = (date) => {
    if (!(date instanceof Date)) date = new Date();
    var nDay = (date.getDay() + 6) % 7;
    date.setDate(date.getDate() - nDay + 3);
    var n1stThursday = date.valueOf();
    date.setMonth(0, 1);
    if (date.getDay() !== 4) {
        date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
};
