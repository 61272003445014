import styled from 'styled-components';

import Headline from 'shared-ui/components/Elements/Headline';

import {queries, color, shadow} from 'shared-ui/styles/variables';

export const StyledHeaderImage = styled.div`
    position: relative;
    height: ${({theme}) => (theme === 'small' ? '240px' : '45vh')};
    width: 100%;
    border-bottom-right-radius: 75px;
    overflow: hidden;
    box-shadow: ${shadow};

    @media ${queries.lg} {
        height: 480px;
        border-bottom-right-radius: 150px;
    }
`;

export const PreviewImage = styled.div`
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background-image: ${({src}) => src && `url(${src})`};
    background-repeat: no-repeat;
    background-size: cover;

    @media ${queries.md} {
        width: 100%;
    }
    &:before {
        content: '';
        width: 100%;
        height: 30%;
        position: absolute;
        background: ${color.gradientWhite};
        top: 0;
        z-index: 11;
    }
    &:after {
        content: '';
        width: 100%;
        height: 40%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: ${color.gradientBlackHorizontal};

        @media ${queries.md} {
            background: ${color.gradientBlackVertical};
            height: 100%;
        }
    }
`;

export const Title = styled(Headline)`
    margin-bottom: 0;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 32px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media ${queries.md} {
        padding-left: 25%;
        width: 75%;
    }
`;

export const BadgeWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 70px;
    position: absolute;
    margin-top: 18px;
    top: 0;

    @media ${queries.md} {
        padding-left: 25%;
        width: 75%;
    }
`;
