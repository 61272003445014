import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import FilterBoxGroup from 'shared-ui/components/Elements/FilterBoxGroup';
import Lightbox from 'shared-ui/components/Elements/Lightbox';

import {
    StyledRecipeFilter,
    FilterButton,
    SubmitButton,
    Window,
    ScrollWrapper,
    FilterDesktop,
    FadeOutBottom,
    FadeOutTop,
} from './styles';

const RecipeFilter = ({
    items,
    isFilterButtonVisible,
    setSelectedFilter,
    selectedFilter,
}) => {
    const modalRef = useRef();
    const [open, setOpen] = useState(false);

    const handleSelectedFilter = (value) => {
        if (selectedFilter.indexOf(value) > -1) {
            const selectedValue = selectedFilter.filter(
                (selectedValue) => selectedValue !== value
            );
            setSelectedFilter(selectedValue);
        } else {
            setSelectedFilter([...selectedFilter, value]);
        }
    };

    return (
        <StyledRecipeFilter>
            <FilterDesktop>
                <FilterBoxGroup
                    items={items}
                    selectedValues={selectedFilter}
                    onChange={(value) => {
                        handleSelectedFilter(value);
                    }}
                />
            </FilterDesktop>
            {!isFilterButtonVisible && (
                <FilterButton onClick={() => setOpen(true)} label="Filter" />
            )}

            <Lightbox
                isOpen={open}
                isOrangeBg={true}
                onClose={() => setOpen(false)}
            >
                <Window>
                    <ScrollWrapper ref={modalRef}>
                        <FilterBoxGroup
                            items={items}
                            selectedValues={selectedFilter}
                            onChange={(value) => {
                                handleSelectedFilter(value);
                            }}
                        />
                    </ScrollWrapper>

                    <FadeOutTop />
                    <FadeOutBottom />

                    <SubmitButton
                        onClick={() => setOpen(false)}
                        label="Bestätigen"
                    />
                </Window>
            </Lightbox>
        </StyledRecipeFilter>
    );
};

RecipeFilter.propTypes = {
    items: PropTypes.array.isRequired,
    isFilterButtonVisible: PropTypes.bool.isRequired,
    setSelectedFilter: PropTypes.func.isRequired,
    selectedFilter: PropTypes.array.isRequired,
};

export default RecipeFilter;
