import React from 'react';
import Notifications from 'react-notify-toast';
import {
    QueryClient,
    QueryClientProvider,
    ReactQueryDevtools,
    Router,
} from 'shared-utils';

import GlobalStyle from 'shared-ui/styles/global';

import AppLayout from 'components/Layout/AppLayout';

import Categories from 'pages/categories';
import CategoryDetailPage from 'pages/category-detail';
import FavoritesPage from 'pages/favorites';
import GamePage from 'pages/game';
import GameLevelPage from 'pages/game-level';
import GameStartPage from 'pages/game-start';
import GlossaryPage from 'pages/glossary';
import GlossaryDetailPage from 'pages/glossary-detail';
import Home from 'pages/home';
import RecipeDetailPage from 'pages/recipe-detail';
import ShoppingListPage from 'pages/shopping-list';
import WeeklyPlannerPage from 'pages/weekly-planner';

import {FavProvider} from 'context/FavContext';
import {GameProvider} from 'context/GameContext';
import {NutritionPlanProvider} from 'context/NutritionPlanContext';
import {ShoppingListProvider} from 'context/ShoppingListContext';
import {ToolTipProvider} from 'context/ToolTipContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <NutritionPlanProvider>
                <FavProvider>
                    <ShoppingListProvider>
                        <GameProvider>
                            <ToolTipProvider>
                                <GlobalStyle />
                                <Router basepath="/nutritionmanager">
                                    <AppLayout path="/">
                                        <Home path="/" />
                                        <Categories path="categories" />
                                        <CategoryDetailPage path="categories/:categoryId" />
                                        <RecipeDetailPage path="recipe/:recipeId" />
                                        <GlossaryPage path="glossary" />
                                        <GlossaryDetailPage path="glossary/:glossaryId" />
                                        <FavoritesPage path="favorites" />
                                        <ShoppingListPage path="shopping-list" />
                                        <WeeklyPlannerPage path="weekly-planner" />
                                        <GameStartPage path="game-start" />
                                        <GamePage path="game" />
                                        <GameLevelPage path="game/:level" />
                                    </AppLayout>
                                </Router>
                                <Notifications options={{zIndex: 1010}} />
                            </ToolTipProvider>
                        </GameProvider>
                    </ShoppingListProvider>
                </FavProvider>
            </NutritionPlanProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default App;
