import {useQuery} from 'react-query';

import {fetchGameLevels} from 'services/request/game-levels';
import {GAME_LEVELS} from 'services/config/query-keys';
import {gameLevels} from 'services/normalize/game-levels';

const getGameLevels = async () => {
    const data = await fetchGameLevels();
    const normalize = await gameLevels(data);
    return normalize;
};

export function useGameLevels() {
    return useQuery(GAME_LEVELS, getGameLevels);
}
