import {useMutation} from 'react-query';

import {exportShoppingPdf} from 'services/request/export-shopping-list';

export const getExportShoppingList = async () => {
    const response = exportShoppingPdf();
    return response || false;
};

export const useExportShoppingList = () => {
    const mutate = useMutation(getExportShoppingList, {
        onSuccess: (response) => {
            window.open(response.data.url, '_blank');
        },
    });
    return mutate;
};
