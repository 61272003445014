import {
    StyledTips,
    StyledDiv,
    StyledContainer,
    Wrapper,
    StyledHeaderWrapper,
} from './styles';
import HeaderImage from 'components/Layout/HeaderImage';
import HomepageNavigation from 'components/Layout/HomepageNavigation';
import Recipe from 'components/Layout/Recipe';
import Tooltip from 'components/Layout/Tooltip';
import HomepageHeader from 'nutrition-manager/src/assets/images/homepage-header.jpg';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Divider from 'shared-ui/components/Elements/Divider';
import Headline from 'shared-ui/components/Elements/Headline';
import Div from 'shared-ui/components/Layout/Div';
import Flex from 'shared-ui/components/Layout/Flex';
import {Row, Column} from 'shared-ui/components/Layout/Grid';
import ServiceCenter from 'shared-ui/components/Layout/ServiceCenter';
import {lang} from 'shared-ui/lang/global.lang';
import {queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

const HomepageTemplate = ({tipsText, recipeOfDay}) => {
    const isLg = useMediaQuery(queries.lg);

    const deleteCache = () => {
        localStorage.removeItem('tooltipSteps');
        window.location.reload();
    };

    return (
        <StyledDiv>
            <StyledHeaderWrapper>
                <HeaderImage
                    title={lang.homepageIntro}
                    image={HomepageHeader}
                />
            </StyledHeaderWrapper>

            <StyledContainer bgWhite>
                <Wrapper>
                    <Div marginBottom="50px">
                        <Tooltip theme="navigation" tipIndex={1} />
                        <HomepageNavigation />
                    </Div>
                    {isLg ? (
                        <Column cols={{sm: 8}} offset={{sm: 2}}>
                            <Row>
                                <Column cols={{sm: 6}}>
                                    <Flex marginTop="40px">
                                        <StyledTips
                                            headline={lang.tipsTricks}
                                            text={tipsText}
                                        >
                                            <Button
                                                label="Tooltipps zurücksetzen"
                                                theme="gradientBorder"
                                                margin="10px 0"
                                                onClick={deleteCache}
                                            />
                                        </StyledTips>
                                    </Flex>
                                </Column>
                                {recipeOfDay && (
                                    <Column cols={{sm: 6}}>
                                        <Headline type="h3" textAlign="center">
                                            {lang.recipeOfTheDay}
                                        </Headline>
                                        <Card width="100%">
                                            <Recipe
                                                id={recipeOfDay.id}
                                                headline={recipeOfDay.headline}
                                                kcal={recipeOfDay.kcal}
                                                minutes={recipeOfDay.minutes}
                                                difficulty={
                                                    recipeOfDay.difficulty
                                                }
                                                image={recipeOfDay.image}
                                            />
                                        </Card>
                                    </Column>
                                )}
                            </Row>
                        </Column>
                    ) : (
                        <Column cols={{sm: 8}} offset={{sm: 2}}>
                            <Row>
                                <Column>
                                    <Divider
                                        width="48px"
                                        height="5px"
                                        marginBottom="20px"
                                        center
                                    />

                                    {recipeOfDay && (
                                        <Div>
                                            <Headline
                                                type="h3"
                                                textAlign="center"
                                            >
                                                {lang.recipeOfTheDay}
                                            </Headline>
                                            <Card width="100%">
                                                <Recipe
                                                    id={recipeOfDay.id}
                                                    headline={
                                                        recipeOfDay.headline
                                                    }
                                                    kcal={recipeOfDay.kcal}
                                                    minutes={
                                                        recipeOfDay.minutes
                                                    }
                                                    difficulty={
                                                        recipeOfDay.difficulty
                                                    }
                                                    image={recipeOfDay.image}
                                                />
                                            </Card>
                                        </Div>
                                    )}
                                </Column>
                                <Column>
                                    <Flex marginTop="50px">
                                        <StyledTips
                                            headline={lang.tipsTricks}
                                            text={tipsText}
                                        >
                                            <Button
                                                label="Tooltipps zurücksetzen"
                                                theme="gradientBorder"
                                                margin="10px 0"
                                                onClick={deleteCache}
                                            />
                                        </StyledTips>
                                    </Flex>
                                </Column>
                            </Row>
                        </Column>
                    )}
                </Wrapper>
            </StyledContainer>

            <ServiceCenter />
        </StyledDiv>
    );
};

HomepageTemplate.propTypes = {
    tipsText: PropTypes.string.isRequired,
    recipeOfDay: PropTypes.shape({
        headline: PropTypes.string,
        minutes: PropTypes.number,
        difficulty: PropTypes.number,
        kcal: PropTypes.number,
        image: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
};

export default HomepageTemplate;
