import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Flex from 'shared-ui/components/Layout/Flex';
import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {lang} from 'shared-ui/lang/global.lang';
import {color, queries} from 'shared-ui/styles/variables';
import {
    StyledCard,
    TextWrapper,
    ParagraphWrapper,
    StyledParagraph,
    StyledButton,
    ButtonsWrapper,
    StyledFlex,
    ImageWrapper,
    TitleWrapper,
} from './styles';

import {routes} from 'shared-utils/utils/routes';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

const RecipeCollapseCard = ({
    children,
    image,
    title,
    ingredientsNumber,
    kcal,
    handleDelete,
    id,
}) => {
    const isMd = useMediaQuery(queries.md);
    const [isOpen, setIsOpen] = useState(false);

    const handleToRecipeDetail = () => navigate(`${routes.recipe}/${id}`);

    return (
        <StyledCard>
            <Flex alignItems="center">
                <ImageWrapper
                    onClick={handleToRecipeDetail}
                    backgroundImage={image}
                />
                <TextWrapper>
                    <TitleWrapper onClick={handleToRecipeDetail}>
                        <Paragraph theme="darkBlue" bold size="small">
                            {title}
                        </Paragraph>
                    </TitleWrapper>
                    {isMd && (
                        <ParagraphWrapper isFlex>
                            <Paragraph bold size="xxsmall">
                                {kcal} {lang.kcalProPortion}
                            </Paragraph>
                            <StyledParagraph bold size="xxsmall">
                                {ingredientsNumber} {lang.ingredients}
                            </StyledParagraph>
                        </ParagraphWrapper>
                    )}
                </TextWrapper>

                <ButtonsWrapper>
                    {isMd && isOpen && (
                        <StyledButton
                            theme="active"
                            icon="delete"
                            iconColor={color.white}
                            iconSize="20px"
                            size="60px"
                            onClick={handleDelete}
                        />
                    )}
                    <StyledButton
                        theme="white"
                        icon={isOpen ? 'close' : 'plus'}
                        iconSize="20px"
                        size="60px"
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </ButtonsWrapper>
            </Flex>

            {isOpen && (
                <>
                    {!isMd && (
                        <StyledFlex
                            justifyContent="space-between"
                            marginTop="5px"
                        >
                            <ParagraphWrapper>
                                <StyledParagraph
                                    theme="darkBlue"
                                    bold
                                    size="xxsmall"
                                >
                                    {kcal} {lang.kcalProPortion}
                                </StyledParagraph>
                                <StyledParagraph
                                    theme="darkBlue"
                                    bold
                                    size="xxsmall"
                                >
                                    {ingredientsNumber} {lang.ingredients}
                                </StyledParagraph>
                            </ParagraphWrapper>
                            <StyledButton
                                theme="active"
                                icon="delete"
                                iconColor={color.white}
                                iconSize="20px"
                                size="60px"
                                onClick={handleDelete}
                            />
                        </StyledFlex>
                    )}
                    {children}
                </>
            )}
        </StyledCard>
    );
};

RecipeCollapseCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    kcal: PropTypes.number.isRequired,
    ingredientsNumber: PropTypes.number.isRequired,
    children: PropTypes.any.isRequired,
    handleDelete: PropTypes.func,
    id: PropTypes.number.isRequired,
};

RecipeCollapseCard.defaultProps = {
    handleDelete: () => {},
    secondaryButtonIconName: '',
};

export default RecipeCollapseCard;
