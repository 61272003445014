import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {navigate} from '@reach/router';

import Lightbox from 'shared-ui/components/Elements/Lightbox';
import {routes} from 'shared-utils/utils/routes';

import GameEndScreen from 'components/Layout/GameEndScreen';
import {useStoreCompletedLevel} from 'services/data-hooks/game/useStoreCompletedLevel';
import {targets, levels} from 'lang/game-levels.lang';
import {useGameContext} from 'context/GameContext';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';

import Target from './Target';
import ProductCard from './ProductCard';

import {CategoryCards, SelectCardDiv} from './styles';

const GameLevelMotionArea = ({levelPath}) => {
    const {mutate} = useStoreCompletedLevel();
    const {
        handleRemoveLive,
        livesCount,
        setLivesCount,
        setInitialDroppedProduct,
        setTrueType,
        setFalseType,
        handleAlertText,
    } = useGameContext();

    const [sumAnswersNumber, setSumAnswersNumber] = useState(0);
    const [trueNumber, setTrueNumber] = useState(0);
    const [falseNumber, setFalseNumber] = useState(0);
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [currentLevelProducts, setCurrentLevelProducts] = useState([]);

    const currentLevel = levels.find((x) => x.path === levelPath);
    const nextLevel = levels.filter((x) => x.path !== levelPath).shift();

    useEffect(() => {
        const isEqual = sumAnswersNumber === currentLevel.products.length;
        if (isEqual) {
            setIsLightBoxOpen(true);
            mutate(currentLevel.level);
        }
    }, [sumAnswersNumber]);

    useEffect(() => {
        setCurrentLevelProducts(currentLevel.products);
        setIsLightBoxOpen(false);
    }, [currentLevel]);

    useEffect(() => {
        if (livesCount === 0) {
            setIsLightBoxOpen(true);
        }
        if (livesCount === 3) {
            setIsLightBoxOpen(false);
        }
    }, [livesCount]);

    const handleResetData = () => {
        setLivesCount(3);
        setTrueType(null);
        setFalseType(null);
        setTrueNumber(0);
        setFalseNumber(0);
        setSumAnswersNumber(0);
    };

    const handleRepeatLevel = () => {
        setCurrentLevelProducts(currentLevel.products);
        handleResetData();
    };

    const handleNextLevel = () => {
        if (nextLevel.path) {
            navigate(`${routes.gameLevel}/${nextLevel.path}`);
            handleResetData();
        }
    };

    function onDragEnd(val) {
        const {draggableId, destination} = val;
        if (!val.destination) {
            return;
        }

        if (destination.droppableId !== 'StartTarget') {
            if (sumAnswersNumber < 1) {
                dataLayerPush({
                    event: 'biib_internalLinkClick',
                    eventAsset: 'game_play',
                    eventAssetBis: levelPath.title,
                });
            }
            const droppedProduct = currentLevelProducts.find(
                (x) => x.value === draggableId
            );
            setInitialDroppedProduct(droppedProduct);
            setSumAnswersNumber((sumAnswersNumber) => sumAnswersNumber + 1);
            if (destination.droppableId === droppedProduct.type) {
                setTrueType(destination.droppableId);
                setTrueNumber((trueNumber) => trueNumber + 1);
                handleAlertText(droppedProduct, true);
            } else {
                setFalseType(destination.droppableId);
                setFalseNumber((falseNumber) => falseNumber + 1);
                handleRemoveLive(falseNumber + 1);
                handleAlertText(droppedProduct, false);
            }
            setCurrentLevelProducts((currentLevelProducts) =>
                currentLevelProducts.filter((x) => x.value !== draggableId)
            );
        }
    }
    function onDragStart() {
        setTrueType(null);
        setFalseType(null);
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <SelectCardDiv productsLength={currentLevelProducts?.length}>
                <Droppable
                    droppableId="StartTarget"
                    type="product"
                    direction="horizontal"
                >
                    {(provided) => (
                        <div
                            style={{
                                width: 'inherit',
                                display: 'flex',
                                height: 100,
                            }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {currentLevelProducts?.map((product, index) => (
                                <ProductCard
                                    key={product.value}
                                    value={product.value}
                                    image={product.image}
                                    index={index}
                                />
                            ))}
                        </div>
                    )}
                </Droppable>
            </SelectCardDiv>
            <CategoryCards>
                {targets.map((target) => (
                    <Target
                        key={target.type}
                        droppableId={target.type}
                        src={target.image}
                        title={target.title}
                        type={target.type}
                    />
                ))}
            </CategoryCards>
            <Lightbox isOpen={isLightBoxOpen}>
                <GameEndScreen
                    totalPoints={currentLevel.products.length}
                    scoredPoints={trueNumber}
                    handleRepeatLevel={handleRepeatLevel}
                    handleNextLevel={handleNextLevel}
                />
            </Lightbox>
        </DragDropContext>
    );
};

GameLevelMotionArea.propTypes = {
    levelPath: PropTypes.string.isRequired,
};

export default GameLevelMotionArea;
