import {useQuery} from 'react-query';

import {fetchIngredientBasic} from 'services/request/ingredients-basic';
import {INGREDIENTS_BASIC} from 'services/config/query-keys';
import {ingredientsBasicNormalize} from 'services/normalize/ingredients-basic';

const getIngredientsBasic = async () => {
    const data = await fetchIngredientBasic();
    const normalize = await ingredientsBasicNormalize(data);
    return normalize;
};

export function useIngredientsBasic() {
    return useQuery(INGREDIENTS_BASIC, () => getIngredientsBasic());
}
