import styled from 'styled-components';

import Icon from 'shared-ui/components/Elements/Icon';
import Flex from 'shared-ui/components/Layout/Flex';

import {cardRadius, shadow, queries} from 'shared-ui/styles/variables';

export const StyledRecipeInfo = styled.div`
    width: ${(props) => props.width};
`;

export const InfoWrapper = styled(Flex)`
    flex-flow: row wrap;
    margin-bottom: 32px;
    border-radius: ${cardRadius};
    box-shadow: ${shadow};
    padding: 10px 15px;
    width: fit-content;
`;

export const Info = styled(Flex)`
    margin-right: 24px;
`;

export const InfoIcon = styled(Icon)`
    margin-right: 6px;
    width: 13px;
    height: 100%;

    @media ${queries.lg} {
        width: 15px;
    }
`;
