export const ingredientsDetailNormalize = async ({data}) => {
    const normalizedMap = {
        id: data.id,
        title: data.name,
        nutrientsParagraph: data.description,
        nutrients: {
            kcal: parseInt(data.calories),
            protein: parseInt(data.protein),
            fat: parseInt(data.fat),
            carbon: parseInt(data.carbs),
        },
        vitaminMineralList: data.micronutrients.map((item) => ({
            title: item.name,
            type: item.type,
            content: item.description,
        })),
        lagerungsortParagraph: data.storage_description,
        storages: data.storage_locations.map((item) => ({
            title: item.name,
            icon: item.icon,
        })),
        amount: data.amount,
        prefix: data.prefix,
        suffix: data.suffix,
    };
    return normalizedMap;
};
