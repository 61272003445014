import styled from 'styled-components';

import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';

import {queries, cardRadius} from 'shared-ui/styles/variables';

export const StyledRecipeFilter = styled.div`
    width: 100%;
`;

export const Window = styled(Card)`
    height: 50vh;
    border-radius: ${cardRadius};
    overflow: hidden;
    padding: 0;
    width: 75vw;

    @media ${queries.smMax} {
        height: 80vh;
    }

    @media ${queries.mdMax} {
        height: 60vh;
    }

    & div > div > label {
        width: 110px;
        height: 110px;
        margin: 6px;

        & div {
            width: 110px;
            height: 110px;
            padding: 4px;
        }
    }
`;

export const Close = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
`;

export const ScrollWrapper = styled.div`
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    height: 100%;
    width: 100%;
    padding: 1rem;
    padding-bottom: 4rem;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const FilterDesktop = styled.div`
    display: none;

    @media ${queries.md} {
        display: block;
    }
`;

export const FilterButton = styled(Button)`
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    z-index: 900;

    @media ${queries.md} {
        display: none;
    }
`;

export const SubmitButton = styled(Button)`
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    z-index: 5;
`;

export const FadeOutBottom = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 96px;
    background: rgb(233, 233, 233);
    border-bottom-left-radius: ${cardRadius};
    border-bottom-right-radius: ${cardRadius};
    background: linear-gradient(
        0deg,
        rgba(233, 233, 233, 1) 70%,
        rgba(233, 233, 233, 0) 100%
    );
    z-index: 5;
`;

export const FadeOutTop = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 16px;
    border-top-left-radius: ${cardRadius};
    border-top-right-radius: ${cardRadius};
    z-index: 5;
    background: linear-gradient(
        180deg,
        rgba(233, 233, 233, 1) 0%,
        rgba(233, 233, 233, 0) 100%
    );
`;
