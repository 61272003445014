import React from 'react';

import ShoppingList from 'components/Templates/ShoppingList';
import PageLayout from 'components/Layout/PageLayout';

import {useShoppingRecipeList} from 'services/data-hooks/shoppingList/useShoppingRecipeList';
import {useIngredientsBasic} from 'services/data-hooks/ingredients/useIngredientsBasic';

const ShoppingListPage = () => {
    const {data: recipes, isLoading} = useShoppingRecipeList();
    const {data: ingredients, isLoading: isIngredientsLoading} =
        useIngredientsBasic();

    if (isLoading) {
        return null;
    }

    return (
        <PageLayout bgWhite>
            <ShoppingList
                recipes={recipes?.data}
                ingredients={ingredients}
                isIngredientsLoading={isIngredientsLoading}
            />
        </PageLayout>
    );
};

export default ShoppingListPage;
