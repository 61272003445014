import React from 'react';
import PropTypes from 'prop-types';

import {StyledFilterBoxGroup} from './styles';

import FilterBox from '../FilterBox';

const FilterBoxGroup = ({items, name, onChange, selectedValues}) => (
    <StyledFilterBoxGroup className="ap-filterbox">
        {items.map(({value, label, icon}, index) => {
            return (
                <FilterBox
                    key={index}
                    id={name + index}
                    label={label}
                    checked={selectedValues?.indexOf(value) > -1}
                    value={value}
                    name={name}
                    icon={icon}
                    onChange={onChange}
                />
            );
        })}
    </StyledFilterBoxGroup>
);

FilterBoxGroup.propTypes = {
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    selectedValues: PropTypes.array,
};

FilterBoxGroup.defaultProps = {
    selectedValues: null,
    name: 'ap-filterbox',
    onChange: () => {},
};

export default FilterBoxGroup;
