import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {queries} from 'shared-ui/styles/variables';
import {lang} from 'shared-ui/lang/global.lang';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {routes} from 'shared-utils/utils/routes';

import {
    StyledCard,
    CategoryImage,
    CategoryImageWrapper,
    ParagraphWrapper,
    StyledParagraph,
    StyledHeadline,
} from './styles';

const CategoryCard = ({id, recipeNumber, title, description, image}) => {
    const isMd = useMediaQuery(queries.md);
    return (
        <StyledCard
            isHoverBg
            onClick={() => navigate(`${routes.categories}/${id}`)}
        >
            <CategoryImageWrapper>
                <CategoryImage image={image} />
            </CategoryImageWrapper>
            {isMd && (
                <>
                    <Paragraph>
                        {recipeNumber} {lang.recipe}
                    </Paragraph>
                    <StyledHeadline type="h4">{title}</StyledHeadline>
                    <Paragraph>{description}</Paragraph>
                </>
            )}
            {!isMd && (
                <ParagraphWrapper>
                    <StyledHeadline textAlign="center" type="h4">
                        {title}
                    </StyledHeadline>
                    <StyledParagraph textAlign="center">
                        {recipeNumber} {lang.recipe}
                    </StyledParagraph>
                </ParagraphWrapper>
            )}
        </StyledCard>
    );
};

CategoryCard.propTypes = {
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    recipeNumber: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
};

export default CategoryCard;
