import React from 'react';
import PropTypes from 'prop-types';

import Card from 'shared-ui/components/Elements/Card';
import Headline from 'shared-ui/components/Elements/Headline';
import Flex from 'shared-ui/components/Layout/Flex';

import {ItemWrapper, UnitWrapper, Unit, Title} from './styles';
import {lang} from 'shared-ui/lang/global.lang';

const Expedient = ({expedients}) => {
    return (
        <Card theme="secondary">
            <Headline type="h4">{lang.expedient}</Headline>
            <Flex flexDirection="column">
                {expedients.map((item, index) => (
                    <ItemWrapper key={index} alignItems="center">
                        <UnitWrapper>
                            <Unit theme="darkBlue">{item.unit}</Unit>
                        </UnitWrapper>
                        <Title theme="grey">{item.title}</Title>
                    </ItemWrapper>
                ))}
            </Flex>
        </Card>
    );
};

Expedient.propTypes = {
    expedients: PropTypes.array.isRequired,
};

export default Expedient;
