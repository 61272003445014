import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';

import App from '../../../psp-mono-repo/apps/nutrition-manager/src/App';

import Layout from '../components/Layout/Layout';
import AgreementForm from '../components/Container/AgreementForm';

import {PageTemplateStyled} from '../templates/styles';

const PspHome = ({user, location}) => {
	const isAggrement = user?.Agreement;

	useEffect(() => {
		if (!user) {
			navigate('/login');
		}
	}, [user]);

	if (!isAggrement) return null;
	return (
		<Layout location={location} isTabsShow={false}>
			<PageTemplateStyled>
				{isAggrement ? <App pspToken={user?.pspToken} /> : <AgreementForm />}
			</PageTemplateStyled>
		</Layout>
	);
};

PspHome.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object]).isRequired,
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(PspHome);
