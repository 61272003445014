import styled, {css} from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Flex from 'shared-ui/components/Layout/Flex';

import {cardRadius, color, queries} from 'shared-ui/styles/variables';

const cardSizes = css`
    width: 125px;
    height: 125px;
    @media ${queries.lgMax} {
        width: 90px;
        height: 90px;
    }
    @media ${queries.mdMax} {
        width: 70px;
        height: 70px;
    }
`;

const recipeCardStyles = styled(Flex)`
    ${cardSizes};
    border-radius: ${cardRadius};
    cursor: pointer;

    @media ${queries.smMax} {
        width: 100px;
    }
`;

export const RecipeWrapper = styled(Flex)`
    margin: 0 0.625rem;
    position: relative;
    @media ${queries.smMax} {
        margin: 0;
        border-bottom: 1px dashed ${color.lightGrey};
    }
    @media ${queries.sm} {
        :after {
            content: '';
            height: 60%;
            width: 1px;
            position: absolute;
            right: -10px;
            bottom: 10px;
            border-right: 1px dashed ${color.lightGrey};
        }
        &:last-child {
            :after {
                border-right: none;
            }
        }
    }
`;

export const RecipeCard = styled(Flex)`
    margin: 10px 0;
    @media ${queries.smMax} {
        margin: 10px;
    }
`;

export const DayCard = styled(Card)`
    ${cardSizes};
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    @media ${queries.smMax} {
        margin-right: 15px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
`;

export const ImageWrapper = styled(recipeCardStyles)`
    overflow: hidden;
    background-image: ${({bgImage}) => bgImage && `url(${bgImage})`};
    background-repeat: no-repeat;
    background-size: cover;
`;

export const StyledPlusCard = styled(recipeCardStyles)`
    border: 1px solid ${color.lightGrey};
    box-sizing: border-box;
    &:hover {
        border: 1px solid ${color.orange};
    }
`;
