import styled from 'styled-components';

import Flex from 'shared-ui/components/Layout/Flex';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Card from 'shared-ui/components/Elements/Card';

import {font, queries, zIndex} from 'shared-ui/styles/variables';
import {Container} from 'shared-ui/components/Layout/Grid';

import teaserDesktop from 'nutrition-manager/src/assets/images/img-teaser-rezepte.png';
import teaserMobile from 'nutrition-manager/src/assets/images/img-teaser-rezepte-mobile.png';

export const StyledHeaderWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 4rem;

    @media ${queries.lg} {
        width: 90%;
        margin-bottom: 8rem;
    } ;
`;

export const TeaserText = styled(Paragraph)`
    @media ${queries.lg} {
        font-size: ${font.size.large};
    }
`;

export const RecipeTeaser = styled(Container)`
    background: url(${teaserMobile});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    margin-top: 4rem;
    height: 25rem;
    padding: 1.5rem;

    @media ${queries.md} {
        background: url(${teaserDesktop});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top;
        height: 20rem;
    }
`;

export const ContentWrapper = styled(Flex)`
    width: 100%;
    @media ${queries.md} {
        width: 480px;
    }
    @media ${queries.lg} {
        width: 70%;
    }
`;

export const AlertText = styled(Paragraph)`
    margin-left: 10px;
`;
export const PlannerNavWrapper = styled.div`
    position: absolute;
    z-index: ${zIndex.navbar};
    top: 0;
    right: -8px;
    @media ${queries.xxl} {
        top: 0px;
        right: -87px;
    }
`;

export const LightboxContent = styled(Card)`
    width: 320px;
    @media ${queries.md} {
        width: 480px;
    }
    @media ${queries.lg} {
        width: 640px;
    }
`;

export const StyledFlex = styled(Flex)`
    padding-top: 64px;
    padding-bottom: 32px;
    @media ${queries.mdMax} {
        padding-top: 20px;
        padding-bottom: 0px;
        padding-left: 10px;
    }
`;

export const NutritionPlanWrapper = styled(Flex)`
    justify-content: flex-start;
    @media ${queries.sm} {
        justify-content: center;
    }
`;
