import React, {useState} from 'react';
import {navigate} from '@reach/router';
import {AnimatePresence, AnimateSharedLayout} from 'framer-motion';

import Icon from '../Icon';

import {color} from '../../../styles/variables';

import {containerVariant, mainIconVariant} from './animations';
import {tabs} from './tabs.data';

import {
    StyledTabs,
    StyledTab,
    StyledTabsContainer,
    StyledMainButton,
} from './styles';

export default function AnimatedTab() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <StyledTabsContainer>
            <AnimateSharedLayout type="crossfade" exitBeforeEnter>
                <StyledTabs
                    variants={containerVariant}
                    initial="hidden"
                    animate={isOpen ? 'show' : 'hidden'}
                >
                    <AnimatePresence>
                        {tabs.map((tab) => (
                            <StyledTab
                                key={tab.key}
                                variants={tab.variants}
                                onClick={() => {
                                    navigate(`${tab.path}`);
                                    setIsOpen(false);
                                }}
                                whileHover={{scale: 1.1}}
                            >
                                <Icon
                                    name={tab.icon}
                                    color={color.white}
                                    size="36px"
                                />
                            </StyledTab>
                        ))}
                    </AnimatePresence>
                </StyledTabs>

                <StyledTabs>
                    <StyledMainButton
                        initial={{opacity: 0}}
                        animate={isOpen ? 'show' : 'hidden'}
                        onClick={() => setIsOpen(!isOpen)}
                        variants={mainIconVariant}
                        whileHover={{scale: 1.1}}
                    >
                        <Icon name="triangle" size="36px" />
                    </StyledMainButton>
                </StyledTabs>
            </AnimateSharedLayout>
        </StyledTabsContainer>
    );
}
