import {useQuery} from 'react-query';

import {fetchCategoryDetail} from 'services/request/category-detail';
import {CATEGORY_DETAIL} from 'services/config/query-keys';
import {categoryDetailNormalize} from 'services/normalize/category-detail';

const getCategoryDetails = async (categoryId) => {
    const categoryDetail = await fetchCategoryDetail(categoryId);
    const normalizeCategoryDetail = await categoryDetailNormalize(
        categoryDetail
    );
    return normalizeCategoryDetail;
};

export function useCategoryDetails(categoryId) {
    return useQuery([CATEGORY_DETAIL, categoryId], () =>
        getCategoryDetails(categoryId)
    );
}
