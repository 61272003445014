export const duplicateNutritionPlanNormalize = async ({
    values,
    currentYear,
}) => {
    const normalizedMap = {
        weeks: values.selectedValues.map((item) => ({
            year: currentYear,
            week: item,
        })),
        plan: values.week.days
            ? values.week.days.map((item) => [
                  item.recipes[0] || null,
                  item.recipes[1] || null,
              ])
            : [null, null],
    };
    return normalizedMap;
};
