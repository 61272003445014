import styled from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Icon from 'shared-ui/components/Elements/Icon';

import {font} from 'shared-ui/styles/variables';

export const Wrapper = styled.div`
    width: 180px;
    height: 180px;
    background-image: url(${({bgImage}) => bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;
export const CircleWrapper = styled.div`
    width: 255px;
    height: 255px;
    position: absolute;
`;

export const StyledCard = styled(Card)`
    position: absolute;
    left: ${({left}) => `${left}px`};
    top: ${({top}) => `${top}px`};
    transform: translate(-50%, -50%);
    font-size: ${font.size.xsmall};
    text-align: center;
    cursor: pointer;
    padding: 15px 0px;
`;

export const StyledIcon = styled(Icon)`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
