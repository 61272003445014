import {useQuery} from 'react-query';

import {fetchCategories} from 'services/request/category-list';
import {CATEGORIES} from 'services/config/query-keys';
import {categoryListNormalize} from 'services/normalize/category-list';

const getCategory = async () => {
    const data = await fetchCategories();
    const normalize = await categoryListNormalize(data);
    return normalize;
};

export function useCategory() {
    return useQuery(CATEGORIES, getCategory);
}
