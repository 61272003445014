import styled from 'styled-components';

import Button from 'shared-ui/components/Elements/Button';
import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import {queries} from 'shared-ui/styles/variables';

export const EndScreenCard = styled(Card)`
    border-radius: 25px;
    padding: 20px 15px;

    @media ${queries.sm} {
        width: 520px;
        padding: 45px 15px;
    }
`;

export const CardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EndScreenButton = styled(Button)`
    padding: 12px 4px;
    margin-top: 12px;
    @media ${queries.sm} {
        padding: 16px 4px;
        margin-top: 20px;
    }
`;

export const ButtonWrapper = styled.div`
    @media ${queries.sm} {
        width: 320px;
    }
`;

export const ResultWrapper = styled.div`
    @media ${queries.sm} {
        width: 320px;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    margin-bottom: 6px;
    @media ${queries.sm} {
        margin-bottom: 16px;
    }
`;

export const FoodParagraph = styled(StyledParagraph)`
    padding: 8px 30px;
`;
