import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';

import {color, shadow} from '../../../styles/variables';

const tabBaseStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    padding-bottom: 7px;
    padding-left: 1px;

    box-shadow: ${shadow};
`;

export const StyledTabsContainer = styled(motion.div)`
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: auto;
    z-index: 999;
    visibility: visible;

    &:before {
        opacity: 1;
    }
`;

export const StyledTabs = styled(motion.span)`
    display: flex;
    z-index: 1;
`;
export const StyledMainButton = styled(motion.span)`
    ${tabBaseStyles};
    position: absolute;
    right: 0;
    bottom: 50px;
    background-color: ${color.white};
`;
export const StyledTab = styled(motion.span)`
    ${tabBaseStyles};
    background: ${color.button.primary};
`;
