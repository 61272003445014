import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Flex from 'shared-ui/components/Layout/Flex';
import Lightbox from 'shared-ui/components/Elements/Lightbox';
import Card from 'shared-ui/components/Elements/Card';
import Tooltip from 'components/Layout/Tooltip';

import RecipeWeeklyPlanner from 'components/Layout/RecipeWeeklyPlanner';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {queries} from 'shared-ui/styles/variables';
import {lang} from 'shared-ui/lang/global.lang';

import {RecipeWrapper, DayCard} from './styles';
import RecipeList from './RecipeList';

const NutritionPlan = ({week}) => {
    const isSm = useMediaQuery(queries.smMax);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [selectedDayValue, setSelectedDayValue] = useState(null);

    const handleLightBox = (day, index) => {
        setSelectedDayValue(day.dayValue);
        const findDay = week.days.find((x) => x.dayValue === day.dayValue);
        const findRecipe = findDay.recipesDetails[index];
        setSelectedRecipe(findRecipe);
        setIsOpen(true);
    };

    return (
        <>
            <Flex flexDirection={isSm ? 'column' : 'row'}>
                <Tooltip theme="weeklyPlanner" tipIndex={2} />

                {week.days.map((day, index) => (
                    <RecipeWrapper
                        key={index}
                        flexDirection={isSm ? 'row' : 'column'}
                    >
                        <DayCard theme="secondary">
                            <Paragraph
                                bold
                                theme="darkBlue"
                                size={isSm ? 'xsmall' : 'small'}
                            >
                                {lang.weekDays[index].shortValue}
                            </Paragraph>
                        </DayCard>
                        <RecipeList
                            day={day}
                            isSm={isSm}
                            week={week}
                            handleLightBox={handleLightBox}
                        />
                    </RecipeWrapper>
                ))}
            </Flex>
            <Lightbox isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <Flex justifyContent="center">
                    <Card width="265px">
                        {selectedRecipe && (
                            <RecipeWeeklyPlanner
                                id={selectedRecipe.id}
                                headline={selectedRecipe.title}
                                image={selectedRecipe.thumbnail}
                                dayValue={selectedDayValue}
                                week={week}
                                width="265px"
                            />
                        )}
                    </Card>
                </Flex>
            </Lightbox>
        </>
    );
};

NutritionPlan.propTypes = {
    week: PropTypes.object.isRequired,
};

export default NutritionPlan;
