import {useQuery} from 'react-query';

import {fetchIngredientDetail} from 'services/request/ingredients-detail';
import {INGREDIENTS} from 'services/config/query-keys';
import {ingredientsDetailNormalize} from 'services/normalize/ingredients-detail';

const getIngredientsDetails = async (glossaryId) => {
    const data = await fetchIngredientDetail(glossaryId);
    const normalize = await ingredientsDetailNormalize(data);
    return normalize;
};

export function useIngredientsDetails(glossaryId) {
    return useQuery([INGREDIENTS, glossaryId], () =>
        getIngredientsDetails(glossaryId)
    );
}
