import styled from 'styled-components';
import {Link} from 'react-scroll';

import Paragraph from 'shared-ui/components/Elements/Paragraph';

import {color} from 'shared-ui/styles/variables';

export const MenuItem = styled(Link)`
    cursor: pointer;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
`;

export const StyledParagprah = styled(Paragraph)`
    color: ${({isActive}) => (isActive ? color.red : 'black')};
    border-left: ${({isActive}) =>
        isActive ? `3px solid ${color.red}` : '3px solid transparent'};
    padding-left: 0.3rem;

    &:hover {
        color: ${color.red};
    }
`;
