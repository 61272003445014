import {alphabet} from 'lang/alphabet.lang';

export const ingredientsListNormalize = async ({data}) => {
    const normalizedMap = alphabet.map((item) => ({
        value: item,
        glossary: data
            .filter((x) => x.name.charAt(0) === item)
            .map((glossar) => ({
                id: glossar.id,
                title: glossar.name,
            })),
    }));
    return normalizedMap;
};
