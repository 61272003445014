import {makeRequest} from 'services/config/nutrition-api';

export async function fetchRecipesOfCategory(categoryId, tagIds, page) {
    const url =
        tagIds.length > 0
            ? `/recipes/?filter[category]=${categoryId}&filter[tag]=${tagIds}&page=${page}`
            : `/recipes/?filter[category]=${categoryId}&page=${page}`;

    const response = await makeRequest(url);
    return response;
}
