import PropTypes from 'prop-types';
import React from 'react';

import {
    VIEWBOX_WIDTH,
    VIEWBOX_HEIGHT,
    VIEWBOX_HEIGHT_HALF,
    VIEWBOX_CENTER_X,
    VIEWBOX_CENTER_Y,
} from './constants';
import Path from './Path';

import {color} from '../../../styles/variables';

import {
    CircularProgressbarBackground,
    CircularProgressbar,
    CircularProgressbarChildren,
} from './styles';

const CircularProgressBar = ({value, maxValue, children, className}) => {
    return (
        <CircularProgressbar
            className={className}
            viewBox={`-10 -10 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}
        >
            <CircularProgressbarBackground
                cx={VIEWBOX_CENTER_X}
                cy={VIEWBOX_CENTER_Y}
                r={VIEWBOX_HEIGHT_HALF}
            />
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor={color.red} />
                    <stop offset="100%" stopColor={color.orange} />
                </linearGradient>
            </defs>
            <Path pathOption="trail" value={value} maxValue={maxValue} />

            <Path pathOption="path" value={value} maxValue={maxValue} />

            {children && (
                <CircularProgressbarChildren
                    x={VIEWBOX_CENTER_X}
                    y={VIEWBOX_CENTER_Y}
                >
                    {children}
                </CircularProgressbarChildren>
            )}
        </CircularProgressbar>
    );
};

CircularProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
    maxValue: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
};

CircularProgressBar.defaultProps = {
    maxValue: 10,
    children: null,
    className: 'ap-circular',
};

export default CircularProgressBar;
