import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Lightbox from 'shared-ui/components/Elements/Lightbox';
import Button from 'shared-ui/components/Elements/Button';
import Headline from 'shared-ui/components/Elements/Headline';
import Icon from 'shared-ui/components/Elements/Icon';
import Flex from 'shared-ui/components/Layout/Flex';
import CheckBoxGroup from 'shared-ui/components/Elements/CheckBoxGroup';
import {Container, Row, Column} from 'shared-ui/components/Layout/Grid';
import {lang} from 'shared-ui/lang/global.lang';
import {color} from 'shared-ui/styles/variables';

import NutritionPlan from 'components/Layout/NutritionPlan';
import PlannerNav from 'components/Layout/PlannerNav';
import {weeklyPlannerLang} from 'lang/weeklyPlanner.lang';

import {useDuplicateNutritionPlan} from 'services/data-hooks/nutritionPlan/useDuplicateNutritionPlan';

import {
    ContentWrapper,
    AlertText,
    LightboxContent,
    RecipeTeaser,
    PlannerNavWrapper,
    NutritionPlanWrapper,
    StyledHeaderWrapper,
    TeaserText,
} from './styles';

import HeaderImage from 'components/Layout/HeaderImage';
import WeeklyPlannerHeader from 'nutrition-manager/src/assets/images/header-wochenplan.jpg';
import Divider from 'shared-ui/components/Elements/Divider';
import {useCreateNextWeeks} from 'utils/hooks/useCreateNextWeeks';

const WeeklyPlanner = ({week}) => {
    const {mutate} = useDuplicateNutritionPlan();
    const {memoWeeksItems: nextSixWeekItems} = useCreateNextWeeks(6);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);

    const handleDuplicate = () => {
        mutate({selectedValues, week});
    };

    return (
        <>
            <StyledHeaderWrapper>
                <HeaderImage
                    title={lang.weeklyPlannerIntro}
                    image={WeeklyPlannerHeader}
                />
            </StyledHeaderWrapper>
            <Container noGutter>
                <Row>
                    <Column>
                        <PlannerNavWrapper>
                            <PlannerNav
                                handleDuplicateOnClick={() =>
                                    setIsLightboxOpen(true)
                                }
                            />
                        </PlannerNavWrapper>
                        <NutritionPlanWrapper
                            alignItems="center"
                            marginTop="55px"
                            marginBottom="55px"
                        >
                            {week && <NutritionPlan week={week} />}

                            <Lightbox
                                isOpen={isLightboxOpen}
                                onClose={() => setIsLightboxOpen()}
                                isOrangeBg
                            >
                                <LightboxContent alignItems="center">
                                    <ContentWrapper
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <Flex
                                            marginTop="20px"
                                            marginBottom="20px"
                                        >
                                            <Headline
                                                type="h4"
                                                textAlign="center"
                                            >
                                                {weeklyPlannerLang.title}
                                            </Headline>
                                        </Flex>
                                        <CheckBoxGroup
                                            items={nextSixWeekItems}
                                            selectedValues={selectedValues}
                                            setSelectedValues={
                                                setSelectedValues
                                            }
                                        />
                                        <Flex
                                            justifyContent="space-around"
                                            marginTop="20px"
                                            marginBottom="20px"
                                        >
                                            <Icon
                                                size="32px"
                                                name="exclamationMark"
                                                color={color.orange}
                                            />
                                            <AlertText
                                                size="xsmall"
                                                textAlign="center"
                                            >
                                                {weeklyPlannerLang.info}
                                            </AlertText>
                                        </Flex>
                                        <Flex marginTop="10px">
                                            <Button
                                                label={lang.confirm}
                                                onClick={handleDuplicate}
                                            />
                                        </Flex>
                                    </ContentWrapper>
                                </LightboxContent>
                            </Lightbox>
                        </NutritionPlanWrapper>
                    </Column>
                </Row>
            </Container>
            <RecipeTeaser>
                <TeaserText theme="white" textAlign="center">
                    {lang.availableRecipes}
                </TeaserText>
                <Divider center />
            </RecipeTeaser>
        </>
    );
};

WeeklyPlanner.propTypes = {
    week: PropTypes.object,
};

WeeklyPlanner.defaultProps = {
    week: {},
};

export default WeeklyPlanner;
