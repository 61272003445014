import styled from 'styled-components';

import Flex from 'shared-ui/components/Layout/Flex';

import BackgroundImageMobile from 'assets/images/game-start-screen-mobile.jpg';
import BackgroundImageDesktop from 'assets/images/game-start-screen-desktop.jpeg';
import {queries} from 'shared-ui/styles/variables';

export const BackgroundImage = styled.div`
    height: 625px;
    width: 100%;
    background-image: url(${BackgroundImageDesktop});
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-radius: 20px;
    @media ${queries.mdMax} {
        height: 800px;
        background-image: url(${BackgroundImageMobile});
        background-size: cover;
        border-radius: 0;
    }
`;

export const StyledFlex = styled(Flex)`
    width: fit-content;
    margin: 0 auto;
`;

export const Wrapper = styled(Flex)`
    height: 100%;
    padding-bottom: 30px;
    @media ${queries.mdMax} {
        height: 300px;
    }
`;
