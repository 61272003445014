import React from 'react';
import {useParams} from '@reach/router';

import GlossaryDetail from 'components/Templates/GlossaryDetail';

import {useIngredientsDetails} from 'services/data-hooks/ingredients/useIngredientsDetails';
import {useRecipeOfIngredient} from 'services/data-hooks/recipes/useRecipeOfIngredient';

const GlossaryDetailPage = () => {
    const params = useParams();
    const {data, isLoading} = useIngredientsDetails(params.glossaryId);
    const {data: moreRecipes} = useRecipeOfIngredient(params.glossaryId);

    if (isLoading) {
        return null;
    }

    return (
        <GlossaryDetail
            glossaryTitle={data.title}
            nutrientsParagraph={data.nutrientsParagraph}
            lagerungsortParagraph={data.lagerungsortParagraph}
            vitaminMineralList={data.vitaminMineralList}
            storages={data.storages}
            nutrients={data.nutrients}
            moreRecipes={moreRecipes}
        />
    );
};

export default GlossaryDetailPage;
