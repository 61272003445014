export const addNutritionPlanNormalize = async (
    weekPlan,
    year,
    selectedWeek
) => {
    const normalizedMap = {
        year: year,
        week: parseInt(selectedWeek),
        plan: weekPlan
            ? weekPlan.map((item) => [
                  item.recipes[0] || null,
                  item.recipes[1] || null,
              ])
            : [null, null],
    };
    return normalizedMap;
};
