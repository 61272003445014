import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import Paragraph from 'shared-ui/components/Elements/Paragraph';
import CircularProgressBar from 'shared-ui/components/Elements/CircularProgressBar';
import Div from 'shared-ui/components/Layout/Div';
import {routes} from 'shared-utils/utils/routes';

import {gameLang} from 'lang/storage-game.lang';

import {
    ButtonWrapper,
    CardContentWrapper,
    EndScreenButton,
    EndScreenCard,
    FoodParagraph,
    ResultWrapper,
    StyledParagraph,
} from './styles';

const GameEndScreen = ({
    totalPoints,
    scoredPoints,
    handleRepeatLevel,
    handleNextLevel,
}) => {
    const checkTotalScore = scoredPoints > totalPoints - 4;
    const {title, result, menuButton} = gameLang.endScreen;

    return (
        <EndScreenCard width="260px">
            <CardContentWrapper>
                <Paragraph size="large" bold theme="black" textAlign="center">
                    {checkTotalScore ? title.won : title.lose}
                </Paragraph>
                <Div width="200px">
                    <CircularProgressBar value={scoredPoints} />
                </Div>
                <ResultWrapper className="test">
                    <StyledParagraph
                        size="small"
                        textAlign="center"
                        theme="darkBlue50"
                    >
                        {result.start}
                    </StyledParagraph>
                    <StyledParagraph
                        size="xlarge"
                        bold
                        theme="darkBlue"
                        textAlign="center"
                        noPadding
                    >
                        {scoredPoints} von {totalPoints}
                    </StyledParagraph>
                    <FoodParagraph
                        size="small"
                        textAlign="center"
                        theme="darkBlue50"
                    >
                        {result.end}
                    </FoodParagraph>
                </ResultWrapper>
                <ButtonWrapper>
                    <EndScreenButton
                        label={menuButton.repeatLevel}
                        onClick={handleRepeatLevel}
                    />
                    {checkTotalScore && (
                        <EndScreenButton
                            label={menuButton.nextLevel}
                            onClick={handleNextLevel}
                        />
                    )}
                    <EndScreenButton
                        label={menuButton.changeLevel}
                        onClick={() => navigate(routes.gameLevel)}
                    />
                </ButtonWrapper>
            </CardContentWrapper>
        </EndScreenCard>
    );
};

GameEndScreen.propTypes = {
    totalPoints: PropTypes.number.isRequired,
    scoredPoints: PropTypes.number.isRequired,
    handleRepeatLevel: PropTypes.func.isRequired,
    handleNextLevel: PropTypes.func.isRequired,
};

GameEndScreen.defaultProps = {
    theme: 'primary',
};

export default GameEndScreen;
