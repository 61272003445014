import styled from 'styled-components';
import {color} from 'shared-ui/styles/variables';

import Card from 'shared-ui/components/Elements/Card';

export const ListWrapper = styled(Card)`
    width: 100%;
    height: 100%;
    flex-flow: row wrap;
    margin-top: 1rem;
`;

export const ListItem = styled.div`
    width: 50%;
    padding-left: 1rem;

    &:nth-child(odd) {
        border-right: 2px solid ${color.darkGrey};
    }
`;
