import styled from 'styled-components';

import backgroundImageMobile from 'assets/images/game-background-mobile-image.jpg';
import backgroundImage from 'assets/images/placeholder-game-desktop-background-image.png';

import Div from 'shared-ui/components/Layout/Div';
import Card from 'shared-ui/components/Elements/Card';

import {color, queries} from 'shared-ui/styles/variables';

export const Wrapper = styled(Div)`
    padding-bottom: 30px;
    @media ${queries.lgMax} {
        padding-top: 0px;
    }
`;

export const BackgroundImage = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 70px;
    margin-bottom: 50px;
    background-image: url(${backgroundImage});
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    @media ${queries.mdMax} {
        padding: 0;
        background-image: url(${backgroundImageMobile});
        background-size: cover;
    }
`;

export const CardsWrapper = styled.div`
    padding-top: 80px;
    width: 100%;

    @media ${queries.mdMax} {
        background-color: ${color.white};
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        padding-top: 50px;
    }
`;

export const LifeWrapper = styled(Div)`
    margin: auto;
    padding-top: 30px;
    justify-content: center;
    display: flex;
    @media ${queries.md} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 30px;
    }
`;

export const LiveContainer = styled(Card)`
    display: flex;
    width: fit-content;
    margin-left: auto;
    @media ${queries.mdMax} {
        margin: 0 auto;
    }
    p {
        margin-right: 10px;
    }
`;

export const ImagesDiv = styled(Div)`
    width: 25px;
    height: 33px;
    margin-left: 5px;
`;
