import React from 'react';

import WeeklyPlanner from 'components/Templates/WeeklyPlanner';

import {useNutritionPlan} from 'services/data-hooks/nutritionPlan/useNutritionPlan';

const WeeklyPlannerPage = () => {
    const {data} = useNutritionPlan();

    if (!data) return null;

    return <WeeklyPlanner week={data} />;
};

export default WeeklyPlannerPage;
