import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';

import {useFavoriteContext} from 'context/FavContext';
import {useAddFavorite} from 'services/data-hooks/favorites/useAddFavorite';
import {useRemoveFavorite} from 'services/data-hooks/favorites/useRemoveFavorite';

import {useShoppingLisContext} from 'context/ShoppingListContext';
import {useAddShopping} from 'services/data-hooks/shoppingList/useAddShopping';
import {useRemoveShopping} from 'services/data-hooks/shoppingList/useRemoveShopping';

import {queries} from 'shared-ui/styles/variables';
import {routes} from 'shared-utils/utils/routes';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import Div from 'shared-ui/components/Layout/Div';

import {
    ButtonsWrapper,
    ImageWrapper,
    StyledImage,
    ContentWrapper,
    StyledCard,
    Wrapper,
    StyledParagraph,
    TitleWrapper,
    StyledButton,
} from './styles';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';

export default function RecipeCategory({recipe, handleCalendarOnclick}) {
    const {id, title, image} = recipe;

    const isMd = useMediaQuery(queries.md);
    const isLg = useMediaQuery(queries.lg);

    const {some: isFavoriteList} = useFavoriteContext();
    const {mutate: addFavorite} = useAddFavorite();
    const {mutate: removeFavorite} = useRemoveFavorite();

    const {some: isShoppingList} = useShoppingLisContext();
    const {mutate: addShopping} = useAddShopping();
    const {mutate: removeShopping} = useRemoveShopping();

    const isFavorite = isFavoriteList(id);
    const isShopping = isShoppingList(id);

    const handleFavoriteIcon = () => {
        if (isFavorite) {
            removeFavorite(id);
        } else {
            addFavorite(id, {
                onSuccess: () => {
                    dataLayerPush({
                        event: 'biib_internalLinkClick',
                        eventAsset: 'add_to_fav',
                        eventAssetBis: 'category',
                    });
                },
            });
        }
    };
    const handleShoppingIcon = () => {
        if (isShopping) {
            removeShopping(id);
        } else {
            addShopping(id, {
                onSuccess: () =>
                    dataLayerPush({
                        event: 'biib_internalLinkClick',
                        eventAsset: 'add_to_shopping_list',
                        eventAssetBis: 'category',
                    }),
            });
        }
    };

    return (
        <Div marginBottom={isMd ? '30px' : '5px'}>
            <StyledCard isHoverBg>
                <Wrapper>
                    <ImageWrapper
                        onClick={() => navigate(`${routes.recipe}/${id}`)}
                    >
                        <StyledImage backgroundImage={image} />
                    </ImageWrapper>

                    <ContentWrapper flexDirection="column">
                        <TitleWrapper
                            onClick={() => navigate(`${routes.recipe}/${id}`)}
                        >
                            <StyledParagraph
                                bold
                                theme="black"
                                size="xsmall"
                                autoLineHeight
                            >
                                {title}
                            </StyledParagraph>
                        </TitleWrapper>

                        <ButtonsWrapper>
                            <StyledButton
                                theme="white"
                                icon="calendarPlus"
                                iconSize={
                                    isLg ? '40px' : isMd ? '50px' : '33px'
                                }
                                onClick={() => handleCalendarOnclick(recipe)}
                            />
                            <StyledButton
                                theme={isShopping ? 'active' : 'white'}
                                icon="bag"
                                iconSize={
                                    isLg ? '25px' : isMd ? '30px' : '20px'
                                }
                                onClick={() => handleShoppingIcon()}
                            />
                            <StyledButton
                                theme={isFavorite ? 'active' : 'white'}
                                icon="heart"
                                iconSize={
                                    isLg ? '25px' : isMd ? '30px' : '20px'
                                }
                                onClick={() => handleFavoriteIcon()}
                            />
                        </ButtonsWrapper>
                    </ContentWrapper>
                </Wrapper>
            </StyledCard>
        </Div>
    );
}

RecipeCategory.propTypes = {
    handleCalendarOnclick: PropTypes.func,
    recipe: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
};
RecipeCategory.defaultProps = {
    handleCalendarOnclick: () => {},
};
