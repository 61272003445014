import React from 'react';

import GlossaryList from 'components/Templates/GlossaryList';

import {useIngredients} from 'services/data-hooks/ingredients/useIngredientsList';

const GlossaryPage = () => {
    const {data, isLoading} = useIngredients();
    if (isLoading) {
        return null;
    }
    return <GlossaryList glossaryList={data} />;
};

export default GlossaryPage;
