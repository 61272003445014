import React from 'react';
import {navigate} from '@reach/router';

import {routes} from 'shared-utils/utils/routes';
import Headline from 'shared-ui/components/Elements/Headline';
import Div from 'shared-ui/components/Layout/Div';
import Image from 'shared-ui/components/Elements/Image';
import {Column, Container} from 'shared-ui/components/Layout/Grid';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Button from 'shared-ui/components/Elements/Button';
import {footerTopPadding, queries} from 'shared-ui/styles/variables';
import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {lang} from 'shared-ui/lang/global.lang';
import {dataLayerPush} from 'shared-utils/utils/tracking-utils';

import MobileImage from 'assets/images/game-startmobile-image.png';

import {BackgroundImage, StyledFlex, Wrapper} from './styles';

const GameStart = () => {
    const isMd = useMediaQuery(queries.md);

    const handleOnClick = () => {
        navigate(`${routes.gameLevel}`);
        dataLayerPush({
            event: 'biib_internalLinkClick',
            eventAsset: 'game_start',
            eventAssetBis: '/',
        });
    };

    return (
        <Div paddingBottom={footerTopPadding}>
            {isMd && (
                <Div
                    justifyContent="center"
                    paddingTop="25px"
                    paddingBottom="25px"
                >
                    <Headline type="h2" textAlign="center">
                        {lang.storageQuiz}
                    </Headline>
                </Div>
            )}
            <Container bgWhite noGutter>
                <BackgroundImage>
                    {!isMd && (
                        <Column
                            cols={{xxs: 8, xs: 6, sm: 6, md: 8}}
                            offset={{xxs: 2, xs: 3, sm: 3, md: 2}}
                        >
                            <Div paddingTop="100px">
                                <Image width="100%" src={MobileImage} />
                                {/* <MobileImage /> */}
                            </Div>
                        </Column>
                    )}
                    <Wrapper alignItems="flex-end">
                        <StyledFlex
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {!isMd && (
                                <Paragraph
                                    size="xlarge"
                                    bold
                                    theme="white"
                                    type="h2"
                                >
                                    {lang.storageQuiz}
                                </Paragraph>
                            )}

                            <Headline type="h3" theme="white">
                                {lang.testYourKnowledge}
                            </Headline>
                            <Paragraph textAlign="center" theme="white">
                                {lang.gameStartScreenDescriptions}
                            </Paragraph>

                            <Button
                                label="Los geht's"
                                onClick={handleOnClick}
                            />
                        </StyledFlex>
                    </Wrapper>
                </BackgroundImage>
            </Container>
        </Div>
    );
};

export default GameStart;
