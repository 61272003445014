import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {InView} from 'react-intersection-observer';

import HeaderImage from 'components/Layout/HeaderImage';
import RecipeFilter from 'components/Layout/RecipeFilter';
import RecipeList from 'components/Layout/RecipeList';
import Tooltip from 'components/Layout/Tooltip';

import Flex from 'shared-ui/components/Layout/Flex';
import Button from 'shared-ui/components/Elements/Button';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Div from 'shared-ui/components/Layout/Div';
import {Container, Column} from 'shared-ui/components/Layout/Grid';

import {useNutritionPlanContext} from 'context/NutritionPlanContext';

import {lang} from 'shared-ui/lang/global.lang';
import {StyledHeaderWrapper, StyledDiv} from './styles';

const CategoryDetail = ({
    recipes,
    tags,
    setSelectedTagIds,
    selectedTagIds,
    categoryDetails,
    meta,
    setPage,
    setInitialRecipes,
}) => {
    const {handleCurrentYear} = useNutritionPlanContext();

    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(true);

    useEffect(() => {
        handleCurrentYear();
    }, []);

    const handleFilter = (selectedValue) => {
        setSelectedTagIds(selectedValue);
        setPage(1);
        setInitialRecipes([]);
    };

    if (!recipes) {
        return null;
    }

    const isShowMoreButton = meta?.total === recipes?.length;

    return (
        <>
            <Div>
                <StyledHeaderWrapper>
                    {categoryDetails && (
                        <HeaderImage
                            image={categoryDetails.image}
                            preTitle={lang.overview}
                            title={categoryDetails.title}
                        />
                    )}
                </StyledHeaderWrapper>
            </Div>
            <Container>
                <Div>
                    <Tooltip theme="categoryDetail" tipIndex={4} />
                    <Column cols={{sm: 12}}>
                        <StyledDiv>
                            {tags && (
                                <RecipeFilter
                                    isFilterButtonVisible={
                                        isFilterButtonVisible
                                    }
                                    items={tags}
                                    setSelectedFilter={handleFilter}
                                    selectedFilter={selectedTagIds}
                                />
                            )}
                        </StyledDiv>
                    </Column>

                    <Column cols={{sm: 12, lg: 12}}>
                        <Div marginTop="0.5rem" marginBottom="2rem">
                            <Paragraph bold theme="darkBlue">
                                {meta &&
                                    `${meta?.total} ${lang.results(
                                        meta?.total
                                    )}`}
                            </Paragraph>
                            <InView
                                as="div"
                                rootMargin="-150px"
                                onChange={(inView) =>
                                    setIsFilterButtonVisible(!inView)
                                }
                            >
                                <RecipeList recipes={recipes} />
                            </InView>
                        </Div>
                    </Column>
                    {!isShowMoreButton && (
                        <Column cols={{md: 6, lg: 4}} offset={{md: 3, lg: 4}}>
                            <Flex justifyContent="center" marginBottom="4rem">
                                <Button
                                    label={lang.showMore}
                                    onClick={() => setPage((page) => page + 1)}
                                />
                            </Flex>
                        </Column>
                    )}
                </Div>
            </Container>
        </>
    );
};

CategoryDetail.propTypes = {
    recipes: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    setSelectedTagIds: PropTypes.func.isRequired,
    selectedTagIds: PropTypes.array.isRequired,
    categoryDetails: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    setInitialRecipes: PropTypes.func.isRequired,
};

CategoryDetail.defaultProps = {
    recipes: [],
    tags: [],
    categoryDetails: {},
};

export default CategoryDetail;
