import React from 'react';

import GameSelectLevel from 'components/Templates/GameSelectLevel';

import {useGameLevels} from 'services/data-hooks/game/useGameLevels';

const Game = () => {
    const {data: successfulyCategories = []} = useGameLevels();

    return <GameSelectLevel successfulyCategories={successfulyCategories} />;
};

export default Game;
