import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'shared-ui/components/Layout/Flex';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Headline from 'shared-ui/components/Elements/Headline';
import {Column, Row} from 'shared-ui/components/Layout/Grid';
import Div from 'shared-ui/components/Layout/Div';

import Nutrients from 'components/Layout/Nutrients';
import VitaminMineral from 'components/Layout/VitaminMineral';
import RecipeStorage from 'components/Layout/RecipeStorage';
import RecipeCard from 'components/Layout/Recipe';
import AnchorLeftMenu from 'components/Layout/AnchorLeftMenu';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';

import {lang} from 'shared-ui/lang/global.lang';
import {queries} from 'shared-ui/styles/variables';

import {
    StyledFlex,
    StyledHeadline,
    StyledParagraph,
    StyledTextParagraph,
    StyledCard,
    AnchorMenuWrapper,
    StyledContainer,
} from './styles';

const GlossaryDetail = ({
    glossaryTitle,
    nutrientsParagraph,
    lagerungsortParagraph,
    vitaminMineralList,
    storages,
    moreRecipes,
    nutrients,
}) => {
    const isLg = useMediaQuery(queries.lg);

    return (
        <>
            <StyledFlex>
                <StyledHeadline type="h2">{glossaryTitle}</StyledHeadline>
            </StyledFlex>

            <StyledContainer bgWhite>
                <Row>
                    <Column cols={{lg: 2}}>
                        {isLg && (
                            <AnchorMenuWrapper
                                marginTop="48px"
                                flexDirection="column"
                            >
                                <AnchorLeftMenu
                                    menuItems={lang.glossaryDetailPagemenuItems}
                                    offset={-150}
                                />
                            </AnchorMenuWrapper>
                        )}
                    </Column>

                    <Column cols={{lg: 6}}>
                        <Div id="nahrwerte" marginTop="48px">
                            <Headline type="h5">{lang.nutrient}</Headline>
                            <Nutrients
                                theme="secondary"
                                kcal={nutrients.kcal}
                                protein={nutrients.protein}
                                fat={nutrients.fat}
                                carbon={nutrients.carbon}
                            />

                            <StyledParagraph size="xsmall">
                                {nutrientsParagraph}
                            </StyledParagraph>
                        </Div>
                        <Div id="vitamine">
                            <Paragraph theme="darkBlue" bold size="small">
                                {lang.vitaminsandminerals}
                            </Paragraph>

                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                marginTop="128px"
                                marginBottom="96px"
                            >
                                <VitaminMineral
                                    vitaminMineralList={vitaminMineralList}
                                    icon="magnifying"
                                />
                            </Flex>
                        </Div>
                        <Div id="lagerungsort">
                            <Paragraph theme="darkBlue" bold size="small">
                                {lang.storagePlace}
                            </Paragraph>
                            <StyledTextParagraph size="xsmall">
                                {lagerungsortParagraph}
                            </StyledTextParagraph>
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <RecipeStorage storages={storages} />
                            </Flex>
                        </Div>
                    </Column>

                    <Column cols={{lg: 3}}>
                        <Headline
                            type="h3"
                            marginTop="32px"
                            marginBottom="16px"
                        >
                            {lang.moreRecipes}
                        </Headline>
                        {moreRecipes && (
                            <Row>
                                {moreRecipes?.map((item, index) => (
                                    <Column
                                        key={index}
                                        cols={{sm: 6, md: 4, lg: 12}}
                                    >
                                        <StyledCard isHoverBg>
                                            <RecipeCard
                                                id={item.id}
                                                image={item.image}
                                                headline={item.title}
                                                minutes={item.minutes}
                                                difficulty={item.difficulty}
                                                kcal={item.kcal}
                                                inSidebar
                                            />
                                        </StyledCard>
                                    </Column>
                                ))}
                            </Row>
                        )}
                    </Column>
                </Row>
            </StyledContainer>
        </>
    );
};

GlossaryDetail.propTypes = {
    vitaminMineralList: PropTypes.array.isRequired,
    storages: PropTypes.array.isRequired,
    moreRecipes: PropTypes.array,
    nutrients: PropTypes.object.isRequired,
    glossaryTitle: PropTypes.string.isRequired,
    nutrientsParagraph: PropTypes.string,
    lagerungsortParagraph: PropTypes.string,
};

GlossaryDetail.defaultProps = {
    moreRecipes: [],
    nutrientsParagraph: [],
    lagerungsortParagraph: [],
};

export default GlossaryDetail;
