import React from 'react';
import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Image from 'shared-ui/components/Elements/Image';

import useMediaQuery from 'shared-utils/utils/hooks/useMediaQuery';
import {queries} from 'shared-ui/styles/variables';

const ProductCard = ({index, value, image}) => {
    const isMdMax = useMediaQuery(queries.mdMax);

    const isDrag = index === 0;
    const isShowMobile = isMdMax && index > 5;

    if (isShowMobile) {
        return null;
    }

    function getStyle(style, snapshot) {
        if (!snapshot.isDragging)
            return {
                position: 'absolute',
                zIndex: 999 - index,
                left: 40 * index,
            };
        if (!snapshot.isDropAnimating) {
            return style;
        }

        return {
            ...style,
            transitionDuration: `0.001s`,
        };
    }

    return (
        <Draggable
            draggableId={value}
            index={index}
            type="product"
            isDragDisabled={!isDrag}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                >
                    <Card size="100px" alignItems="center">
                        <Image src={image} width="60px" alt="" />
                        <Paragraph textAlign="center" noPadding size="xxxsmall">
                            {value.length > 10
                                ? value.slice(0, 9).concat('..')
                                : value}
                        </Paragraph>
                    </Card>
                </div>
            )}
        </Draggable>
    );
};

ProductCard.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default ProductCard;
