export const routes = {
    home: '/nutritionmanager',
    recipe: '/nutritionmanager/recipe',
    categories: '/nutritionmanager/categories',
    glossary: '/nutritionmanager/glossary',
    favorites: '/nutritionmanager/favorites',
    weeklyPlanner: '/nutritionmanager/weekly-planner',
    shoppingList: '/nutritionmanager/shopping-list',
    gameLevel: '/nutritionmanager/game',
    gameStart: '/nutritionmanager/game-start',
};
