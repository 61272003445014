import styled from 'styled-components';

import Flex from 'shared-ui/components/Layout/Flex';

import {queries, color} from 'shared-ui/styles/variables';

export const TargetCard = styled(Flex)`
    position: relative;
    height: 100%;
    @media ${queries.lgMax} {
        width: 160px;
    }
`;

export const CategoryCards = styled.div`
    margin: 0 auto;
    justify-content: center;
    position: relative;
    height: 380px;
    width: 80%;
    display: grid;
    grid-template-columns: 300px 300px 300px;
    grid-template-rows: auto;
    grid-template-areas:
        'fridge bowl regal'
        'fridge bowl regal';
    @media ${queries.lgMax} {
        width: 90%;
        grid-template-columns: 50% 50%;
        /* grid-template-rows: 200px 50%; */
        grid-template-areas:
            'fridge bowl'
            'fridge bowl'
            'fridge regal'
            'fridge regal';
    }
    .target-card {
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin: 0 10px;
        position: relative;
    }
    .target-card:nth-child(1) {
        background-color: #fdf7e5;
        grid-area: fridge;
        @media ${queries.lgMax} {
            height: 100%;
        }
    }
    .target-card:nth-child(2) {
        background-color: #ffe6db;
        grid-area: bowl;
        @media ${queries.lgMax} {
            height: fit-content;
            margin-bottom: 5px;
        }
    }
    .target-card:nth-child(3) {
        background-color: #ffdbdb;
        grid-area: regal;
        @media ${queries.lgMax} {
            height: fit-content;
            margin-top: 5px;
        }
    }
`;

const productCardGutter = 40;

export const SelectCardDiv = styled(Flex)`
    margin: 20px auto 40px;
    position: relative;
    justify-content: center;
    height: 100px;
    width: ${({productsLength}) => 100 + productsLength * productCardGutter}px;
    @media ${queries.mdMax} {
        width: ${100 + 5 * productCardGutter}px;
    }
`;

export const AlertCard = styled.div`
    position: absolute;
    bottom: 20px;
    width: 90%;
    padding: 10px 20px;
    background-color: ${color.white};
    border-radius: 20px;
    z-index: 999;
    border: 1px solid
        ${({trueType}) => (trueType ? color.alertGreen : color.red)};
    @media ${queries.mdMax} {
        position: fixed;
        width: 80%;
        margin: 0 10%;
        left: 0;
        right: 0;
        bottom: 25%;
    }
`;
