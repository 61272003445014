import {useMutation, useQueryClient} from 'react-query';

import {gameLevelCompleted} from 'services/request/game-level-completed';
import {GAME_LEVELS} from 'services/config/query-keys';

export const getStoreCompletedLevel = async (levelId) => {
    const response = gameLevelCompleted(levelId);
    return response;
};

export const useStoreCompletedLevel = () => {
    const cache = new useQueryClient();
    const mutate = useMutation(
        (levelId) => {
            getStoreCompletedLevel(levelId);
        },
        {
            onSuccess: () => {
                cache.refetchQueries(GAME_LEVELS);
            },
        }
    );
    return mutate;
};
