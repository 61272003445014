import styled, {css} from 'styled-components';

import Card from 'shared-ui/components/Elements/Card';
import Paragraph from 'shared-ui/components/Elements/Paragraph';
import Button from 'shared-ui/components/Elements/Button';
import Flex from 'shared-ui/components/Layout/Flex';

import {shadow, color, queries} from 'shared-ui/styles/variables';

export const StyledCard = styled(Card)`
    padding: 10px;
    margin-bottom: 15px;
    box-shadow: ${shadow};
    position: relative;
`;

export const ImageWrapper = styled.div`
    width: 86px;
    height: 70px;
    border-radius: 0.625rem;
    object-fit: cover;
    margin: 4px 4px;
    cursor: pointer;

    ${({backgroundImage}) =>
        backgroundImage &&
        css`
            background-image: url(${backgroundImage});
            background-size: cover;
            background-position: center;
        `}
    @media ${queries.mdMax} {
        max-width: 70px;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
    justify-content: space-around;
`;

export const ParagraphWrapper = styled.div`
    display: inline-block;
    margin-top: 3px;
    ${({isFlex}) =>
        isFlex &&
        css`
            display: flex;
        `}
`;

export const StyledParagraphTitle = styled(Paragraph)`
    color: ${color.darkBlue};
    font-size: 15px;
`;

export const StyledParagraph = styled(Paragraph)`
    margin-left: 1.5rem;
`;

export const TitleWrapper = styled.div`
    cursor: pointer;
`;

export const StyledButton = styled(Button)`
    margin-left: auto;
    border-radius: 8px;
    margin-left: 0.5rem;
`;

export const ButtonsWrapper = styled.div`
    margin-left: auto;

    @media ${queries.smMax} {
        display: flex;
        flex-direction: column-reverse;
    }
`;

export const StyledFlex = styled(Flex)`
    margin-left: 1rem;
`;

export const StyledChildrenDiv = styled.div`
    margin-top: 10px;
`;
