import {useQuery} from 'react-query';

import {fetchRecipesOfTags} from 'services/request/recipes-of-tags';
import {RECIPE_OF_CATEGORY} from 'services/config/query-keys';
import {recipeListNormalize} from 'services/normalize/recipe-list';

const randomElement = async (list) => {
    return list[Math.floor(Math.random() * list.length)];
};

const getRecipeOfTags = async (tags) => {
    const selectedTag = await randomElement(tags);
    const {data, meta} = await fetchRecipesOfTags(selectedTag.id);
    const normalize = await recipeListNormalize(data, meta);
    return normalize.data.slice(0, 4);
};

export function useRecipeOfTags(tags) {
    return useQuery([RECIPE_OF_CATEGORY, tags], () => getRecipeOfTags(tags), {
        enabled: !!tags,
    });
}
