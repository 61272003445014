import styled from 'styled-components';

import Icon from 'shared-ui/components/Elements/Icon';
import Button from 'shared-ui/components/Elements/Button';
import {shadow, color, queries} from 'shared-ui/styles/variables';
import Card from 'shared-ui/components/Elements/Card';

export const StyledIcon = styled(Icon)`
    padding: 15px;
    box-shadow: ${shadow};
    &:hover {
        background-color: ${color.button.primary};
    }
`;

export const StyledButton = styled(Button)`
    padding: 0px;
    margin: 0px;
`;

export const StyledExportCard = styled(Card)`
    width: 70vw;
    @media ${queries.sm} {
        width: 300px;
    }
`;
