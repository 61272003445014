import React from 'react';
import PropTypes from 'prop-types';

import Popover from 'shared-ui/components/Elements/Popover';

import RecipeWeeklyPlanner from 'components/Layout/RecipeWeeklyPlanner';

import PlusCard from './PlusCard';
import {RecipeCard, ImageWrapper} from './styles';

const RecipeList = ({day, isSm, handleLightBox, week}) => {
    const {recipes, recipesDetails} = day;

    return (
        <>
            {recipes.map((recipe, index) => (
                <RecipeCard key={index} theme="secondary">
                    {recipe ? (
                        <>
                            {isSm ? (
                                <ImageWrapper
                                    bgImage={recipesDetails[index].image}
                                    onClick={() => handleLightBox(day, index)}
                                />
                            ) : (
                                <Popover
                                    width="300px"
                                    content={
                                        <RecipeWeeklyPlanner
                                            id={recipesDetails[index].id}
                                            headline={
                                                recipesDetails[index].title
                                            }
                                            image={recipesDetails[index].image}
                                            dayValue={day.dayValue}
                                            week={week}
                                        />
                                    }
                                    positions="bottom"
                                >
                                    <ImageWrapper
                                        bgImage={recipesDetails[index].image}
                                    />
                                </Popover>
                            )}
                        </>
                    ) : (
                        <PlusCard />
                    )}
                </RecipeCard>
            ))}
        </>
    );
};

RecipeList.propTypes = {
    day: PropTypes.object.isRequired,
    isSm: PropTypes.bool,
    handleLightBox: PropTypes.func,
    week: PropTypes.object.isRequired,
};

RecipeList.defaultProps = {
    isSm: false,
    handleLightBox: () => {},
};

export default RecipeList;
