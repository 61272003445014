import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Popover as PopoverComponent} from 'react-tiny-popover';

import {color} from '../../../styles/variables';
import useDimension from 'shared-utils/utils/hooks/useDimension';

import {StyledContent, StyledArrowContainer} from './styles';

export default function Popover({children, positions, content, align, width}) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [refTooltip, refTooltipSize] = useDimension();

    return (
        <PopoverComponent
            ref={refTooltip}
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            padding={-refTooltipSize.width / 2}
            positions={[positions]}
            align={align}
            containerStyle={{
                zIndex: '4',
                left: positions === 'top' ? '9px' : '0',
                top: positions === 'top' ? '-25px' : '0',
            }}
            content={({position, childRect, popoverRect, parentRect}) => (
                <StyledArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    parentRect={parentRect}
                    isOutLeft={popoverRect.left < 0}
                    isOutRight={popoverRect.right > parentRect.width}
                    arrowColor={color.white}
                    arrowClassName="popover-arrow"
                    arrowStyle={{padding: 1, zIndex: 10}}
                    arrowSize={15}
                >
                    <StyledContent width={width}>{content}</StyledContent>
                </StyledArrowContainer>
            )}
        >
            <span onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                {children}
            </span>
        </PopoverComponent>
    );
}

Popover.propTypes = {
    children: PropTypes.any.isRequired,
    positions: PropTypes.oneOf(['top', 'bottom']).isRequired,
    align: PropTypes.oneOf(['start', 'center', 'end']).isRequired,
    content: PropTypes.any.isRequired,
    width: PropTypes.string,
};

Popover.defaultProps = {
    width: '',
    positions: 'top',
    align: 'center',
};
