import React, {createContext, useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useLocalStorage} from 'shared-utils';

const ToolTipContext = createContext();

export const ToolTipProvider = ({children}) => {
    const [stepsStorage, setStepsStorage] = useLocalStorage('tooltipSteps', []);
    const [screenToolTips, setScreenToolTips] = useState([]);

    const handleCloseOnClick = (tipIndex) => {
        setStepsStorage([...stepsStorage, tipIndex]);
        setScreenToolTips((prev) => prev.filter((step) => step !== tipIndex));
    };

    const setScreenToolTipIndex = (tipIndex) => {
        if (!stepsStorage.includes(tipIndex)) {
            setScreenToolTips((prev) => [...prev, tipIndex].sort());
        }
    };

    useEffect(() => {
        if (screenToolTips.length > 0) {
            document.body.style.pointerEvents = 'none';
        } else {
            document.body.style.pointerEvents = 'auto';
        }
    }, [screenToolTips]);

    const values = {
        handleCloseOnClick,
        setScreenToolTipIndex,
        screenToolTips,
        stepsStorage,
        setScreenToolTips,
    };

    return (
        <ToolTipContext.Provider value={values} displayName="ToolTipProvider">
            {children}
        </ToolTipContext.Provider>
    );
};

ToolTipProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export const useToolTip = () => {
    return useContext(ToolTipContext);
};
