import React, {useEffect, useState} from 'react';
import {useParams} from '@reach/router';

import CategoryDetail from 'components/Templates/CategoryDetail';
import PageLayout from 'components/Layout/PageLayout';

import {useCategoryDetails} from 'services/data-hooks/categories/useCategoryDetails';
import {useTags} from 'services/data-hooks/tags/useTags';
import {useRecipeOfCategory} from 'services/data-hooks/recipes/useRecipeOfCategory';

const CategoryDetailPage = () => {
    const params = useParams();
    const {categoryId} = params;
    const [page, setPage] = useState(1);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [initialRecipes, setInitialRecipes] = useState([]);

    const {data: categoryDetails} = useCategoryDetails(categoryId);
    const {data: tags} = useTags();
    const {data: recipes} = useRecipeOfCategory(
        categoryId,
        selectedTagIds,
        page
    );

    useEffect(() => {
        recipes &&
            setInitialRecipes((initialRecipes) => [
                ...initialRecipes,
                ...recipes.data,
            ]);
    }, [recipes]);

    return (
        <PageLayout bgWhite>
            <CategoryDetail
                categoryDetails={categoryDetails}
                recipes={initialRecipes}
                meta={recipes?.meta}
                tags={tags}
                setSelectedTagIds={setSelectedTagIds}
                selectedTagIds={selectedTagIds}
                setInitialRecipes={setInitialRecipes}
                setPage={setPage}
                page={page}
            />
        </PageLayout>
    );
};

export default CategoryDetailPage;
